import { store } from 'redux/store';
import { updateIsOpenUpdateVersionDialog } from 'redux/slices/global';

export const lazyRetry: any = (componentImport: any) => {
  // Hanlde reload app when load chunk fail due to new deployment
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        console.log('Error in code splitting', error);
        if (error.name.includes('ChunkLoadError') && !hasRefreshed) {
          // not been refreshed yet
          // alert('A new version of the app has been released, the system will be reloaded to keep the app up to date');

          store.dispatch(updateIsOpenUpdateVersionDialog(true));
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        // TO DO
        reject(error); // there was an error
      });
  });
};

export default lazyRetry;
