import { useEffect, useRef, useState } from 'react';

// @mui
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER } from '../../../config';
// components
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import SearchIcon from '@mui/icons-material/Search';
import { IconButtonAnimate } from 'components/animate';
import HeaderCheckoutV2 from 'features/checkout-nails123/components/HeaderCheckout';
import { useLocation, useNavigate } from 'react-router-dom';
// import { setCurrentPage } from 'redux/slices/global';
import { RootState, useAppSelector } from 'redux/store';
import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
import AccountPopover from './AccountPopover';
import BookingPopover from './BookingPopover';
// import LanguagePopover from './LanguagePopover';
// import { Search, SearchIconWrapper, StyledInputBase } from './style';
import { LOGO_WIDTH } from 'utils/constants';
import CheckinPopover from './CheckinPopover';
import ConnectorPopover from './ConnectorPopover';
import icSmsCount from 'assets/images/checkout/ic_smsCount.svg';

// ----------------------------------------------------------------------
const RootStyle = styled(AppBar)<any>(({ iscollapse, isoffset, verticallayout, theme }: any) => ({
  position: 'fixed',
  background: '#FFFFFF',
  boxShadow: '0px 0px 6px 4px rgba(99, 115, 129, 0.15)',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: '100%',
    ...(iscollapse === 'true' && {
      width: '100%',
    }),
    ...(isoffset === 'true' && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticallayout === 'true' && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const TitleHeader = styled(Box)<any>(({ subMenu, theme }: any) => ({
  paddingLeft: 10,
  color: theme.palette.text.headline,
  fontWeight: 500,
  textAlign: 'left',
  fontSize: '24px',
  display: 'flex',
  cursor: subMenu ? 'pointer' : '',
  alignItems: 'center',
}));

const Text = styled(Typography)<any>(() => ({
  color: '#000',
  fontSize: '16px',
  width: '100%',
  marginRight: 5,
  fontWeight: 600,
}));

const SMSNumber = styled(Typography)<any>(() => ({
  color: '#0E73E4',
  fontSize: '16px',
  fontWeight: 600,
}));

const WrapperActionHeader = styled(Box)<any>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'auto',
  gap: '10px',
}));

const WrapperSMS = styled(Box)<any>(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '8px 10px',
  borderRadius: '8px',
  border: '2px solid #878282',
}));
// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: any;
  onClickCalendar?: any;
  verticalLayout?: boolean;
  collapseClick?: any;
};

export default function DashboardHeader({ onOpenSidebar, verticalLayout = false, onClickCalendar }: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const { currentPage, checkoutPasscodeModal, businessInfo } = useAppSelector((state: RootState) => state.global);
  const { showBooking = false, showChecking = false, smsCount = 0 } = businessInfo;
  const isShowSms = !!smsCount;
  const location = useLocation();
  const [widthHeaderCheckout, setWidthHeaderCheckout] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (currentPage.path === '/checkout' || currentPage.path === '/checkoutv2') {
      const screenWidth = window.innerWidth;
      const containerWidth = containerRef.current?.clientWidth || 0;
      const widthHeaderCheckout = screenWidth - containerWidth - LOGO_WIDTH;
      setWidthHeaderCheckout(widthHeaderCheckout);
    }
  }, [containerRef, currentPage.path]);

  const SMS = () => {
    return (
      <WrapperSMS>
        <img src={icSmsCount} alt="" />
        <Text>SMS: </Text>
        <SMSNumber>{smsCount}</SMSNumber>
      </WrapperSMS>
    );
  };

  const renderHeaderContent = () => {
    if (currentPage.path === '/checkout' || currentPage.path === '/checkoutv2') {
      return (
        <>
          {!checkoutPasscodeModal.open && <HeaderCheckoutV2 width={widthHeaderCheckout} onClickCalendar={onClickCalendar} />}
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <div ref={containerRef}>
            <WrapperActionHeader>
              {isShowSms && <SMS />}
              {showChecking && <CheckinPopover />}
              {showBooking && <BookingPopover />}
              <ConnectorPopover />
              <AccountPopover />
            </WrapperActionHeader>
          </div>
        </>
      );
    } else {
      return (
        <>
          <TitleHeader
            subMenu={currentPage.subMenu}
            onClick={() => {
              if (currentPage.subMenu) navigate(currentPage.backPath, { state: { prevUrl: location.pathname } });
            }}
          >
            {currentPage.subMenu && <ArrowBackIosIcon />}
            {currentPage.title}
          </TitleHeader>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {/* {haveSearch && (
              <Search>
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: '#7A7A7A' }} />
                </SearchIconWrapper>
                <StyledInputBase placeholder={placeholder || ''} inputProps={{ 'aria-label': 'search' }} value={searchText} onChange={handleSearch} />
              </Search>
            )} */}
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}
            <AccountPopover />
          </Stack>
        </>
      );
    }
  };
  return (
    <RootStyle isoffset={`${isOffset}`} verticallayout={`${verticalLayout}`}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          paddingLeft: '0 !important',
        }}
      >
        {isDesktop && <Logo />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" sx={undefined} />
          </IconButtonAnimate>
        )}

        {renderHeaderContent()}
      </Toolbar>
    </RootStyle>
  );
}
