import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ic_close from 'assets/images/icons/ic_close.svg';
import ic_error_triangle from 'assets/images/icons/ic_error_triangle.svg';
import ic_success from 'assets/images/icons/ic_success.svg';
import { FocusEvent, FormEvent, KeyboardEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { PasscodeWrapper, VerificationInput, useStyles } from './useStyle';

type InputOrNull = HTMLInputElement | null;

interface IPasscodeDialog {
  length?: number;
  onSubmit: (passcode: Array<string>) => void;
}

const keyboardValueOption: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Delete', '0', 'OK'];
const defaultWrongPasscodeMessage = 'Passcode is not correct';

const ModalPasscode = forwardRef(({ length = 4, onSubmit }: IPasscodeDialog, ref) => {
  const [open, setOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [code, setCode] = useState<string[]>(Array(length).fill(''));
  const inputRef: any = useRef([]);
  const classes = useStyles();
  const [errorText, setErrorText] = useState<string>(defaultWrongPasscodeMessage);

  useEffect(() => {
    if (code.every((item) => item !== '')) {
      handleSubmitPasscode({ passcode: code });
    }
  }, [code]);

  const update = useCallback(
    (index: number, val: string) => {
      setCode((prevState) => {
        const slice = prevState.slice();
        slice[index] = val;
        return slice;
      });
      inputRef.current[index].value = val;
      if (isError) {
        setIsError(false);
      }
    },
    [isError],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        onOpen: handleClickOpen,
        onClose: handleClose,
      };
    },
    [],
  );

  const handleSubmitPasscode = async ({ passcode }: { passcode: Array<string> }) => {
    try {
      setIsSubmitted(true);
      await onSubmit(passcode);
    } catch (error: any) {
      setIsError(true);
      setErrorText(error.message);
    } finally {
      setIsSubmitted(false);
      resetCode();
    }
  };

  const resetCode = () => {
    setCode(Array(length).fill(''));
    if (inputRef.current?.length) {
      inputRef.current.map((input: HTMLInputElement) => (input.value = ''));
      inputRef.current[0].focus();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsError(false);
    setIsSubmitted(false);
    setShowPassword(false);
    setCode(Array(length).fill(''));
    setOpen(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (evt: FormEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value;
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    let nextIndex = index + 1;
    let nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);

    update(index, value[0] || '');
    if (value.length === 1) nextInput?.focus();
    else if (index < length - 1) {
      const split = value.slice(index + 1, length).split('');
      split.forEach((val) => {
        update(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = form.querySelector(`.input-${nextIndex}`);
      });
    }
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (isNaN(index) || !inputRef.current?.length) return; // just in case
    const prevInput = inputRef.current[index - 1];
    const nextInput = inputRef.current[index + 1];
    switch (evt.key) {
      case 'Backspace':
        if (code[index]) {
          update(index, '');
        } else if (prevInput) {
          prevInput.select();
        } else {
          prevInput.focus();
        }
        break;
      case 'ArrowRight':
        evt.preventDefault();
        if (nextInput) nextInput.focus();
        break;
      case 'ArrowLeft':
        evt.preventDefault();
        if (prevInput) prevInput.focus();
    }
  };

  const handleFocus = (evt: FocusEvent<HTMLInputElement>) => {
    evt.currentTarget.select();
  };

  const renderNumpadItem = (item: string) => {
    switch (item) {
      case 'Delete':
        return <img src={ic_close} alt="Delete" />;
      case 'OK':
        return <img src={ic_success} alt="OK" />;
      default:
        return item;
    }
  };

  const handleChangeNumpad = (value: string) => {
    const index = inputRef.current.findIndex((input: HTMLInputElement) => input === document.activeElement);

    const prevInput = inputRef.current[index - 1];
    const currentInput = inputRef.current[index];
    const nextInput = inputRef.current[index + 1];

    if (value === 'OK') {
    } else if (value !== 'Delete') {
      update(index, value);
      if (currentInput) {
        currentInput.value = value;
      }
      code.every((item) => item.length > 0) && setIsSubmitted(true);
      nextInput?.focus();
    } else {
      update(index - 1, '');
      prevInput?.focus();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        sx={{ zIndex: 1500 }}
      >
        <DialogTitle id="alert-dialog-title" pl={'40px !important'}>
          {'Enter Passcode'}
        </DialogTitle>
        <DialogContent sx={{ height: '460px', width: '350px' }}>
          <PasscodeWrapper>
            <Box
              borderRadius="4px"
              padding={2}
              sx={{
                cursor: 'text',
                border: `1px solid ${isError ? 'rgba(230, 96, 33, 1)' : 'rgba(14, 115, 228, 1)'}`,
                opacity: isSubmitted ? 0.4 : 1,
                m: 'auto',
                width: '100%',
              }}
              ref={formRef}
            >
              <Stack direction={'row'} justifyContent={'center'} gap={2} position="relative">
                {[...(Array(4).keys() as any)].map((num, i) => {
                  return (
                    <Box display="flex" key={i} justifyContent="center" alignItems="center" flexDirection="column" color="rgba(11, 112, 225, 1)">
                      <VerificationInput
                        autoComplete="off"
                        autoFocus={i === 0}
                        type={showPassword ? 'number' : 'password'}
                        inputProps={{
                          type: 'text',
                          autoComplete: 'off',
                          'aria-autocomplete': 'none',
                          className: `input-${i}`,
                          'aria-label': `Number ${i + 1}`,
                          'data-index': i,
                          pattern: '[0-9]*',
                          inputtype: 'numeric',
                          onChange: handleChange,
                          onKeyDown: (e: any) => handleKeyDown(e, i),
                          onFocus: handleFocus,
                          maxLength: 1,
                        }}
                        inputRef={(ref) => (inputRef.current[i] = ref)}
                      />
                      <Box lineHeight="0" width="20px" height="2px" bgcolor={isError ? 'rgba(221, 76, 35, 1)' : 'rgba(14, 115, 228, 1)'} />
                    </Box>
                  );
                })}

                <IconButton onClick={handleShowPassword} edge="end" className={classes.iconPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Stack>
            </Box>
            {isError && (
              <Box display="flex" alignItems="center" position="absolute" sx={{ top: '80px' }}>
                <img src={ic_error_triangle} alt="ac" />
                <Typography data-hj-allow fontSize="12px" color="rgba(221, 76, 35, 1)" ml={1}>
                  {errorText}
                </Typography>
              </Box>
            )}
            <Grid container columns={12} spacing={1}>
              {keyboardValueOption.map((item: string) => {
                return (
                  <Grid md={4} key={item} item>
                    <Button
                      className={`${classes.numpadItem} ${item === 'Delete' && classes.numpadDel} ${item === 'OK' && classes.numpadOK}`}
                      sx={{ opacity: isSubmitted ? 0.4 : 1 }}
                      disabled={isSubmitted}
                      onPointerDown={(e) => {
                        e.preventDefault();
                        handleChangeNumpad(item);
                      }}
                      id={`key-${item}`}
                    >
                      {renderNumpadItem(item)}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </PasscodeWrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export { ModalPasscode };
