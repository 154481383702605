import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import bg_passcode from 'assets/images/background/bg_passcode.svg';
import ic_arrow_left from 'assets/images/icons/ic_arrow_left.svg';
import ic_close from 'assets/images/icons/ic_close.svg';
import ic_error_triangle from 'assets/images/icons/ic_error_triangle.svg';
import ic_success from 'assets/images/icons/ic_success.svg';
import rich_logo from 'assets/images/rich_logo.svg';
import useNetwork from 'hooks/useNetwork';
import { FocusEvent, FormEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateCheckoutPasscodeModal, updatePasscode } from 'redux/slices/global';
import { RootState } from 'redux/store';
import businessService from 'services/business.service';
import * as yup from 'yup';
import useStyles, { VerificationInput } from './style';
// import useFontFaceObserver from 'use-font-face-observer';

const defaultWrongPasscodeMessage = 'Passcode is not correct';
type Props = {
  children?: React.ReactNode;
  isCheckout?: boolean;
  fullScreen?: boolean;
};

type InputOrNull = HTMLInputElement | null;

export default function PassCodeGuard({ children, isCheckout, fullScreen = false }: Props) {
  const isOnline = useNetwork();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>(defaultWrongPasscodeMessage);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { passcode = {}, checkoutPasscodeModal } = useAppSelector((state: RootState) => state.global);
  const checkPasscode = Object.keys(passcode);
  const isAuthTest = checkPasscode.length !== 0 ? true : false;

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const pageUrl: any = {
    '/checkout': 'Checkout',
    '/report': 'Report',
    '/campaign': 'Campaign',
    '/turn-tracker': 'TurnTracker',
    '/store-settings': 'StoreSettings',
    '/config-settings': 'ConfigSettings',
    '/booking': 'Booking',
  };

  useEffect(() => {
    if (!isCheckout) {
      const { pathname = 'xxxxxx-xxx', search } = location || {};
      if (Boolean(search) || !Object.keys(pageUrl).includes(pathname)) {
        const _rootPath = pathname.split('/')[1];
        if (isAuthTest) {
          return;
        } else {
          if (!_rootPath) {
            navigate('/checkout');
          } else {
            navigate(`/${_rootPath}`);
          }
        }
      } else {
        return;
      }
    }
  }, [passcode, location.pathname, location.search]);

  useEffect(() => {
    return () => {
      setIsError(false);
      setErrorText(defaultWrongPasscodeMessage);
    };
  }, [location]);

  const handleSubmitPasscode = async (data: any) => {
    setLoadingSubmit(true);
    try {
      if (!isOnline) {
        toast.error("You're now offline, check your connection");
        setIsError(true);
      } else {
        const pathName = `/${location.pathname.split('/')[1]}`;
        const response = await businessService.loginPasscode({
          passcode: data.join(''),
          page: (pageUrl as any)[`${pathName}`],
        });

        if (!response.success && response?.data?.message) {
          setIsError(true);
          setErrorText(response?.data?.message || 'Something went wrong, please try again later');
        } else {
          setIsError(false);
          setErrorText(defaultWrongPasscodeMessage);
          dispatch(
            updatePasscode({
              ...response.data.data,
              page: (pageUrl as any)[`${location.pathname}`],
            }),
          );

          if (isCheckout) {
            const { permission } = response?.data?.data;
            const permissionList = Array.isArray(permission) ? permission : [];
            const checkoutPasscodeModalClone = { ...checkoutPasscodeModal };
            const { action, actionType, staffId } = checkoutPasscodeModalClone;
            if (actionType === 'cashDrawer') {
              if (permissionList.includes('cashDrawer')) {
                dispatch(updateCheckoutPasscodeModal({ open: false, action: null, actionType: null }));
                action();
              } else {
                setIsError(true);
                setErrorText('You don’t have enough perrmission.');
              }
            } else if (actionType === 'externalCredit') {
              if (permissionList.includes('externalCreditCard')) {
                dispatch(updateCheckoutPasscodeModal({ open: false, action: null, actionType: null }));
                dispatch(action);
              } else {
                setIsError(true);
                setErrorText('You don’t have enough perrmission.');
              }
            } else if (actionType === 'payroll') {
              if ((staffId && staffId === response.data.data.staffId) || response.data.data.role === 'Owner' || response.data.data.role === 'INTERNAL') {
                dispatch(updateCheckoutPasscodeModal({ open: false, action: null, actionType: null }));
                dispatch(action);
              } else {
                setIsError(true);
                setErrorText('You don’t have enough perrmission.');
              }
            } else if (actionType === 'unlockTicket') {
              if (permissionList.includes('unlockTicket')) {
                dispatch(updateCheckoutPasscodeModal({ open: false, action: null, actionType: null }));
                action();
              } else {
                setIsError(true);
                setErrorText('You don’t have enough perrmission.');
              }
            }
          }
        }
      }
    } catch (error) {
      setIsError(true);
    }
    setLoadingSubmit(false);
  };
  return (
    <>
      {!isCheckout &&
        (!isAuthTest ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PassCodeVerify
              onSubmitPasscode={handleSubmitPasscode}
              loadingSubmit={loadingSubmit}
              isError={isError}
              errorText={errorText}
              onChangeErrorStatus={() => setIsError(false)}
            />
          </Box>
        ) : (
          children || <Outlet />
        ))}

      {isCheckout &&
        (checkoutPasscodeModal.open && fullScreen ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PassCodeVerify
              isCheckout={isCheckout}
              onSubmitPasscode={handleSubmitPasscode}
              loadingSubmit={loadingSubmit}
              isError={isError}
              errorText={errorText}
              onChangeErrorStatus={() => setIsError(false)}
            />
          </Box>
        ) : (
          children || <Outlet />
        ))}
    </>
  );
}

function PassCodeVerify({ length = 4, onSubmitPasscode, isError, errorText, onChangeErrorStatus, isCheckout }: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes: any = useStyles();
  const schema = yup
    .array()
    .required()
    .of(yup.number().required())
    .when('$length', (len, schema) => {
      if (len) return schema.length(len);
      else return schema;
    });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [code, setCode] = useState<string[]>(Array(length).fill(''));
  const update = useCallback((index: number, val: string) => {
    return setCode((prevState) => {
      const slice = prevState.slice();
      slice[index] = val;
      return slice;
    });
  }, []);

  useEffect(() => {
    if (isError) {
      setCode(Array(length).fill(''));
      setCount(1);
    }
  }, [isError]);

  const formRef = useRef<HTMLFormElement>(null);

  function handleKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevInput: InputOrNull = form.querySelector(`.input-${prevIndex}`);
    const nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);
    switch (evt.key) {
      case 'Backspace':
        if (code[index]) update(index, '');
        else if (prevInput) prevInput.select();
        break;
      case 'ArrowRight':
        evt.preventDefault();
        if (nextInput) nextInput.focus();
        break;
      case 'ArrowLeft':
        evt.preventDefault();
        if (prevInput) prevInput.focus();
    }
  }

  function handleChange(evt: FormEvent<HTMLInputElement>) {
    const value = evt.currentTarget.value;
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    let nextIndex = index + 1;
    let nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);

    update(index, value[0] || '');
    if (value.length === 1) nextInput?.focus();
    else if (index < length - 1) {
      const split = value.slice(index + 1, length).split('');
      split.forEach((val) => {
        update(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = form.querySelector(`.input-${nextIndex}`);
      });
    }
  }

  const handleChangeNumpad = (value: string) => {
    const form: any = formRef.current;
    const index = code.findIndex((item) => item.length < 1);

    const nextInput: InputOrNull = form.querySelector(`.input-${index + 1}`);
    const prevInput: InputOrNull = form.querySelector(`.input-${index - 1}`);

    if (value === 'OK') {
    } else if (value !== 'Delete') {
      update(index, value);
      code.every((item) => item.length > 0) && setIsSubmitted(true);
      nextInput?.focus();
    } else {
      update(index - 1, '');
      prevInput?.focus();
    }
  };

  function handleFocus(evt: FocusEvent<HTMLInputElement>) {
    evt.currentTarget.select();
  }

  useEffect(() => {
    return () => {
      setCode(Array(length).fill(''));
      setCount(1);
    };
  }, [location]);

  useEffect(() => {
    // check validity if form has been submitted
    if (isSubmitted) {
      try {
        setIsValid(schema.isValidSync(code, { context: { length } }));
      } catch (e) {}
    }

    if (code.join('') !== '') {
      onChangeErrorStatus();
    }
  }, [code]);

  async function handleSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    setIsSubmitted(true);
    try {
      const data = await schema.validate(code, { context: { length } });
      onSubmitPasscode(data);
    } catch (e) {
      setIsValid(false);
    }
    setIsSubmitted(false);
  }
  const [count, setCount] = useState<number>(1);
  // const isFontLoaded = useFontFaceObserver([{ family: 'Password' }]);

  const onBack = () => {
    if (isCheckout) {
      dispatch(updateCheckoutPasscodeModal({ open: false, action: null, actionType: null }));
    } else {
      navigate('/checkout');
    }
  };

  useEffect(() => {
    if (!code.join('') && count < 3) {
      const form = formRef.current;
      if (isNaN(0) || form === null) return; // just in case
      const index = 0;
      const currentInput: InputOrNull = form.querySelector(`.input-${index}`);
      currentInput?.focus();
      setCount(count + 1);
    }
  }, [code, formRef, count]);

  useEffect(() => {
    if (!code.includes('')) {
      setIsSubmitted(true);
      onSubmitPasscode(code);
    } else {
      setIsSubmitted(false);
    }
  }, [code]);

  const keyboardValueOption: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Delete', '0', 'OK'];

  return (
    <Grid container bgcolor={'white'} height="100%" px={1} py={2} gap={3} columns={{ xs: 12, sm: 12, md: 13, lg: 13 }} overflow="scroll">
      <Grid
        lg={6.5}
        md={6.5}
        xs={12}
        sx={{
          pl: 1,
          boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.16)',
          borderRadius: 1,
        }}
        item
      >
        <Box mb={3}>
          <img src={rich_logo} alt="Rich Logo" />
        </Box>
        <Box>
          <img src={bg_passcode} alt="Background Passcode" height={750} width="100%" />
        </Box>
      </Grid>
      <Grid md={6.1} lg={6.2} xs={12} item>
        <Box
          sx={{
            margin: '0 auto',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" mb={8}>
            <Button className={classes.btnBack} onClick={onBack}>
              <Box mr={'16px'}>
                <img src={ic_arrow_left} alt="Arrow Left" />
              </Box>
              Back
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography data-hj-allow className={classes.heading}>
              Enter Passcode
            </Typography>
            <Typography data-hj-allow className={classes.subHeading}>
              Please enter the code in the box below to continue.
            </Typography>

            <PasscodeInput
              handleSubmit={handleSubmit}
              formRef={formRef}
              isSubmitted={isSubmitted}
              code={code}
              isError={isError}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              handleFocus={handleFocus}
              classes={classes}
              errorText={errorText}
            />

            <PasscodeKeyBoard keyboardValueOption={keyboardValueOption} classes={classes} isSubmitted={isSubmitted} handleChangeNumpad={handleChangeNumpad} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const PasscodeInput = ({ handleSubmit, formRef, isSubmitted, isError, code, handleChange, handleKeyDown, handleFocus, classes, errorText }: any) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleCLickFocus = () => {
    const form: any = formRef.current;
    const index = code.findIndex((item: any) => item.length < 1);
    const currentInput: InputOrNull = form.querySelector(`.input-${index}`);
    currentInput?.focus();
  };
  return (
    <Box mb={5} width="50%" component="form" ref={formRef} onSubmit={handleSubmit} onClick={handleCLickFocus} position="relative">
      <Box
        borderRadius="4px"
        padding={2}
        sx={{
          cursor: 'text',
          border: `1px solid ${isError ? 'rgba(230, 96, 33, 1)' : 'rgba(14, 115, 228, 1)'}`,
          opacity: isSubmitted ? 0.4 : 1,
        }}
      >
        <Stack direction={'row'} justifyContent={'center'} gap={2} position="relative">
          {code.map((value: any, i: number) => {
            return (
              <Box key={i} display="flex" justifyContent="center" alignItems="center" flexDirection="column" color="rgba(11, 112, 225, 1)">
                <VerificationInput
                  data-test-id="passcode-input-item"
                  value={value}
                  autoFocus={showPassword}
                  disabled={isSubmitted}
                  autoComplete="off"
                  inputProps={{
                    type: 'text',
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    className: `input-${i}`,
                    'aria-label': `Number ${i + 1}`,
                    'data-index': i,
                    pattern: '[0-9]*',
                    inputtype: 'numeric',
                    onChange: handleChange,
                    onKeyDown: handleKeyDown,
                    onFocus: handleFocus,
                  }}
                />
                <Box lineHeight="0" width="20px" height="2px" bgcolor={isError ? 'rgba(221, 76, 35, 1)' : 'rgba(14, 115, 228, 1)'} />
              </Box>
            );
          })}
          <IconButton onClick={handleShowPassword} edge="end" className={classes.iconPassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Stack>
      </Box>
      {isError && (
        <Box display="flex" alignItems="center" position="absolute">
          <img src={ic_error_triangle} alt="ac" />
          <Typography data-hj-allow fontSize="12px" color="rgba(221, 76, 35, 1)" ml={1}>
            {errorText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const PasscodeKeyBoard = ({ keyboardValueOption, classes, isSubmitted, handleChangeNumpad }: any) => {
  const renderNumpadItem = (item: string) => {
    switch (item) {
      case 'Delete':
        return <img src={ic_close} alt="Delete" />;
      case 'OK':
        return <img src={ic_success} alt="OK" />;
      default:
        return item;
    }
  };

  return (
    <Grid container columns={{ md: 12 }} width="55%" spacing={3}>
      {keyboardValueOption.map((item: string) => {
        return (
          <Grid md={4} key={item} item>
            <Button
              className={`${classes.numpadItem} ${item === 'Delete' && classes.numpadDel} ${item === 'OK' && classes.numpadOK}`}
              sx={{ opacity: isSubmitted ? 0.4 : 1 }}
              disabled={isSubmitted}
              onPointerDown={(e) => {
                e.preventDefault();
                handleChangeNumpad(item);
              }}
              id={`key-${item}`}
            >
              {renderNumpadItem(item)}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

PassCodeGuard.PasscodeInput = PasscodeInput;
PassCodeGuard.PasscodeKeyBoard = PasscodeKeyBoard;
