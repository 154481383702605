/* eslint-disable react/jsx-no-bind */
import { ReactNode, useEffect, useState } from 'react';
import utilsService from 'services/utils.service';
import { getUserInfo } from 'services/axiosInstance';
// libs
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

// material core
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

type IProps = {
  children: ReactNode;
};

const DefaultPage = ({ children }: IProps) => {
  const [boundaryKey, setBoundaryKey] = useState(0);

  const memorizedSyncLog = async (message?: any, componentStack?: any) => {
    const { tenantId, username, role }: any = getUserInfo() || {};
    const errParams = {
      level: 'ERROR',
      datetime: new Date().toUTCString(),
      userAgent: window.navigator.userAgent,
      description: 'platform.description',
      error: JSON.stringify(message),
      componentStack: JSON.stringify(componentStack),
      location: window.location.href,
      // version: '1.0.0',
      userInfo: {
        tenantId,
        username,
        role,
      },
    };

    localStorage.setItem('errorLog', JSON.stringify(errParams));

    if (!`${window.location.href || ''}`.includes('localhost') && errParams.error) {
      await utilsService.loggingError(errParams);
    }
  };

  useEffect(() => {
    // async function sendLogWhenOnline() {
    //   const getLogFromStorage: string | null = localStorage.getItem('errorLog');
    //   if (getLogFromStorage) {
    //     memorizedSyncLog();
    //   }
    // }
    // sendLogWhenOnline();

    window.onerror = async (message, _, __, ___, errorObj) => {
      memorizedSyncLog(message, errorObj?.stack);
    };

    return () => {
      window.onerror = null;
    };
  }, [memorizedSyncLog]);

  const refresh = () => {
    return window.location.reload(); // refresh the page
  };

  const ErrorFallbackUI: any = ({ resetErrorBoundary }: FallbackProps) => {
    return (
      <Dialog onClose={resetErrorBoundary} fullWidth open>
        <DialogTitle>
          <Typography data-hj-allow variant="h4">
            Error
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Something went wrong, please refresh the page and try again!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={refresh} color="primary" autoFocus>
            Refresh
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ErrorBoundary resetKeys={[boundaryKey]} FallbackComponent={ErrorFallbackUI} onReset={() => setBoundaryKey((prev) => prev + 1)}>
      {children}
    </ErrorBoundary>
  );
};

export default DefaultPage;
