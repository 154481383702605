import { useEffect } from 'react';
import { updateStatusDevice } from 'redux/slices/checkout';
import { dispatch, RootState, useAppSelector } from 'redux/store';
import cloverService from 'services/clover.service';

const USB_MODE = 'USB';

export const useConnection = () => {
  const { checkoutSettings } = useAppSelector((state: RootState) => state.global);

  function setIntervalImmediately(func: any, interval: number) {
    func();
    return setInterval(() => func(), interval);
  }

  // useEffect(() => {
  //   const deviceId = checkoutSettings?.cloverSettings?.USB?.deviceId;
  //   const mode = checkoutSettings?.cloverSettings?.chosen;
  //   const apiToken = checkoutSettings?.cloverSettings?.cloud?.apiToken;
  //   const intervalId = setIntervalImmediately(() => {
  //     checkConnection({ mode, deviceId, apiToken });
  //   }, 10000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [JSON.stringify(checkoutSettings)]);

  const checkConnection = async ({ mode, deviceId, oAuthToken, posId }: any) => {
    let result = false;
    try {
      if (mode === USB_MODE) {
        result = await checkUSBConnection({ deviceId });
      } else {
        result = await checkCloudConnection({ deviceId, oAuthToken, posId });
      }
    } catch (error) {
      console.error('Handle clover connection error with mode: ', mode, error);
    }
    dispatch(updateStatusDevice(result));
  };

  const checkUSBConnection = async ({ deviceId }: { deviceId?: string }) => {
    try {
      if (!deviceId) return false;
      const testReady = await cloverService.checkCloverReady({ deviceId });
      return testReady?.success;
    } catch (error) {
      throw error;
    }
  };

  const checkCloudConnection = async ({ deviceId, oAuthToken, posId }: any) => {
    try {
      const testCloudRes = await cloverService.testConnectionCloud({
        deviceId,
        posId,
        cloverToken: oAuthToken,
      });
      return testCloudRes?.success;
    } catch (error) {
      throw error;
    }
  };
};
