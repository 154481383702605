import { Avatar, Box, Button, CircularProgress, MenuItem } from '@mui/material';
import { alpha } from '@mui/material/styles';
import icConnect from 'assets/images/icons/ic_connected.svg';
import icDisConnect from 'assets/images/icons/ic_disconnected.svg';
import MenuPopover from 'components/MenuPopover';
import { useConnection } from 'hooks/useConnection';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { updateStatusDevice } from 'redux/slices/checkout';
import { dispatch, RootState, useAppSelector } from 'redux/store';
import cloverService from 'services/clover.service';
const USB_MODE = 'USB';

export default function AccountPopover() {
  useConnection();
  const { status_device = false } = useAppSelector((state: RootState) => state.checkout);
  const { checkoutSettings = {} } = useAppSelector((state: RootState) => state.global);
  // const deviceId = checkoutSettings?.cloverSettings?.USB?.deviceId;
  const mode = checkoutSettings?.cloverSettings?.chosen;
  const deviceId = mode === 'USB' ? checkoutSettings?.cloverSettings?.USB?.deviceId : checkoutSettings?.cloverSettings?.cloud?.chosen;

  const oAuthToken = checkoutSettings?.cloverSettings?.cloud?.oAuthToken;
  const posId = checkoutSettings?.cloverSettings?.cloud?.posId;

  const [open, setOpen] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handleOpen = (event: any) => {
    // if (status_device) {
    //   return;
    // }
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleConnectDevice = async ({ mode, deviceId, oAuthToken, posId }: any) => {
    setLoadingSubmit(true);
    let result = false;
    try {
      if (mode === USB_MODE) {
        result = await handleConnectUSB({ deviceId });
      } else {
        result = await handleConnectCloud({ deviceId, oAuthToken, posId });
      }
    } catch (error) {
      console.error('Handle clover connection error with mode: ', mode, error);
    }
    dispatch(updateStatusDevice(result));
    if (result) {
      toast.success('Connect to device success.');
    }
    setTimeout(() => {
      setLoadingSubmit(false);
      handleClose();
    }, 300);
  };

  // const handleDisConnectDevice = async () => {
  //   setLoadingSubmit(true);
  //   dispatch(updateStatusDevice(false));
  //   setTimeout(() => {
  //     setLoadingSubmit(false);
  //     toast.success('Disconnect to device success.');
  //     handleClose();
  //   }, 500);
  // };

  const handleConnectUSB = async ({ deviceId }: { deviceId?: string }) => {
    try {
      if (!deviceId) return false;
      const initClover = await cloverService.initCloverSession({ deviceId });
      if (!initClover.success) throw new Error();
      const testReady = await cloverService.checkCloverReady({ deviceId });
      if (!testReady.success) throw new Error();
      return testReady?.success;
    } catch (error) {
      throw error;
    }
  };

  const handleConnectCloud = async ({ deviceId, oAuthToken, posId }: any) => {
    try {
      const testCloudRes = await cloverService.testConnectionCloud({
        deviceId,
        posId,
        cloverToken: oAuthToken,
      });
      if (!testCloudRes.success) throw new Error();
      return testCloudRes?.success;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        disableFocusRipple
        disableRipple
        disableElevation
        sx={{
          p: 0,
          minWidth: '0 !important',
          '&.Mui-focusVisible': {
            background: 'none !important',
          },
          ...((open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.2),
            },
          }) as any),
        }}
      >
        <Box sx={{ width: '30px', height: '30px' }}>
          <Avatar
            variant="square"
            src={status_device ? icConnect : icDisConnect}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          ></Avatar>
        </Box>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem sx={{ p: 0 }}>
          {status_device ? (
            <Button
              disabled={loadingSubmit}
              sx={{ height: '56px', width: '100%', color: '#0E73E4', fontWeight: 600, fontSize: '16px' }}
              onClick={() => handleConnectDevice({ mode, deviceId, oAuthToken, posId })}
            >
              {loadingSubmit ? <CircularProgress /> : 'Refresh'}
            </Button>
          ) : (
            <Button
              sx={{ height: '56px', width: '100%', color: '#0E73E4', fontWeight: 600, fontSize: '16px' }}
              onClick={() => handleConnectDevice({ mode, deviceId, oAuthToken, posId })}
            >
              {loadingSubmit ? <CircularProgress /> : 'Connect'}
            </Button>
          )}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
