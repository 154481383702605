import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UpdateVersionIcon from 'assets/images/icons/ic_update_version.svg';
import * as React from 'react';
import { useEffect } from 'react';
import { updateIsOpenUpdateVersionDialog } from 'redux/slices/global';
import { RootState, dispatch, useAppSelector } from 'redux/store';

export default function UpdateVersionDialog() {
  const { isOpenUpdateVersionDialog } = useAppSelector((state: RootState) => state.global);
  const [count, setCount] = React.useState<number>(60);

  const handleUpdate = () => {
    dispatch(updateIsOpenUpdateVersionDialog(false));
    window.location.reload();
  };

  const handleClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    } else {
      dispatch(updateIsOpenUpdateVersionDialog(false));
    }
  };

  useEffect(() => {
    let intervalId: any;

    if (isOpenUpdateVersionDialog) {
      intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 1) {
            clearInterval(intervalId);
            dispatch(updateIsOpenUpdateVersionDialog(false));
            window.location.reload();
          }
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
      setCount(60);
    };
  }, [isOpenUpdateVersionDialog]);

  return (
    <Dialog onClose={handleClose} fullWidth open={isOpenUpdateVersionDialog} disableEscapeKeyDown>
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography data-hj-allow variant="h4">
          Update Version <span style={{ color: '#228800' }}></span>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
          <DialogContentText
            sx={{
              width: '80%',
              textAlign: 'center',
              '& span': {
                fontWeight: 600,
              },
            }}
          >
            <div>
              The system will automatically update to the new version within the next <span>{count}</span> seconds.
            </div>
          </DialogContentText>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
          <img src={UpdateVersionIcon}></img>
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(updateIsOpenUpdateVersionDialog(false));
          }}
          style={{ borderRadius: '5px', width: '35%' }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleUpdate} style={{ borderRadius: '5px', width: '35%' }}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
