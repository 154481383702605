import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GlobalState } from 'redux/slices/global';
import { RootState, useAppSelector } from 'redux/store';
import { formatNumberPrice } from 'utils/common';
import { PREFIX_CURRENCY } from 'utils/constants';

const useStyles = makeStyles(() => ({
  btnConfirm: {
    color: 'white',
    width: '200px',
    padding: '11px 22px',
    background: '#0E73E4',
    '&:hover': {
      background: '#0E73E4',
    },
  },
  price: {
    fontSize: '48px',
    fontWeight: 600,
  },
  dialogContent: {
    padding: '0px',
  },
}));
const ModalConfirmCashChange = ({ open, onClose, maxWidth, modalData, onConfirm, ...other }: any) => {
  const classes: any = useStyles();
  const { businessInfo } = useAppSelector((state: RootState) => state.global as GlobalState);
  return (
    <Dialog
      {...other}
      fullWidth={true}
      maxWidth={maxWidth}
      sx={{
        width: '600px',
        margin: '0 auto',
        zIndex: 9999,
      }}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent dividers className={classes.dialogContent}>
        <Box height="7rem" pt={5} display="flex" justifyContent="center" alignItems="center" gap={2}>
          <Typography data-hj-allow>Cash change{':'} </Typography>
          <Typography data-hj-allow className={classes.price}>
            {PREFIX_CURRENCY[businessInfo.currency]}
            {formatNumberPrice(modalData?.cashChange)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className={classes.btnConfirm} onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmCashChange;
