import axios from 'axios';
import { LOCALSTORAGE_KEY } from 'configs';
import { toast } from 'react-toastify';
import { MAINDOMAIN_LANDING } from 'services/appDomain';
import sessionService from 'services/session.service';
import authService from './auth.service';
import { store } from '../redux/store';
import { updateAccessToken } from '../redux/slices/global';

const messageNotifyEnglish = {
  GeneralInternalServerError: 'Server error',
  GeneralInvalidParameters: 'Invalid parameters',
  TokenIsExpired: 'Token is expired',
  Unauthorized: 'Unauthorized',
  NewPasswordIsRequired: 'NewPassword is required',
  UsernameIsRequired: 'Username is required',
  UserAlreadyExisted: 'User already existed',
  InvalidPassword: 'Invalid password',
  GroupNotFound: 'Group not found',
  AliasAlreadyExisted: 'Alias already existed',
  BusinessNameIsRequired: 'BusinessName is required',
  BusinessPhoneIsRequired: 'BusinessPhone is required',
  IndustryIsRequired: 'Industry is required',
  TenantIsNotExisted: 'Tenant is not existed',
  TenantIdIsRequired: 'TenantId is required',
  UserNotFound: 'User not found',
  UsernamePasswordIsRequired: 'Username and Password is required',
  TicketIsNotExisted: 'Ticket is not existed',
  ServiceIsNotExisted: 'Service is not existed',
  InvalidUserUsername: 'Invalid Username',
  PasswordIsRequired: 'Password is required',
  IncorrectUsernameOrPassword: 'Incorrect Username or Password',
  RefreshTokenIsRequired: 'RefreshToken is required',
  InvalidRefreshToken: 'Invalid refresh token',
  RefreshTokenIsExpired: 'RefreshToken is expired',
  XTenantIdIsNotMatchWithToken: 'XTenantIdIsNotMatchWithToken',
  InvalidUsernameOrPassword: 'Invalid Username or Password',
  InvalidUsername: 'Invalid Username',
  TicketAlreadyExisted: 'Ticket already existed',
  IDsWasWrong: 'ID is wrong',
  IncorrectUserRole: 'Incorrect UserRole',
  NotAuthorizedException: 'NotAuthorizedException',
  WrongPasscode: 'Passcode is wrong',
};

const apiNotShowError = [
  'pos/checkout/v2',
  'roles/login-passcode',
  'staffs',
  'staffs/info',
  'customers',
  'customers/info',
  'payment/clover/test',
  'payment/clover/sale',
  'payment/clover/refund',
  'payment/clover/cancel',
  'cash-drawer',
  'pos/checkin',
  'payment/clover/transaction/list',
  'pos/temp-ticket/complete',
  'pos/temp-ticket',
  'tags/info/remove',
];

export const getUserInfo = () => {
  const activeLogin = sessionStorage.getItem(LOCALSTORAGE_KEY.ACTIVE_LOGIN) || '';
  const userInfo = activeLogin
    ? JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY.USER_INFO}_${activeLogin}`) || '{}')
    : JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY.USER_INFO}`) || '{}');
  if (typeof userInfo === 'string') {
    return JSON.parse(userInfo);
  } else {
    return userInfo;
  }
};

export const updateUserInfo = (userInfo: any) => {
  const activeLogin = sessionStorage.getItem(LOCALSTORAGE_KEY.ACTIVE_LOGIN) || '';
  let _userInfo = {};
  if (activeLogin) {
    _userInfo = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY.USER_INFO}_${activeLogin}`) || '{}');
  } else {
    _userInfo = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY.USER_INFO}`) || '{}');
  }
  _userInfo = { ..._userInfo, ...userInfo };
  activeLogin
    ? localStorage.setItem(`${LOCALSTORAGE_KEY.USER_INFO}_${activeLogin}`, JSON.stringify(_userInfo))
    : localStorage.setItem(`${LOCALSTORAGE_KEY.USER_INFO}`, JSON.stringify(_userInfo));
  return;
};

export const handleLogout = async () => {
  try {
    sessionService.clearSession();
    window.location.href = `${MAINDOMAIN_LANDING}/login?logout=${true}&from=${window.location.origin}`;
  } catch (error) {
    console.error(error);
  }
};

const BASE_URL = process.env.REACT_BASE_URL;

const axiosInstance: any = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 200000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const publicUrl = ['/login', '/register', '/refresh-token'];

// Interceptors
// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config: any) {
    // Do something before request is sent
    if (publicUrl.includes(config?.url)) {
      return config;
    }
    // const accessToken = getAccessToken();
    const userInfo = getUserInfo();
    const { accessToken } = userInfo;
    if (!accessToken) {
      // navigate('/login');
    }
    config.headers['X-Tenant-Id'] = `${userInfo.tenantId}`;
    config.headers.authorizer = accessToken;
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  },
);

let refreshTokenPromise: null | Promise<any>;

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async (response: any) => {
    return response;
  },
  async (err: any) => {
    if (axios.isCancel(err)) {
      return Promise.reject(err);
    }

    const customId = 'custom-unique-id';
    const originalConfig = err.config;
    if (err.response.status === 401 || err.response.status === 403) {
      const response = err.response;
      const { Unauthorized, NotAuthorizedException } = messageNotifyEnglish;
      if (
        [Unauthorized, NotAuthorizedException].includes(response?.data?.messageKey) &&
        (response?.data?.details.includes('User is disabled.') || response?.data?.details.includes('Access Token has been revoked'))
      ) {
        toast.error('User status is Inactivate!', {
          toastId: customId,
        });
        setTimeout(() => {
          handleLogout();
        }, 1500);
      } else {
        const userInfo = getUserInfo();
        const { refreshToken } = userInfo;
        const { messageKey } = err.response.data;
        if (messageKey === 'TokenExpired' || messageKey === 'GeneralUnauthorized' || messageKey === 'TokenIsExpired') {
          if (!refreshTokenPromise) {
            refreshTokenPromise = authService
              .refreshToken({ refreshToken })
              .then((tokenRes: any) => {
                refreshTokenPromise = null; // clear state
                return tokenRes?.data?.data; // resolve with the new token
              })
              .catch(() => {
                handleLogout();
              });
          }

          return refreshTokenPromise.then((token) => {
            try {
              err.config.headers.authorizer = token;
              updateUserInfo({
                accessToken: token,
              });
              // Update token in redu =>, useWWebsocketConnection hook will detect change and reload socket connecttion with new token
              store.dispatch(updateAccessToken(token));
              return axiosInstance.request(err.config);
            } catch (err) {
              handleLogout();
            }
          });
        }
      }
    }

    // handle hide toast error on some api
    const messageKey = err?.response?.data?.messageKey;

    const apiUrl = originalConfig?.url;
    const checkoutShowError = apiNotShowError.filter((item) => apiUrl.includes(item));

    if (navigator.onLine && !checkoutShowError.length) {
      toast.error((messageNotifyEnglish as any)[`${messageKey}`] || 'Something went wrong.', {
        toastId: customId,
      });
    }
    return Promise.reject(err);
  },
);

export default axiosInstance;
