// Actions

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { EditingTicket } from 'features/report/tickets/interface';
import moment from 'moment';

// Define a type for the slice state
export interface TicketState {
  isLoading: boolean;
  error: null;
  listTicket: Array<any>;
  filter: {
    startDate: number;
    endDate: number;
    search: string;
  };
  meta: {
    limit: number;
    listKey: Array<any>;
  };

  defaultTicket: any;
  currentTicket: any;
  editingTicket: EditingTicket;

  modalEditDiscount: {
    type: string; // SERVICE, STAFF, TOTAL
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalConfirmPrint: {
    open: boolean;
    data: any;
  };
}

// Define the initial state using that type
const initialState: TicketState = {
  isLoading: false,
  error: null,
  listTicket: [],
  filter: {
    startDate: moment().toDate().getTime(),
    endDate: moment().toDate().getTime(),
    search: '',
  },
  meta: {
    limit: 20,
    listKey: [],
  },

  defaultTicket: {},
  currentTicket: {},
  editingTicket: {
    createdDate: '',
    timezone: '',
    ticketId: '',
    ticketPK: '',
    ticketSK: '',
    ticketStatus: '',
    customer: {
      customerId: '',
      customerName: '',
      customerPhone: '',
    },
    staffs: [
      {
        staffId: '',
        staffName: '',
        staffDiscount: 0,
        ownerDiscount: 0,
        gratuity: 0,
        selecting: false,
        services: [
          {
            serviceId: '',
            serviceName: '',
            servicePrice: 0,
            serviceDiscount: 0,
            serviceDiscountAmount: 0,
            serviceDiscounter: '',
            removeTax: false,
          },
        ],
      },
    ],
    cashDiscountAndTransactionFee: {
      calBasedOn: '',
      chosen: '',
      cashDiscount: 0,
      transactionFee: { amount: 0, percentage: 0, transactionFeeType: '' },
    },
    subTotal: 0,
    discounts: [{ id: '', percent: 0, amount: 0, discounter: '' }],
    totalDiscount: 0,
    taxesPercent: 0,
    taxesAmount: 0,
    taxesCalBasedOn: '',
    gratuity: 0,
    gratuityType: '',
    payments: [],
    total: 0,
    rewardAmount: 0,
    visitDiscount: 0,
  },

  modalEditDiscount: {
    type: 'SERVICE',
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalConfirmPrint: {
    open: false,
    data: null,
  },
};

export const ticketSlice = createSlice({
  name: 'ticket',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setListTicket: (state: TicketState, action: PayloadAction<any>) => {
      state.listTicket = [...action.payload];
    },
    updateListTicket: (state: TicketState, action: PayloadAction<any>) => {
      state.listTicket = [...action.payload];
    },
    setFilterTicket: (state: TicketState, action: PayloadAction<any>) => {
      state.filter = { ...action.payload };
    },
    setMetaTicket: (state: TicketState, action: PayloadAction<any>) => {
      state.meta = { ...action.payload };
    },

    updateDefaultTicket: (state: TicketState, action: PayloadAction<any>) => {
      state.defaultTicket = action.payload ? { ...action.payload } : null;
    },
    updateCurrentTicket: (state: TicketState, action: PayloadAction<any>) => {
      state.currentTicket = action.payload ? { ...action.payload } : null;
    },
    updateEditingTicket: (state: TicketState, action: PayloadAction<EditingTicket>) => {
      state.editingTicket = { ...action.payload };
    },

    updateModalEditDiscount: (state: TicketState, action: PayloadAction<any>) => {
      state.modalEditDiscount = { ...action.payload };
    },

    updateModalConfirmPrint: (state: TicketState, action: PayloadAction<any>) => {
      state.modalConfirmPrint = { ...action.payload };
    },
  },
});

export const {
  setListTicket,
  updateListTicket,
  setFilterTicket,
  setMetaTicket,
  updateDefaultTicket,
  updateCurrentTicket,
  updateEditingTicket,
  updateModalEditDiscount,
  updateModalConfirmPrint,
} = ticketSlice.actions;

export const handleCaculatorService = (listStaff: Array<any>) => {
  const handledStaff = listStaff.map((staff: any) => {
    const handleServce = staff.services.map((service: any) => {
      const { serviceQuantity, serviceDiscount, servicePrice, total } = service;
      const _serviceSubTotal = !serviceQuantity || !servicePrice ? '0' : serviceQuantity * servicePrice;
      const _total = total ? total : !serviceQuantity || !servicePrice || !serviceDiscount ? '' : serviceQuantity * servicePrice * (1 - serviceDiscount / 100);
      return {
        ...service,
        serviceSubTotal: _serviceSubTotal,
        total: _total,
      };
    });
    return {
      ...staff,
      services: [...handleServce],
    };
  });
  return handledStaff;
};

export default ticketSlice.reducer;
