import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '100%',
    height: '100%',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#EEF0F6',
    boxShadow: '4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #FFFFFF, 6px 6px 36px rgba(0, 0, 0, 0.06)',
    '& .MuiAvatar-root': {
      width: '100%',
      height: '100%',
    },
  },
}));

export default function MainAvatar({ src, ...other }: any) {
  const classes: any = useStyles();
  return <Avatar className={classes.avatar} src={src || `${require('assets/images/ic_user.svg')}`} {...other}></Avatar>;
}
