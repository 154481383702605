import { RootState, useAppSelector } from 'redux/store';
import { formatNumberPrice } from 'utils/common';
import { LAYOUT_MODE, CUSTOMER_TICKET_FIELDS, CUSTOMER_TICKET_KEYS, PAYMENT_METHODS, TIP_SETTING_TYPE, PREFIX_CURRENCY } from 'utils/constants';
import { shortcutTicketId } from 'utils/formatTicket';

interface Service {
  staffName: string;
  gratuity: number;
  items: Array<{
    serviceName: string;
    serviceQuantity: number;
    servicePrice: number;
    servicePriceDefault: number;
    discount: number;
    discountType: string;
  }>;
}
interface CustomerPrintLayoutParams {
  currency?: keyof typeof PREFIX_CURRENCY;
  businessName?: string;
  businessAddress?: {
    address1: string;
    address2: string;
    state: string;
    city: string;
    zipCode: string;
  };
  businessPhone?: string;
  customerReceipt?: string;
  newLine?: string;
  receiptNo?: string;
  date?: {
    from?: string;
    to?: string;
  };
  transactionNo?: string;
  entryMethod?: string;
  customerName?: string;
  customerPoint?: string;
  paymentMethod?: Array<string>;
  paymentMethodStr?: string;
  listService?: Array<Service>;
  subTotal?: number;
  discount?: number;
  transactionFee?: number;
  tax?: number;
  tip?: number;
  total?: number;
  changeDue?: number;
  cashPaid?: number;
  gratuityAmount?: number;
  signature?: string;
  notes?: string;
  discountType?: string;
  giftCardIds?: Array<any>;
  giftCardTotals?: Array<any>;
  isPartialPayment?: boolean;
  listPayment?: Array<any>;
  entryLocation?: string;
  cashDiscountAmount?: number;
}

export function passDataToCustomerPrintLayout(
  key: string,
  noteContent: string,
  mode: string,
  {
    currency = 'USD',
    businessName = 'Business Name',
    businessAddress = {
      address1: '',
      address2: '',
      state: '',
      city: '',
      zipCode: '',
    },
    businessPhone = 'Business Phone',
    customerReceipt = 'Customer Receipt',
    receiptNo = '******',
    date = { from: '******', to: '******' },
    transactionNo = '******',
    entryMethod = '******',
    customerName = '******',
    customerPoint = '',
    paymentMethod = [''],
    paymentMethodStr = '******',
    listService = [
      {
        staffName: 'Technician Name 1',
        gratuity: 0,
        items: [
          { serviceName: 'Service name 1', servicePrice: 0, servicePriceDefault: 0, serviceQuantity: 1, discount: 1, discountType: 'O' },
          { serviceName: 'Service name 2', servicePrice: 0, servicePriceDefault: 0, serviceQuantity: 2, discount: 2, discountType: 'O' },
        ],
      },
      {
        staffName: 'Technician Name 2',
        gratuity: 0,
        items: [
          { serviceName: 'Service name 3', servicePrice: 0, servicePriceDefault: 0, serviceQuantity: 1, discount: 0, discountType: 'O' },
          { serviceName: 'Service name 4', servicePrice: 0, servicePriceDefault: 0, serviceQuantity: 2, discount: 0, discountType: 'O' },
        ],
      },
    ],
    subTotal = 0,
    discount = 0,
    transactionFee = 0,
    tax = 0,
    tip = 0,
    total = 0,
    changeDue = 0,
    cashPaid = 0,
    gratuityAmount = 0,
    giftCardIds = [],
    giftCardTotals = [],
    isPartialPayment = false,
    listPayment = [],
    entryLocation = '',
    cashDiscountAmount = 0,
  }: CustomerPrintLayoutParams,
) {
  let listServiceInfo = '';
  listServiceInfo = listService
    .map((service: Service) => {
      const { items = [], staffName } = service || {};
      return `<div class="list-service">
        <p>${staffName}</p>
        <div>
          ${items
            .map((item) => {
              const {
                serviceName,
                servicePrice = 0,
                serviceQuantity = 1,
                discountType = '',
                servicePriceDefault = 0,
                discount: serviceDiscount = 0,
              } = item || {};
              return `<div>
                <div class="service">
                  <div class="service-detail">
                    <p>${serviceQuantity}</p>
                    <p>${serviceName}</p>
                  </div>
                  <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(servicePrice)}</p>
                </div>
                ${
                  !!Number(discount)
                    ? `
                        <div class="service">
                          <div class="service-detail">
                            <p></p>
                            <p class="discount">Discount (${discountType})</p>
                          </div>
                          <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice((Number(servicePriceDefault) * Number(serviceDiscount)) / 100)}</p>
                        </div>
                      `
                    : ''
                }
              </div>`;
            })
            .join('')}
        </div>
      </div>`;
    })
    .join('');

  const CUSTOMER_TICKET_PREVIEW_FIELDS = {
    [CUSTOMER_TICKET_KEYS.BUSINESS_NAME]: `<p class="header-title">${businessName}</p>`,
    [CUSTOMER_TICKET_KEYS.BUSINESS_ADDRESS]: `${
      businessAddress.address1 && businessAddress.address2 ? `<p class="header-text">${businessAddress.address1} ${businessAddress.address2}</p>` : ''
    }${
      businessAddress.state && businessAddress.city && businessAddress.zipCode
        ? `<p class="header-text">${businessAddress.state}, ${businessAddress.city}, ${businessAddress.zipCode}</p>`
        : `<p class="header-text">Business Address</p>`
    }`,
    [CUSTOMER_TICKET_KEYS.BUSINESS_PHONE]: `<p class="header-text">${businessPhone}</p>`,
    [CUSTOMER_TICKET_KEYS.CUSTOMER_RECEIPT]: `<p class="header-sub-title">${customerReceipt}</p>`,
    [CUSTOMER_TICKET_KEYS.NEW_LINE]: '<div style="border-top: 1px dashed #000" />',
    [CUSTOMER_TICKET_KEYS.RECEIPT_NO]: `<p class="info">Receipt No: <span>${shortcutTicketId(receiptNo)}</span></p>`,
    [CUSTOMER_TICKET_KEYS.DATE]: date?.to
      ? `<p class="info">Date: <span>${date.from} - ${date.to}</span></p>`
      : `<p class="info">Date: <span>${date.from}</span></p>`,
    [CUSTOMER_TICKET_KEYS.TRANSACTION_NO]: `<p class="info">Transaction No: ${transactionNo}</p>`,
    [CUSTOMER_TICKET_KEYS.ENTRY_METHOD]: `<p class="info">${paymentMethod.includes(PAYMENT_METHODS.CREDIT) ? `Entry Method: ${entryMethod}` : ``}</p>`,
    [CUSTOMER_TICKET_KEYS.CUSTOMER_NAME]: `<p class="info">Customer: <span>${customerName}</span><p>${
      Boolean(Number(customerPoint)) ? `<p class="info">${customerPoint}pts</p>` : ''
    }`,
    [CUSTOMER_TICKET_KEYS.PAYMENT_METHOD]: `<p class="info">Payment Method: <span>${mode === LAYOUT_MODE.PREVIEW ? '******' : paymentMethodStr}</span></p>
    ${
      paymentMethod.length === 1 && paymentMethod.includes(PAYMENT_METHODS.GIFT_CARD) && giftCardIds.length !== 0
        ? `<div>
            ${giftCardIds
              .map(
                (giftCardId: string, index: number) =>
                  `<div class="gift-card-item">
                    <p>- ${giftCardId}: ${PREFIX_CURRENCY[currency]}${giftCardTotals[index]}</p>
                  </div>`,
              )
              .join('')}
          </div>`
        : ``
    }
    ${
      isPartialPayment
        ? `<div class="partial-items">
        ${listPayment
          .map(
            (item: any) =>
              `<div class="partial-item">
                ${
                  item?.paymentMethodStr.toLowerCase().includes(PAYMENT_METHODS.GIFT_CARD)
                    ? `
                      ${giftCardIds
                        .map(
                          (giftCardId: string, index: number) =>
                            `
                          <div class="partial-detail">
                            <p>- Gift card (${giftCardId}):</p>
                            <p style="margin-left: 5px">${PREFIX_CURRENCY[currency]}${giftCardTotals[index]}</p>
                          </div>
                        `,
                        )
                        .join('')}
                    `
                    : `
                      <div class="partial-detail">
                        <p>- ${item?.paymentMethodStr}:</p>
                        <p style="margin-left: 5px">${PREFIX_CURRENCY[currency]}${formatNumberPrice(item?.total)}</p>
                      </div>
                    `
                }
              </div>`,
          )
          .join('')}
          </div>`
        : ``
    }`,
    [CUSTOMER_TICKET_KEYS.LIST_SERVICE]: listServiceInfo,
    [CUSTOMER_TICKET_KEYS.SUB_TOTAL]: `<div class="price">
          <p>Sub Total</p>
          <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(subTotal)}</p>
        </div>${
          listPayment.length > 1 && listPayment.some((payment: any) => payment.paymentMethodStr.toLowerCase() === PAYMENT_METHODS.CASH)
            ? `<div class="price">
              <p>Cash Discount</p>
              <p>
                -${PREFIX_CURRENCY[currency]}${formatNumberPrice(cashDiscountAmount)}
              </p>
            </div>`
            : ``
        }${
      Boolean(Number(discount))
        ? `<div class="price">
            <p>Discount</p>
            <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(discount)}</p>
          </div>`
        : ''
    }${
      Boolean(Number(transactionFee))
        ? `<div class="price"}>
            <p>Transaction Fee</p>
            <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(transactionFee)}</p>
          </div>`
        : ''
    }`,
    [CUSTOMER_TICKET_KEYS.TAX]: `
        ${
          Boolean(Number(tax))
            ? `<div class="price">
          <p>Tax</p>
          <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(tax)}</p>
        </div>`
            : ''
        }
        ${
          Boolean(Number(tip))
            ? `<div class="price checkout-gratuity">
          <p>Gratuity</p>
          <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(tip)}</p>
        </div>`
            : ''
        }
    `,

    [CUSTOMER_TICKET_KEYS.TOTAL]: `<div class="total">
          <p>Total</p>
          <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(total)}</p>
        </div>${
          paymentMethod.length === 1 && paymentMethod.includes(PAYMENT_METHODS.CASH) && Boolean(Number(changeDue))
            ? `<div class="price">
                <p>Cash Paid</p>
                <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(cashPaid)}</p>
              </div>
              <div class="price">
                <p>Change Due</p>
                <p>${PREFIX_CURRENCY[currency]}${formatNumberPrice(changeDue)}</p>
              </div>
              `
            : ``
        }`,
    [CUSTOMER_TICKET_KEYS.GRATUITY_AMOUNT]: `${
      entryLocation === TIP_SETTING_TYPE.ON_PRINTED_RECEIPT && paymentMethod.includes(PAYMENT_METHODS.CREDIT)
        ? `<div class="price checkout-gratuity gratuity-amount">
          <p>Gratuity Amount</p>
          ${
            Boolean(Number(gratuityAmount))
              ? `${PREFIX_CURRENCY[currency]}${formatNumberPrice(gratuityAmount)}`
              : '<div style="width: 120px; border-bottom: 1px dashed #919EAB; margin-bottom: 5px;" />'
          }
        </div>`
        : ''
    }`,
    [CUSTOMER_TICKET_KEYS.LAST_TOTAL]: `${
      entryLocation === TIP_SETTING_TYPE.ON_PRINTED_RECEIPT && paymentMethod.includes(PAYMENT_METHODS.CREDIT)
        ? `<div class="price checkout-gratuity gratuity-amount">
          <p>TOTAL</p>
          ${
            Boolean(Number(gratuityAmount))
              ? `${PREFIX_CURRENCY[currency]}${formatNumberPrice(gratuityAmount)}`
              : '<div style="width: 120px; border-bottom: 1px dashed #919EAB; margin-bottom: 5px;" />'
          }
        </div>`
        : ''
    }`,
    [CUSTOMER_TICKET_KEYS.SIGNATURE]: `${
      entryLocation === TIP_SETTING_TYPE.ON_PRINTED_RECEIPT && paymentMethod.includes(PAYMENT_METHODS.CREDIT)
        ? `<div class="price checkout-gratuity signature">
        <p>Signature</p>
        <div style="width: 120px; border-bottom: 1px dashed #919EAB; margin-bottom: 5px;" />
      </div>`
        : ''
    }`,
    [CUSTOMER_TICKET_KEYS.NOTES]: `<div class="footer">
    ${
      noteContent
        ? noteContent
            .split('\n')
            .map((line: string) => `<p class="footer-text">${line}</p>`)
            .join('')
        : ``
    }
      </div>`,
  };

  return CUSTOMER_TICKET_PREVIEW_FIELDS[key];
}

export const customerLayoutCss = `
  .customer-receipt-layout {
    width: 270px !important;
    height: fit-content;
    font-family: Tahoma !important;
    padding: 0px 10px;
    font-style: normal;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
  }

  .info-section {
    margin: -5px 0;
  }

  .header-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }

  .header-sub-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin: 0 0 3 0;
  }

  .header-text {
    line-height: 18px;
    text-align: center;
    margin: 0;
  }

  .footer-text {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin: 0;
  }

  .info {
    line-height: 4px;
    margin: revert;
  }

  .info span {
    font-weight: 600;
  }

  .gift-card-item {
    line-height: 18px;
    margin-left: 20px;
  }

  .gift-card-item p {
    line-height: 14px;
    font-weight: 600;
  }

  .partial-items {
    padding-left: 0;
    margin-bottom: 10px;
  }

  .partial-detail {
    display: flex;
    flex-direction: row;
  }

  .partial-item {
    font-weight: 600;
    line-height: 14px;
    margin-left: 20px;
  }

  .partial-item p {
    font-weight: 600;
    line-height: 18px;
    margin: 0;
  }

  .partial-item p::marker {
    font-weight: 600;
  }

  .section, .footer {
    display: flex;
    flex-direction: column;
  }

  .list-service p {
    line-height: 14px;
    margin: 0 0 2px 0;
  }

  .price p {
    line-height: 14px;
    margin: 3px 0;
  }

  .checkout-gratuity p {
    font-weight: 400;
    line-height: 14px;
    padding-left: 4px;
  }

  .total, .price, .service {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .service-detail {
    display: flex;
    flex-direction: row;
  }

  .service-detail p {
    padding-left: 8px;
  }

  .service-detail .discount {
    font-size: 11px;
  }

  .total p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin: 3px 0;
  }

  .gratuity-amount {
    margin-top: 12px;
  }

  .signature {
    margin-top: 34px;
  }
`;

export default function CustomerTicketPreview() {
  const { checkoutSettings } = useAppSelector((state: RootState) => state.global);
  const printLayout = checkoutSettings?.printLayout?.customerLayout || CUSTOMER_TICKET_FIELDS;

  const getPreviewField = (index: number) => {
    // Pass property noteContent in case user adds new notes
    return printLayout[index]?.display
      ? passDataToCustomerPrintLayout(printLayout[index]?.key, printLayout[index]?.noteContent, LAYOUT_MODE.PREVIEW, {
          entryLocation: TIP_SETTING_TYPE.ON_PRINTED_RECEIPT,
          paymentMethod: [PAYMENT_METHODS.CREDIT],
        })
      : '';
  };

  return (
    <html>
      <head>
        <style>{customerLayoutCss}</style>
      </head>
      <body
        style={{
          height: 'calc(100% - 20px)',
          backgroundColor: '#FFFFFF',
          width: '491px',
          border: '1px solid rgba(231, 231, 231, 0.5)',
          borderRadius: '16px',
          boxShadow: '0px 8px 24px -4px rgba(145, 158, 171, 0.16)',
          overflow: 'auto',
        }}
      >
        <div className="customer-receipt-layout" style={{ margin: '30px auto' }}>
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(0) }} />
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(1) }} />
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(2) }} />
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(3) }} />
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(4) }} />
          <div className="section">
            <div className={'info-section'}>
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(5) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(6) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(7) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(8) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(9) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(10) }} />
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(11) }} />{' '}
          <div className="section">
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(12) }} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(13) }} />
          <div className="section">
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(14) }} />
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(15) }} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: getPreviewField(16) }} />
          <div className="section">
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(17) }} />
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(18) }} />
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(19) }} />
          </div>
          {printLayout.map((_: any, index: number) => index >= 20 && <div key={index} dangerouslySetInnerHTML={{ __html: getPreviewField(index) }} />)}
        </div>
      </body>
    </html>
  );
}
