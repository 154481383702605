class SessionService {
  getAccessToken = () => localStorage.getItem('userInfo');

  isAuthenticated = () => !!this.getAccessToken();

  setSession = (key: string, accessToken: string) => {
    localStorage.setItem(key, accessToken);
  };

  logOut = () => {
    localStorage.clear();
  };

  clearSession = () => {
    localStorage.removeItem('USER_INFO');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('redux-root');
  };

  getUser = () => {
    const user = localStorage.getItem('user') || '';
    return user;
  };

  isValidToken = (accessToken: string | null) => {
    const expireTime = 1606275140.897;
    if (!accessToken) return false;

    const currentTime = Date.now() / 1000;

    return expireTime < currentTime;
  };

  setSessionClover = (deviceId: string, status: boolean) => {
    const data = { deviceId, status };
    this.setSession('sessionClover', JSON.stringify(data));
  };

  getSessionClover = () => {
    const data = localStorage.getItem('sessionClover') || '{}';
    return JSON.parse(data);
  };

  getDisplaySettings = () => {
    const data = localStorage.getItem('displaySettings') || '{}';
    const newData = JSON.parse(data);
    const {
      fontSize = {
        staff: 5,
        service: 5,
        category: 5,
      },
      layout = 'oldStyle',
    } = newData;
    return {
      fontSize,
      layout,
    };
  };

  clearSessionClover = () => {
    localStorage.removeItem('sessionClover');
  };
}

const sessionService = new SessionService();

export default sessionService;
