import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface GiftCardState {
  isFocusSearchField: boolean;
  giftCardList: Array<any>;
}

const initialState: GiftCardState = {
  isFocusSearchField: false,
  giftCardList: [],
};

export const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    setIsFocusSearchField: (state: GiftCardState, action: PayloadAction<any>) => {
      state.isFocusSearchField = action.payload;
    },
    setGiftCardList: (state: GiftCardState, action: PayloadAction<any>) => {
      state.giftCardList = action.payload;
    },
  },
});

export const { setIsFocusSearchField, setGiftCardList } = giftCardSlice.actions;

export default giftCardSlice.reducer;
