export let MAINDOMAIN_LANDING = 'https://landing.devrpay.com';

export let BASE_URL = 'https://0bzzv6zx83.execute-api.us-east-2.amazonaws.com';
//'https://api.devrpay.com';
export let TENANT_DOMAIN = `${BASE_URL}/tenant`;
export let CATALOG_DOMAIN = `${BASE_URL}/catalog`;
export let STAFF_DOMAIN = `${BASE_URL}/staff`;
export let CUSTOMER_DOMAIN = `${BASE_URL}/customer`;
export let TAG_DOMAIN = `${BASE_URL}/tag`;
export let AUTH_DOMAIN = `${BASE_URL}/auth`;
export let POS_DOMAIN = `${BASE_URL}/pos`;
export let CLOUD_DOMAIN = `${BASE_URL}/payment/clover`;
export let STAFF_SERVICE_DOMAIN = `${BASE_URL}/staff-services`;
export let CAMPAIGN_DOMAIN = `${BASE_URL}/campaign`;
export let REMINDER_DOMAIN = `${BASE_URL}/reminder`;
export let DISCOUNT_PROMOTION = `${BASE_URL}/discount-promotion`;
export let APPOINTMENT_DOMAIN = `${BASE_URL}/appointment`;

// export const CLOVER_DOMAIN = `https://787e-2001-ee0-4f10-5320-c06b-5b0f-3b2d-df5a.ngrok-free.app`;
export const CLOVER_DOMAIN = `http://localhost:5000`;

switch (`${process.env.REACT_APP_ENV}`.trim().toLowerCase()) {
  case 'dev':
    MAINDOMAIN_LANDING = 'https://landing.devrpay.com';
    BASE_URL = `https://api.devrpay.com`;
    break;
  case 'staging':
    MAINDOMAIN_LANDING = 'https://landing.stage.devrpay.com';
    BASE_URL = 'https://0bzzv6zx83.execute-api.us-east-2.amazonaws.com';
    //`https://api-stage.devrpay.com`;
    break;
  case 'prod':
    MAINDOMAIN_LANDING = 'https://landing.getrichpos.com';
    BASE_URL = `https://api.getrichpos.com`;
    break;
  default:
    break;
}

TENANT_DOMAIN = `${BASE_URL}/tenant`;
CATALOG_DOMAIN = `${BASE_URL}/catalog`;
STAFF_DOMAIN = `${BASE_URL}/staff`;
CUSTOMER_DOMAIN = `${BASE_URL}/customer`;
TAG_DOMAIN = `${BASE_URL}/tag`;
AUTH_DOMAIN = `${BASE_URL}/auth`;
POS_DOMAIN = `${BASE_URL}/pos`;
CLOUD_DOMAIN = `${BASE_URL}/payment/clover`;
STAFF_SERVICE_DOMAIN = `${BASE_URL}/staff-services`;
CAMPAIGN_DOMAIN = `${BASE_URL}/campaign`;
REMINDER_DOMAIN = `${BASE_URL}/reminder`;
DISCOUNT_PROMOTION = `${BASE_URL}/discount-promotion`;
APPOINTMENT_DOMAIN = `${BASE_URL}/appointment`;

// Using in case debug login with Landing page
// MAINDOMAIN_LANDING = 'http://localhost:3000';

export const AppDomain = {
  MAINDOMAIN_LANDING,
  BASE_URL,
  TENANT_DOMAIN,
  CATALOG_DOMAIN,
  STAFF_DOMAIN,
  CUSTOMER_DOMAIN,
  TAG_DOMAIN,
  AUTH_DOMAIN,
  POS_DOMAIN,
  CLOUD_DOMAIN,
  CLOVER_DOMAIN,
  STAFF_SERVICE_DOMAIN,
  CAMPAIGN_DOMAIN,
  REMINDER_DOMAIN,
  DISCOUNT_PROMOTION,
  APPOINTMENT_DOMAIN,
};

export default AppDomain;
