import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ICampaign {
  PK: string;
  SK: string;
  campaignId: string;
  createdAt: string;
  data: string;
  dateSend: number;
  description: string;
  discount: number;
  endDate: number;
  name: string;
  sendMethod: string;
  sendTime: string;
  startDate: number;
  status: string;
  tagId: string;
  titleEmail: string;
  totalMail: number;
  totalSMS: number;
  updatedAt: string;
  offerValueUnit: string;
  businessName: string;
  replyStop: boolean;
  exp: boolean;
  offerTo: Array<{ value: string; key: string }>;
}

export interface IReminder {
  reminderId: string;
  name: string;
  discount: number;
  discountValueUnit: string;
  totalMail: number;
  totalSMS: number;
  status: string;
}
interface CampaignState {
  campaigns: Array<ICampaign>;
  reminders: Array<IReminder>;
}

const initialState: CampaignState = {
  campaigns: [],
  reminders: [],
};

export const campaignSlice = createSlice({
  name: 'campaign',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCampaigns: (state: CampaignState, action: PayloadAction<any>) => {
      state.campaigns = [...action.payload];
    },
    setReminders: (state: CampaignState, action: PayloadAction<any>) => {
      state.reminders = [...action.payload];
    },
  },
});

export const { setCampaigns, setReminders } = campaignSlice.actions;

export default campaignSlice.reducer;
