import { useNavigate, useParams } from 'react-router-dom';
import { removeManageTicketItem, updateCurrentTicket, updateModalDeleteTicket } from 'redux/slices/checkout';
import { RootState, dispatch, useAppSelector } from 'redux/store';
import { WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import ModalDeleteTicketCheckout from '../features/checkout-nails123/components/Modal/ModalDeleteTicket';
import { LIST_ENV_ALLOW_LOCKING_TICKET } from 'utils/constants';
import { toast } from 'react-toastify';

const { REMOVE } = WS_ACTION_TYPE;
const { TEMP_TICKET_RESOLVER } = WS_ACTION;

export default function CommonModal() {
  const { checkoutId } = useParams();
  const navigate = useNavigate();
  const { manageTicket, modalDeleteTicket } = useAppSelector((state: RootState) => state.checkout);
  const { sendJsonMessage, profileId } = useAppSelector((state: RootState) => state.global);

  return (
    <>
      {modalDeleteTicket?.open && (
        <ModalDeleteTicketCheckout
          onClose={() => dispatch(updateModalDeleteTicket({ ...modalDeleteTicket, open: false, isSubmitting: false }))}
          open={modalDeleteTicket?.open || false}
          modalData={modalDeleteTicket?.data || {}}
          isSubmitting={modalDeleteTicket?.isSubmitting || false}
          onSubmitData={(ticketUUId: any) => {
            const _manageTicket = { ...manageTicket };
            const deleteTicket = _manageTicket[`${ticketUUId}`];

            if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
              if (deleteTicket?.activeProfile && deleteTicket?.activeProfile !== profileId) {
                return toast.warning('This ticket is being edited by another profile!');
              }
            }
            delete _manageTicket[`${ticketUUId}`];
            const completeTicketParams = {
              id: deleteTicket?.ticketId,
              _id: deleteTicket?._id,
              tenantId: deleteTicket?.tenantId,
              isHardDelete: true,
            };

            dispatch(removeManageTicketItem(ticketUUId));
            dispatch(updateModalDeleteTicket({ ...modalDeleteTicket, open: false, data: null }));

            const completeParams = {
              identify: 'handleDeleteTicket-2',
              eventName: REMOVE,
              data: completeTicketParams,
            };
            sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...completeParams });
            if (checkoutId === ticketUUId) {
              dispatch(updateCurrentTicket(null));
              navigate('/checkout');
            }
          }}
        />
      )}
    </>
  );
}
