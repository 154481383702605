// Actions

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICheckOutSettings } from 'common/interfaces/checkout-settings';
import moment from 'moment';
import businessService from 'services/business.service';
import cloverService from 'services/clover.service';
import sessionService from 'services/session.service';
import { PREFIX_CURRENCY } from 'utils/constants';

// Define a type for the slice state
export interface GlobalState {
  globalSearch: string;
  currentPage: {
    title: string;
    path: string;
    subMenu: boolean;
    backPath: string;
    searchInput: {
      haveSearch: boolean;
      searchText: string;
      placeholder: string;
    };
  };
  // popup update version
  isOpenUpdateVersionDialog: boolean;
  // business info
  businessInfoStatus: 'idle' | 'loading' | 'failed';
  businessInfo: {
    PK: string;
    SK: string;
    adminId: string;
    adminUsername: string;
    businessEmail: string;
    businessName: string;
    businessPhone: string;
    countryCode: string;
    currency: keyof typeof PREFIX_CURRENCY;
    data: string;
    industry: string;
    language: string;
    phoneCode: string;
    timezone: string;
    address: string;
    address2: string;
    state: string;
    city: string;
    zipCode: string;
    [key: string]: any;
  };

  paymentSettingsStatus: 'idle' | 'loading' | 'failed';
  paymentSettings: any;

  //clover
  isMainScreen: boolean;

  // checkout settings
  checkoutSettingsStatus: 'idle' | 'loading' | 'failed';
  checkoutSettings: ICheckOutSettings;

  cloverSettingsStatus: 'idle' | 'loading' | 'failed';
  cloverSettings: any;

  loadStaffStatus: 'idle' | 'loading' | 'failed';
  listStaff: Array<any>;

  loadStaffWithWorkingHoursStatus: 'idle' | 'loading' | 'failed';
  listStaffWithWorkingHours: Array<any>;

  loadCustomerStatus: 'idle' | 'loading' | 'failed';
  listCustomer: Array<any>;

  loadTagStatus: 'idle' | 'loading' | 'failed';
  listTag: Array<any>;

  loadServiceStatus: 'idle' | 'loading' | 'failed';
  listService: Array<any>;

  loadGiftCardStatus: 'idle' | 'loading' | 'failed';
  listGiftCard: Array<any>;

  passcode: any;

  checkoutPasscodeModal: any;

  isLoadingCreateTicket: boolean;

  isFullScreen: boolean;

  fontSize: {
    staff: number;
    service: number;
    category: number;
  };
  websocketClient: any;
  sendJsonMessage: any;
  lastJsonMessage: any;
  layout: 'modernStyle' | 'oldStyle';
  profileId: string | null;
  accessToken: string | null;
}

// Define the initial state using that type
const initialState: GlobalState = {
  globalSearch: '',
  currentPage: {
    title: '',
    path: '/',
    subMenu: false,
    backPath: '',
    searchInput: {
      haveSearch: false,
      searchText: '',
      placeholder: 'Search...',
    },
  },
  isOpenUpdateVersionDialog: false,
  businessInfoStatus: 'idle',
  businessInfo: {
    PK: '',
    SK: '',
    adminId: '',
    adminUsername: '',
    businessEmail: '',
    businessName: '',
    businessPhone: '',
    countryCode: '',
    currency: 'USD',
    data: '',
    industry: '',
    language: '',
    phoneCode: '',
    timezone: '',
    address: '',
    address2: '',
    state: '',
    city: '',
    zipCode: '',
  },

  paymentSettingsStatus: 'loading',
  paymentSettings: {},

  checkoutSettingsStatus: 'loading',
  checkoutSettings: {
    checkoutAmountToPoint: { checkoutAmount: 0, point: 0 },
    visit: {
      number: 0,
      percent: 0,
      amount: 0,
    },
    rewardPoint: { amount: 0, point: 0 },
    earnPoint: { cashPoint: 0, cardPoint: 0 },
    openCashDrawerAfterCheckout: false,
    passcodeSettings: { externalCreditCard: false },
    printSettings: { manualPrint: false, autoPrint: { printMerchant: false, printCustomer: false }, isPrint: false },
    cloverSettings: {
      cloud: { posId: '', oAuthToken: '', apiToken: '', devicesInfo: [{ deviceName: '', id: '' }], merchantId: '', chosen: '' },
      USB: {
        deviceId: '',
        allowOffline: false,
      },
      chosen: '',
      status: false,
    },
    scheduleJob: '',
    discounter: 'Owner Only',
    pointToGiftCard: {
      giftCard: 0,
      point: 0,
    },
    turnTracker: false,
    printLayout: {
      merchantLayout: [{ id: 0, text: '', key: '', display: false }],
      customerLayout: [{ id: 0, text: '', key: '', display: false }],
      payrollLayout: [{ id: 0, text: '', key: '', display: false }],
      technicianLayout: [{ id: 0, text: '', key: '', display: false }],
    },
    staffCheckInAndOut: false,
    totalGratuityInPayroll: '',
    cashDiscountSettings: '',
    cashDiscountAndTransactionFee: {
      cashDiscount: 0,
      chosen: '',
      calBasedOn: '',
      transactionFee: { customName: '', transactionFeeType: '', amount: 0, percentage: 0 },
    },
    entryLocation: '',
    taxSettings: '',
    staffReport: '',
    cashDrawerConnect: false,
    integrateClover: false,
    taxes: { calBasedOn: '', paymentMethod: { giftCard: false, externalCredit: false, credit: false, cash: false }, percentage: 0 },
    showSCPayroll: false,
    autoCompleteSale: false,
    showCampaign: false,
    paxDeviceIp: '',
  },

  cloverSettingsStatus: 'loading',
  cloverSettings: {},

  loadStaffStatus: 'loading',
  listStaff: [],

  loadStaffWithWorkingHoursStatus: 'loading',
  listStaffWithWorkingHours: [],

  loadCustomerStatus: 'loading',
  listCustomer: [],

  loadTagStatus: 'loading',
  listTag: [],

  loadServiceStatus: 'loading',
  listService: [],

  loadGiftCardStatus: 'loading',
  listGiftCard: [],

  passcode: {},
  isMainScreen: true,

  isLoadingCreateTicket: false,

  isFullScreen: false,

  checkoutPasscodeModal: {
    open: false,
    action: null,
  },
  websocketClient: null,
  sendJsonMessage: null,
  lastJsonMessage: null,

  fontSize: {
    staff: 0,
    service: 5,
    category: 5,
  },
  layout: 'modernStyle',
  profileId: null,
  accessToken: null,
};

// Functions
export const fetchBusinessInfo = createAsyncThunk(
  'global/fetchBusinessInfo',
  // if you type your function argument here
  async () => {
    const response = await businessService.getBusinessInfo();
    return response?.data?.data || {};
  },
);

export const fetchPaymentSettings = createAsyncThunk(
  'global/fetchPaymentSettings',
  // if you type your function argument here
  async () => {
    const response = await businessService.getPaymentInfo();
    return response?.data?.data || {};
  },
);

export const fetchCheckoutSettings = createAsyncThunk(
  'global/fetchCheckoutSettings',
  // if you type your function argument here
  async () => {
    const response = await businessService.getCheckoutSettings();
    const response2 = await cloverService.getStatusOfCashDrawer();
    const response3 = await cloverService.getPrinterDevices();
    return response?.data?.data
      ? { ...response?.data?.data, cashDrawerConnect: response2?.data?.data?.status || false, printerDevices: response3?.data?.data || [] }
      : {};
  },
);

export const fetchCloverSettings = createAsyncThunk(
  'global/fetchCloverSettings',
  // if you type your function argument here
  async () => {
    const response = await businessService.getCloverSettings();
    const { deviceId, status } = response?.data?.data || {};
    sessionService.setSessionClover(deviceId, status);
    return response?.data?.data || {};
  },
);

export const fetchListStaff = createAsyncThunk(
  'checkout/fetchListStaff',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllStaff(false);
    return response?.data?.data || [];
  },
);

export const fetchListStaffWithWorkingHours = createAsyncThunk('checkout/fetchListStaffWithWorkingHours', async () => {
  const response = await businessService.getBusinessInfo();
  const timezone = response?.data?.data?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = `${moment().tz(timezone).startOf('day').unix()}`;
  const endDate = `${moment().tz(timezone).endOf('day').unix()}`;
  const response2 = await businessService.getAllStaffWithWorkingHours(startDate, endDate);
  return response2?.data?.data || [];
});

export const fetchListTag = createAsyncThunk(
  'checkout/fetchListTag',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllTag();
    return response?.data?.data || [];
  },
);

export const fetchListService = createAsyncThunk(
  'checkout/fetchListService',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllProducts();
    return response?.data?.data || [];
  },
);

// Global slice
export const globalSlice: any = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentPage: (state: GlobalState, action: PayloadAction<any>) => {
      state.currentPage = { ...action.payload };
    },

    setBusinessInfo: (state: GlobalState, action: PayloadAction<any>) => {
      state.businessInfo = { ...action.payload };
    },

    incrementByAmount: (state: GlobalState, action: PayloadAction<number>) => {
      state.globalSearch += action.payload;
    },

    // update business settings
    updateBusinessInfo: (state: GlobalState, action: PayloadAction<any>) => {
      state.businessInfo = { ...action.payload };
    },

    // update checkout settings
    updatePaymentSettings: (state: GlobalState, action: PayloadAction<any>) => {
      state.paymentSettings = { ...action.payload };
    },

    // update checkout settings
    updateCheckoutSettings: (state: GlobalState, action: PayloadAction<any>) => {
      state.checkoutSettings = { ...action.payload };
    },

    // update clover settings
    updateCloverSettings: (state: GlobalState, action: PayloadAction<any>) => {
      state.cloverSettings = { ...action.payload };
    },

    // update list staff
    updateListStaff: (state: GlobalState, action: PayloadAction<any>) => {
      state.listStaff = [...action.payload];
    },

    updateListStaffWithWorkingHours: (state: GlobalState, action: PayloadAction<any>) => {
      state.listStaffWithWorkingHours = [...action.payload];
    },

    // update list customer
    updateListCustomer: (state: GlobalState, action: PayloadAction<any>) => {
      state.listCustomer = [...action.payload];
    },

    updateCustomer: (
      state: GlobalState,
      action: PayloadAction<{
        customerId: string;
        customerPoint: number;
        customerVisit: number;
        customerSpent: number;
        [key: string]: any;
      }>,
    ) => {
      const { customerId, customerPoint, customerVisit, customerSpent = 0, ...rest } = action.payload;

      state.listCustomer = state.listCustomer.map((c: any) => {
        let customer = { ...c };
        if (customer.customerId === customerId) {
          customer = {
            ...customer,
            ...rest,
            points: customerPoint,
            visits: customerVisit,
            totalSpend: (customer?.totalSpend || 0) + customerSpent,
          };
        }

        return customer;
      });
    },

    // update list tag
    updateListTag: (state: GlobalState, action: PayloadAction<any>) => {
      state.listTag = [...action.payload];
    },

    // update list service
    updateListService: (state: GlobalState, action: PayloadAction<any>) => {
      state.listService = [...action.payload];
    },

    updatePasscode: (state: GlobalState, action: PayloadAction<any>) => {
      state.passcode = action.payload || null;
    },
    updateIsMainScreen: (state: GlobalState, action: PayloadAction<any>) => {
      state.isMainScreen = action.payload;
    },
    updateCheckoutPasscodeModal: (state: GlobalState, action: PayloadAction<any>) => {
      state.checkoutPasscodeModal = action.payload || false;
    },
    updateWebsockerClient: (state: GlobalState, action: PayloadAction<any>) => {
      state.websocketClient = action.payload || null;
    },
    updateSendJsonMessage: (state: GlobalState, action: PayloadAction<any>) => {
      state.sendJsonMessage = action.payload || null;
    },
    updateLastJsonMessage: (state: GlobalState, action: PayloadAction<any>) => {
      state.lastJsonMessage = action.payload || null;
    },
    updateProfileId: (state: GlobalState, action: PayloadAction<any>) => {
      state.profileId = action.payload || null;
    },
    updateAccessToken: (state: GlobalState, action: PayloadAction<any>) => {
      state.accessToken = action.payload || null;
    },
    updateIsOpenUpdateVersionDialog: (state: GlobalState, action: PayloadAction<any>) => {
      state.isOpenUpdateVersionDialog = action.payload || false;
    },
    updateIsFullScreen: (state: GlobalState, action: PayloadAction<any>) => {
      state.isFullScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch business info
      .addCase(fetchBusinessInfo.pending, (state) => {
        state.businessInfoStatus = 'loading';
      })
      .addCase(fetchBusinessInfo.fulfilled, (state, action) => {
        state.businessInfo = { ...action.payload };
        state.businessInfoStatus = 'idle';
      })
      .addCase(fetchBusinessInfo.rejected, (state) => {
        state.businessInfoStatus = 'failed';
      })

      // fetch data payment settings
      .addCase(fetchPaymentSettings.pending, (state) => {
        state.paymentSettingsStatus = 'loading';
      })
      .addCase(fetchPaymentSettings.fulfilled, (state, action) => {
        state.paymentSettings = { ...action.payload };
        state.paymentSettingsStatus = 'idle';
      })
      .addCase(fetchPaymentSettings.rejected, (state) => {
        state.paymentSettings = 'failed';
      })

      // fetch data checkout settings
      .addCase(fetchCheckoutSettings.pending, (state) => {
        state.checkoutSettingsStatus = 'loading';
      })
      .addCase(fetchCheckoutSettings.fulfilled, (state, action) => {
        state.checkoutSettings = { ...action.payload };
        state.checkoutSettingsStatus = 'idle';
      })
      .addCase(fetchCheckoutSettings.rejected, (state) => {
        state.checkoutSettingsStatus = 'failed';
      })

      // fetch data clover settings
      .addCase(fetchCloverSettings.pending, (state) => {
        state.cloverSettingsStatus = 'loading';
      })
      .addCase(fetchCloverSettings.fulfilled, (state, action) => {
        state.cloverSettings = { ...action.payload };
        state.cloverSettingsStatus = 'idle';
      })
      .addCase(fetchCloverSettings.rejected, (state) => {
        state.cloverSettingsStatus = 'failed';
      })

      // fetch data staffs
      .addCase(fetchListStaff.pending, (state) => {
        state.loadStaffStatus = 'loading';
      })
      .addCase(fetchListStaff.fulfilled, (state, action) => {
        state.listStaff = [...action.payload];
        state.loadStaffStatus = 'idle';
      })
      .addCase(fetchListStaff.rejected, (state) => {
        state.loadStaffStatus = 'failed';
      })

      // fetch data staffs with working hours
      .addCase(fetchListStaffWithWorkingHours.pending, (state) => {
        state.loadStaffWithWorkingHoursStatus = 'loading';
      })
      .addCase(fetchListStaffWithWorkingHours.fulfilled, (state, action) => {
        state.listStaffWithWorkingHours = [...action.payload];
        state.loadStaffWithWorkingHoursStatus = 'idle';
      })
      .addCase(fetchListStaffWithWorkingHours.rejected, (state) => {
        state.loadStaffWithWorkingHoursStatus = 'failed';
      })

      //fetchListTag
      .addCase(fetchListTag.pending, (state) => {
        state.loadCustomerStatus = 'loading';
      })
      .addCase(fetchListTag.fulfilled, (state, action) => {
        state.listCustomer = [...action.payload];
        state.loadCustomerStatus = 'idle';
      })
      .addCase(fetchListTag.rejected, (state) => {
        state.loadCustomerStatus = 'failed';
      })

      // fetch list service
      .addCase(fetchListService.pending, (state) => {
        state.loadServiceStatus = 'loading';
      })
      .addCase(fetchListService.fulfilled, (state, action) => {
        state.listService = [...action.payload];
        state.loadServiceStatus = 'idle';
      })
      .addCase(fetchListService.rejected, (state) => {
        state.loadServiceStatus = 'failed';
      });
  },
});

export const {
  setCurrentPage,
  incrementByAmount,
  updatePaymentSettings,
  updateCheckoutSettings,
  updateCloverSettings,
  updateBusinessInfo,
  updateListStaff,
  updateListStaffWithWorkingHours,
  updateListCustomer,
  updateCustomer,
  updateListTag,
  updateListService,
  updatePasscode,
  updateCheckoutPasscodeModal,
  updateWebsockerClient,
  updateSendJsonMessage,
  updateProfileId,
  updateAccessToken,
  updateIsOpenUpdateVersionDialog,
  updateIsMainScreen,
  updateIsFullScreen,
  updateLastJsonMessage,
} = globalSlice.actions;

export default globalSlice.reducer;
