import useNetwork from 'hooks/useNetwork';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateListOfflineTicket } from 'redux/slices/checkout';
import { RootState } from 'redux/store';
import checkoutService from 'services/checkout.service';
import PassCodeGuard from './PassCodeGuard';
import { CALCULATE_TAXES_BASED_ON } from 'utils/constants';

export default function CheckoutGuard() {
  // useConnection();
  const dispatch = useAppDispatch();
  const { listOfflineTicket } = useAppSelector((state: RootState) => state.checkout);
  const { checkoutSettingsStatus, checkoutSettings } = useAppSelector((state: RootState) => state.global);
  const cashDiscountAndTransactionFee = checkoutSettingsStatus === 'idle' ? checkoutSettings?.cashDiscountAndTransactionFee : {};

  const isOnline = useNetwork();
  const counterRef = useRef(listOfflineTicket);

  function setIntervalImmediately(func: () => void, interval: number) {
    func();
    return setInterval(func, interval);
  }

  useEffect(() => {
    counterRef.current = [...listOfflineTicket];
  }, [listOfflineTicket]);

  useEffect(() => {
    const intervalId = setIntervalImmediately(() => {
      if (isOnline) {
        handleReCheckout(counterRef.current);
      } else {
        // toast.warning("Offline mode");
      }
    }, 10000);

    if (!isOnline) {
      toast.warning('Disconnected - you are now offline');
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isOnline]);

  const handleReCheckout = async (listOffline: any) => {
    const newList: any = [];
    for (let index = 0; index < listOffline.length; index++) {
      const status = await checkoutPayment(listOffline[index]);
      if (!status) {
        newList.push(listOffline[index]);
      }
    }
    dispatch(updateListOfflineTicket([...newList]));
  };

  const checkoutPayment = async (data: any) => {
    let result = false;
    const params = {
      ...data,
      taxesCalBasedOn: checkoutSettings.taxes?.calBasedOn || CALCULATE_TAXES_BASED_ON.REDUCED_PRICE,
      ticketInfo: {
        ...(data?.ticketInfo || {}),
        gratuity: Number(data?.ticketInfo?.gratuity || 0),
        cashDiscountAndTransactionFee,
      },
    };

    if (!params.ticketId) {
      result = true;
    }

    try {
      const responseCheckout = await checkoutService.checkoutTicketByQueue(params);
      if (!responseCheckout.success) {
        throw new Error(responseCheckout);
      } else {
        result = true;
      }
      return result;
    } catch (error) {
      console.log('error', error);
      checkoutService.observeCheckoutError('Error Checkout in CheckoutGuard', {
        submitData: JSON.stringify(params),
        error: JSON.stringify(error),
      });
      return false;
    }
  };
  return <PassCodeGuard isCheckout />;
}
