// Actions

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

// Define a type for the slice state
interface TransactionState {
  isLoading: boolean;
  error: null;
  listTransaction: Array<any>;
  filter: {
    filterType: string;
    startDate: number;
    endDate: number;
    search: string;
  };
  meta: {
    limit: number;
    listKey: Array<any>;
  };
}

// Define the initial state using that type
const initialState: TransactionState = {
  isLoading: false,
  error: null,
  listTransaction: [],
  filter: {
    filterType: '',
    startDate: moment().toDate().getTime(),
    endDate: moment().toDate().getTime(),
    search: '',
  },
  meta: {
    limit: 20,
    listKey: [],
  },
};

export const transactionSlice = createSlice({
  name: 'transaction',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`

    setFilterTransaction: (state: TransactionState, action: PayloadAction<any>) => {
      state.filter = { ...action.payload };
    },
  },
});

export const { setFilterTransaction } = transactionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default transactionSlice.reducer;
