import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session';
import { getPersistConfig } from 'redux-deep-persist';
// slices
import globalReducer from './slices/global';
import ticketReducer from './slices/ticket';
import payrollReducer from './slices/payroll';
import checkoutReducer from './slices/checkout';
import transactionReducer from './slices/transaction';
import campaignReducer from './slices/campaign';
import bookingReducer from './slices/booking';
import giftCardReducer from './slices/giftCard';

// ----------------------------------------------------------------------
const rootReducer = combineReducers({
  global: globalReducer,
  ticket: ticketReducer,
  payroll: payrollReducer,
  checkout: checkoutReducer,
  transaction: transactionReducer,
  campaign: campaignReducer,
  booking: bookingReducer,
  giftCard: giftCardReducer,
});

const whileListGlobalPage = [
  'global.globalSearch',
  'global.currentPage',

  'global.businessInfoStatus',
  'global.businessInfo',

  'global.checkoutSettingsStatus',
  'global.checkoutSettings',

  'global.cloverSettingsStatus',
  'global.cloverSettings',

  // 'global.loadStaffStatus',
  'global.listStaff',
  'global.listStaffWithWorkingHours',

  'global.loadCustomerStatus',
  'global.listCustomer',

  'global.loadServiceStatus',
  'global.listService',

  'global.loadGiftCardStatus',
  'global.listGiftCard',

  'global.fontSize',
  'global.layout',

  'global.passcode',
  'global.profileId',
  'global.accessToken',
];

const whileListCheckoutPage = [
  'checkout.manageTicket',
  'checkout.loadStaffStatus',
  'checkout.listStaff',
  'checkout.loadServiceStatus',
  'checkout.listService',
  'checkout.listOfflineTicket',
  'checkout.currentTicket',
];

const rootPersistConfig = getPersistConfig({
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: [...whileListGlobalPage, ...whileListCheckoutPage],
  rootReducer,
});

export { rootPersistConfig, rootReducer };
