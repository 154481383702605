export const formatNavConfig = (navConfig: Array<any>) => {
  const result: Array<any> = [];
  navConfig.forEach((listItems): any => {
    listItems.items.forEach((item: any) => {
      const { title, path, children, searchInput } = item;
      result.push({
        title,
        path,
        subMenu: false,
        searchInput: {
          ...searchInput,
        },
      });
      if (children) {
        item.children.forEach((subItem: any) => {
          const { title, path, searchInput, backPath } = subItem;
          result.push({
            title,
            path,
            backPath: backPath || item.path,
            subMenu: true,
            searchInput: {
              ...searchInput,
            },
          });
        });
      }
    });
  });
  return result;
};
