import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Https';
import { Box, Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCheckin from 'hooks/useCheckin';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CheckoutState, updateCurrentTicket, updateManageTicketItem, updateModalPayroll } from 'redux/slices/checkout';
import { updateCheckoutPasscodeModal } from 'redux/slices/global';
import { RootState } from 'redux/store';
import { getUserInfo } from 'services/axiosInstance';
import { LIST_ENV_ALLOW_LOCKING_TICKET, WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import { useStyles } from './useStyle';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { MODIFY_STATUS } = WS_ACTION_TYPE;

interface IProps {
  onClickCalendar: any;
  width: number;
}

export default function HeaderCheckout({ onClickCalendar, width }: IProps) {
  const { checkoutId } = useParams();
  const { manageTicket, modalPayroll, baseTicketDate, currentTicket } = useAppSelector((state: RootState) => state.checkout as CheckoutState);
  const { profileId, sendJsonMessage } = useAppSelector((state: RootState) => state.global);
  const [noToRender, setNoToRender] = useState<{ from: number; to: number }>({ from: 1, to: 0 });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const maxItem = noToRender.to - noToRender.from + 1;
  const dispatch: any = useAppDispatch();
  const boxRef = useRef<HTMLDivElement>();
  const anchor = useRef<any>();
  const childRef = useRef<any>();
  const dateTimeRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const navigate = useNavigate();
  const { control, setValue }: any = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      createdDate: baseTicketDate,
      startDate: baseTicketDate,
      endDate: baseTicketDate,
    },
  });

  useEffect(() => {
    setValue('createdDate', baseTicketDate);
  }, [baseTicketDate]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (currentTicket?._id) {
      const { createdDate } = currentTicket;

      setValue('createdDate', moment(createdDate));
    }
  }, [currentTicket?._id]);

  useLayoutEffect(() => {
    handleShowTicketByWidth();
  }, [manageTicket, width]);

  useEffect(() => {
    if (checkoutId) {
      const ticketInfo: any = manageTicket[`${checkoutId}`];
      const { statusTicket } = ticketInfo || {};
      if (!modalPayroll.data) {
        if (!ticketInfo || Boolean(statusTicket)) {
          navigate('/checkout');
        }
      }
      if (!ticketInfo?.activeProfile || ticketInfo?.activeProfile === profileId) {
        dispatch(updateCurrentTicket(ticketInfo));
      } else {
        dispatch(updateCurrentTicket(null));
        navigate('/checkout');
      }
    } else {
      dispatch(updateCurrentTicket(null));
    }
  }, [checkoutId, JSON.stringify(manageTicket)]);

  const onResize = debounce(() => {
    handleShowTicketByWidth();
  }, 50);

  const handleShowTicketByWidth = () => {
    if (!boxRef.current) return;

    const margin = 15;
    const { width: widthDateTime }: any = dateTimeRef.current?.getBoundingClientRect();
    const currentWidth = width - widthDateTime;
    const childWidth = (childRef.current?.getBoundingClientRect().width || 0) + margin * 2;
    const maxItem = Math.floor(currentWidth / childWidth) || 0;

    setNoToRender((prev) => ({ ...prev, from: 1, to: maxItem }));
  };

  const listTicketData = useMemo(() => {
    const userInfo = getUserInfo();

    const _data = Object.values(manageTicket);
    const dataSort = _data.sort(({ createdDate: a }: any, { createdDate: b }: any) => {
      const unixA = moment(a).unix();
      const unixB = moment(b).unix();
      return unixA > unixB ? 1 : unixA < unixB ? -1 : 0;
    });
    const ticketDataMappingColor = dataSort
      .filter((item: any) => {
        return item.tenantId === userInfo.tenantId && item?.statusTicket !== 'failed';
      })
      .map((item: any) => {
        const timeMinute = (moment().unix() - moment(item.createdDate).unix()) / 60;
        let backgroundColor = '';
        let borderColor = '';
        let textColor = '';
        if (timeMinute < 1) {
          backgroundColor = '#ECF0FA';
          borderColor = '#0C71E2';
          textColor = '#061340';
        } else if (timeMinute < 2) {
          backgroundColor = '#FFF7CD';
          borderColor = '#FFC107';
          textColor = '#7A4F01';
        } else {
          backgroundColor = '#FFE7D9';
          borderColor = '#FF4842';
          textColor = '#7A0C2E';
        }
        return {
          ...item,
          ticketIdLabel: (item.ticketId || '').substring(10),
          backgroundColor,
          borderColor,
          textColor,
        };
      });

    return ticketDataMappingColor;
  }, [JSON.stringify(manageTicket)]);

  const unlockTicket = (ticketItem: any) => {
    if (currentTicket && currentTicket?._id) {
      const updateSocketParams = {
        identify: 'updateTicketLockingStatus-8',
        eventName: MODIFY_STATUS,
        data: {
          tenantId: currentTicket?.tenantId,
          _id: currentTicket?._id,
          ticketId: currentTicket?.ticketId,
          activeProfile: '',
        },
      };
      sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
    }

    const cloneObj = { ...manageTicket };
    const ticketData = cloneObj[`${ticketItem._id}`];

    const updateParams = {
      ...ticketData,
    };

    if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
      updateParams.activeProfile = profileId;
    }

    dispatch(updateManageTicketItem(updateParams));
    navigate(`/checkout/${ticketItem._id}`);
  };

  useEffect(() => {
    let barcodeScan = '';
    const handleKeyDown = (e: any) => {
      if (e.keyCode === 13 && barcodeScan.length === 36) {
        window.history.replaceState({}, '', `/checkout/${barcodeScan}`);
        const ticketItem = listTicketData.find((item) => item._id === barcodeScan);
        handleSelectTicket({ ticketItem, unlockTicket, sendJsonMessage });
      }

      barcodeScan += e.key;
      setTimeout(() => {
        barcodeScan = '';
      }, 100);
    };

    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  // const handleChangeDate = (data: Date) => {
  //   const parsedDate = moment(data);

  //   const currentTime = moment();

  //   parsedDate.hour(currentTime.hour());
  //   parsedDate.minute(currentTime.minute());
  //   parsedDate.second(currentTime.second());
  //   parsedDate.millisecond(currentTime.millisecond());

  //   dispatch(updateBaseTicketDate(parsedDate));

  //   if (currentTicket) {
  //     const nextTicketValue = { ...currentTicket, createdDate: moment(data).unix().toString() };

  //     const ticketId: any = nextTicketValue._id;
  //     const cloneObj = { ...manageTicket };
  //     let ticketData = cloneObj[`${ticketId}`];
  //     const { manageCart = {} } = ticketData;
  //     const newManageCart = { ...manageCart };
  //     let _manageCart: any = {};
  //     _manageCart = { ..._manageCart, ...newManageCart };

  //     ticketData = {
  //       ...ticketData,
  //       createdDate: moment(data).unix().toString(),
  //       manageCart: { ..._manageCart },
  //     };
  //     dispatch(updateManageTicketItem(ticketData));
  //     dispatch(updateCurrentTicket(nextTicketValue));
  //   }
  // };

  const handleSelectTicket = ({ ticketItem, unlockTicket, sendJsonMessage }: any) => {
    if (!ticketItem.ticketId) {
      return;
    }
    if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
      if (ticketItem?.activeProfile && ticketItem?.activeProfile !== profileId) {
        const action: () => void = unlockTicket(ticketItem);
        return dispatch(updateCheckoutPasscodeModal({ open: true, action, actionType: 'unlockTicket' }));
      }
    }

    if (currentTicket && currentTicket?._id) {
      const updateSocketParams = {
        identify: 'updateTicketLockingStatus-2',
        eventName: MODIFY_STATUS,
        data: {
          tenantId: currentTicket?.tenantId,
          _id: currentTicket?._id,
          ticketId: currentTicket?.ticketId,
          activeProfile: '',
        },
      };

      sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
    }
    navigate(`/checkout/${ticketItem._id}`);

    dispatch(
      updateModalPayroll({
        ...modalPayroll,
        data: false,
      }),
    );
  };

  const isUsingByOtherProfile = ({ ticketItem }: any) => {
    let result = false;
    if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
      result = ticketItem?.activeProfile && ticketItem?.activeProfile !== profileId;
    }
    return result;
  };

  // Popup Ticket List
  const handleOpenTicketList = () => {
    setAnchorEl(anchor.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Click Prev or Next icon
  // const handleClickPrevIcon = () => {
  //   setNoToRender((prev) => {
  //     if (prev.from === 1) {
  //       return prev;
  //     }

  //     return {
  //       from: prev.from - 1,
  //       to: prev.to - 1,
  //     };
  //   });
  // };

  // const handleClickNextIcon = () => {
  //   setNoToRender((prev) => {
  //     if (prev.to === listTicketData.length) {
  //       return prev;
  //     }

  //     return {
  //       from: prev.from + 1,
  //       to: prev.to + 1,
  //     };
  //   });
  // };

  return (
    <Box ref={anchor} className={classes.headerWrapper}>
      <Box ref={dateTimeRef} className={classes.datePicker}>
        {/* <DateTimeField
          name="createdDate"
          control={control}
          onChange={handleChangeDate}
          className="sxWidth"
          disabled={currentTicket?._id ? true : false}
          disableFuture
          onClick={onClickCalendar}
        /> */}
      </Box>

      <Box className={classes.gridFlex}>
        <Box className={classes.boxFlex} ref={boxRef}>
          {/* {listTicketData.length > maxItem && (
            <div className={classes.btnBox}>
              <img className={classes.iconAction} src={PrevIcon} onClick={handleClickPrevIcon} alt="Prev" />
            </div>
          )} */}

          {listTicketData.length > 0 && (
            <div ref={childRef}>
              <HeaderCheckoutItem
                ticketItem={listTicketData?.[0]}
                activeCard={checkoutId === listTicketData[0]?._id}
                isUsingByOtherProfile={isUsingByOtherProfile({ ticketItem: listTicketData?.[0] })}
                handleSelectTicket={handleSelectTicket}
              />
            </div>
          )}
          {listTicketData?.slice(noToRender.from, noToRender.to).map((ticketItem: any) => (
            <HeaderCheckoutItem
              key={ticketItem._id}
              ticketItem={ticketItem}
              activeCard={checkoutId === ticketItem._id}
              isUsingByOtherProfile={isUsingByOtherProfile({ ticketItem })}
              handleSelectTicket={handleSelectTicket}
            />
          ))}

          {/* {listTicketData.length > maxItem && (
            <div className={classes.btnBox}>
              <img className={classes.iconAction} src={NextIcon} onClick={handleClickNextIcon} alt="Next" />
            </div>
          )} */}
        </Box>

        {listTicketData.length > maxItem && (
          <Box className={classes.btnBox}>
            <ViewMore
              classes={classes}
              anchorEl={anchorEl}
              maxItem={maxItem}
              listTicketData={listTicketData}
              checkoutId={checkoutId}
              handleOpenTicketList={handleOpenTicketList}
              handleClose={handleClose}
              isUsingByOtherProfile={isUsingByOtherProfile}
              handleSelectTicket={handleSelectTicket}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

const HeaderCheckoutItem = ({ ticketItem, activeCard, isUsingByOtherProfile, className, handleSelectTicket, unlockTicket }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleDeleteTicket } = useCheckin();
  const { checkoutId } = useParams();

  const ticketNum = ticketItem?.ticketId?.substr(ticketItem?.ticketId?.length - 4);
  const { sendJsonMessage, profileId } = useAppSelector((state: RootState) => state.global);
  const { manageTicket, currentTicket, loadTempTicketStatus } = useAppSelector((state: RootState) => state.checkout);
  const { backgroundColor, borderColor, textColor } = ticketItem;
  const { transactionList = [] } = currentTicket || {};

  useEffect(() => {
    updateSocketTemp();
  }, [sendJsonMessage, activeCard, checkoutId, loadTempTicketStatus]);

  const updateSocketTempTicket: any = useCallback(
    debounce(async ({ sendJsonMessage, ticketItem, profileId }: any) => {
      if (ticketItem?._id) {
        delete ticketItem.createdAt;
        delete ticketItem.updatedAt;
        const updateSocketParams = {
          identify: 'updateTicketLockingStatus-1',
          eventName: MODIFY_STATUS,
          data: {
            tenantId: ticketItem.tenantId,
            _id: ticketItem._id,
            ticketId: ticketItem.ticketId,
            activeProfile: '',
          },
        };

        if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
          updateSocketParams.data.activeProfile = profileId;
        }
        sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
      }
    }, 200),
    [],
  );

  const isLoadingTempTicket = loadTempTicketStatus === 'loading';

  const updateSocketTemp = () => {
    if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
      if (sendJsonMessage && activeCard && checkoutId && loadTempTicketStatus === 'idle') {
        updateSocketTempTicket({ sendJsonMessage, ticketItem, profileId });
      }
    }
  };
  return (
    <Stack
      data-test-id="ticket-item"
      key={ticketItem._id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={className}
      sx={{
        cursor: 'pointer',
        marginX: '0.25rem',
        paddingX: '16px',
        height: '100%',
        color: `${textColor}`,
        background: `${backgroundColor}`,
        borderLeft: activeCard ? '' : `3px solid ${borderColor}`,
        borderBottom: activeCard ? `6px solid ${borderColor}` : '',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#061340',
          },
        }}
        onClick={() => handleSelectTicket({ ticketItem, unlockTicket, sendJsonMessage })}
      >
        <Typography data-hj-allow>{ticketItem.staffName}</Typography>
        <Typography data-hj-allow>{ticketNum ? ticketNum : 'Creating...'}</Typography>
      </Box>
      {isUsingByOtherProfile ? (
        <LockIcon
          data-test-id="close-ticket"
          fontSize="small"
          sx={{ cursor: 'pointer', color: '#061340' }}
          onClick={() => {
            const action: () => void = unlockTicket;
            return dispatch(updateCheckoutPasscodeModal({ open: true, action, actionType: 'unlockTicket' }));
          }}
        />
      ) : (
        <IconButton
          onClick={() => {
            if (isLoadingTempTicket) {
              return;
            }

            if (transactionList.length > 0) {
              return toast.warning('Partially paid tickets cannot be deleted!');
            }
            handleDeleteTicket(ticketItem?._id);
          }}
        >
          <CloseIcon data-test-id="close-ticket" fontSize="small" sx={{ cursor: 'pointer', color: '#061340' }} />
        </IconButton>
      )}
    </Stack>
  );
};

const ViewMore = ({
  classes,
  handleOpenTicketList,
  anchorEl,
  handleClose,
  listTicketData,
  maxItem,
  isUsingByOtherProfile,
  handleSelectTicket,
  checkoutId,
}: any) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div style={{ paddingLeft: '0.25rem' }}>
      <Button
        className={[classes.buttonPrimary, `${listTicketData.length > maxItem ? classes.btnActive : ''}`].join(' ')}
        onClick={handleOpenTicketList}
        endIcon={<ExpandMoreIcon />}
        id={'ticket_list'}
      >
        View all
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: anchorEl?.getBoundingClientRect().width,
          },
        }}
      >
        <Box className={classes.boxGrid} sx={{ p: 2 }}>
          {listTicketData?.map((ticketItem: any) => {
            const activeCard = checkoutId === ticketItem?._id;
            return (
              <HeaderCheckoutItem
                className={classes.boxItem}
                key={ticketItem._id}
                ticketItem={ticketItem}
                activeCard={activeCard}
                isUsingByOtherProfile={isUsingByOtherProfile({ ticketItem })}
                handleSelectTicket={handleSelectTicket}
              />
            );
          })}
        </Box>
      </Popover>
    </div>
  );
};
