import { Box, Divider, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { IconButtonAnimate } from 'components/animate';
import MainAvatar from 'components/MainAvatar';
import MenuPopover from 'components/MenuPopover';
import { useEffect, useState } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'redux/store';
import { MAINDOMAIN_LANDING } from 'services/appDomain';
import sessionService from 'services/session.service';
import { numberToPhone } from 'utils/common';
import { updateIsFullScreen } from 'redux/slices/global';
export default function AccountPopover() {
  const { businessInfoStatus, businessInfo } = useAppSelector((state: RootState) => state.global);

  const dispatch = useAppDispatch();

  const { isFullScreen } = useAppSelector((state: RootState) => state.global);

  const [open, setOpen] = useState(null);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      sessionService.clearSession();
      window.location.href = `${MAINDOMAIN_LANDING}/login?logout=${true}&from=${window.location.origin}`;
    } catch (error) {
      console.error(error);
    }
  };

  const handleFullScreen = () => {
    dispatch(updateIsFullScreen(!document.fullscreenElement));
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    handleClose();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'F11') {
      event.preventDefault();
      handleFullScreen();
    } else if (event.key === 'Escape' && document.fullscreenElement) {
      document.exitFullscreen();
      dispatch(updateIsFullScreen(false));
      handleClose();
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      dispatch(updateIsFullScreen(document.fullscreenElement));
    };

    document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...((open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.2),
            },
          }) as any),
        }}
      >
        <Box sx={{ width: '40px', height: '40px' }}>
          <MainAvatar />
        </Box>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {businessInfoStatus !== 'loading' && (
            <>
              <Typography data-hj-allow variant="subtitle2" noWrap>
                {businessInfo?.businessName}
              </Typography>
              <Typography data-hj-allow variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {numberToPhone(businessInfo?.businessPhone)}
              </Typography>
            </>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleFullScreen} sx={{ m: 1 }}>
          {!isFullScreen ? 'FullScreen' : 'Exit FullScreen'}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
