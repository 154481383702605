// ----------------------------------------------------------------------
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),

    checkout: path(ROOTS_DASHBOARD, '/checkout'),
    checkoutDetails: path(ROOTS_DASHBOARD, '/checkout/:checkoutId'),

    transactions: path(ROOTS_DASHBOARD, '/transactions'),
    transactionDetail: path(ROOTS_DASHBOARD, '/transactions/detail'),

    tickets: path(ROOTS_DASHBOARD, '/tickets'),
    ticketDetail: path(ROOTS_DASHBOARD, '/tickets/detail'),
    ticketEdit: path(ROOTS_DASHBOARD, '/tickets/edit'),

    payrolls: path(ROOTS_DASHBOARD, '/payrolls'),
    payrollsByStaff: path(ROOTS_DASHBOARD, '/payrolls/payroll_by_staff'),

    income: path(ROOTS_DASHBOARD, '/income'),
  },
  //report
  report: {
    root: path(ROOTS_DASHBOARD, '/report'),

    payroll: path(ROOTS_DASHBOARD, '/report/payrolls'),
    payrollsByStaff: path(ROOTS_DASHBOARD, '/report/payrolls/payroll_by_staff'),

    transaction: path(ROOTS_DASHBOARD, '/report/transactions'),
    transactionDetail: path(ROOTS_DASHBOARD, '/report/transactions/detail'),

    ticket: path(ROOTS_DASHBOARD, '/report/tickets'),
    ticketDetail: path(ROOTS_DASHBOARD, '/report/tickets/detail'),
    ticketEdit: path(ROOTS_DASHBOARD, '/report/tickets/edit'),

    income: path(ROOTS_DASHBOARD, '/report/income'),

    customer: path(ROOTS_DASHBOARD, '/report/customer_setting'),
  },
  turn_tracker: {
    root: path(ROOTS_DASHBOARD, '/turn-tracker'),
  },
  campaign: {
    root: path(ROOTS_DASHBOARD, '/campaign'),
    list: path(ROOTS_DASHBOARD, '/campaign/list'),
    reminder: path(ROOTS_DASHBOARD, '/campaign/reminder'),
    detail: path(ROOTS_DASHBOARD, '/campaign/detail'),
  },
  // Settings
  store_settings: {
    root: path(ROOTS_DASHBOARD, '/store-settings'),
    services: path(ROOTS_DASHBOARD, '/store-settings/services'),
    details: path(ROOTS_DASHBOARD, '/store-settings/details'),
    customer_setting: path(ROOTS_DASHBOARD, '/store-settings/customer_setting'),
    gift_card: path(ROOTS_DASHBOARD, '/store-settings/gift-card'),
    gift_card_history: path(ROOTS_DASHBOARD, '/store-settings/gift-card/history'),
    discount_promotion: path(ROOTS_DASHBOARD, '/store-settings/discount-promotion'),
    staffs: path(ROOTS_DASHBOARD, '/store-settings/staffs'),
    staffs_info: path(ROOTS_DASHBOARD, '/store-settings/staffs/info'),
    staffs_role_permission: path(ROOTS_DASHBOARD, '/store-settings/staffs/role-permission'),
    staffs_clock_in_and_out: path(ROOTS_DASHBOARD, '/store-settings/staffs/clock-in-and-out'),
  },
  config_settings: {
    root: path(ROOTS_DASHBOARD, '/config-settings'),
    payment: path(ROOTS_DASHBOARD, '/config-settings/payment'),
    checkout_setting: path(ROOTS_DASHBOARD, '/config-settings/checkout_setting'),
    checkin_setting: path(ROOTS_DASHBOARD, '/config-settings/checkin_setting'),
    display_setting: path(ROOTS_DASHBOARD, '/config-settings/display_setting'),
    admin_setting: path(ROOTS_DASHBOARD, '/config-settings/admin_setting'),
    income_report: path(ROOTS_DASHBOARD, '/config-settings/income_report'),
    printer: path(ROOTS_DASHBOARD, '/config-settings/printer'),
    printer_custom_receipt: path(ROOTS_DASHBOARD, '/config-settings/printer/custom-receipt'),
    printer_catalogue: path(ROOTS_DASHBOARD, '/config-settings/printer/catalogue'),
    turn_tracker: path(ROOTS_DASHBOARD, '/config-settings/turn_tracker'),
    notification: path(ROOTS_DASHBOARD, '/config-settings/notification'),
    review: path(ROOTS_DASHBOARD, '/config-settings/review'),
    report_dev: path(ROOTS_DASHBOARD, '/config-settings/report-dev'),
  },
  booking: {
    root: path(ROOTS_DASHBOARD, '/booking'),
  },
};
