import * as Sentry from '@sentry/react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BASE_URL, POS_DOMAIN } from 'services/appDomain';
import { getUserInfo } from 'services/axiosInstance';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from './axiosInstance';
import { ICheckoutParams } from 'common/interfaces/checkout';
import cloverService from './clover.service';
import { formatCheckoutParams } from 'utils/checkoutCalculation';

const POS_HOST = POS_DOMAIN || 'https://nfv7up4usb.execute-api.ap-southeast-1.amazonaws.com/dev';

// ========== SERVICE SETUP ==========
const checkoutTicket = (data: any) => {
  // Hotfix
  // Refactor this
  const body = {
    ...data,
    ticketInfo: {
      ...(data?.ticketInfo || {}),
      gratuity: Number(data?.ticketInfo?.gratuity || 0),
    },
  };

  return axiosInstance
    .post(`${POS_HOST}/pos/checkout/v2`, body)
    .then((res: any) => {
      return {
        success: true,
        data: res,
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const checkoutTicketByQueue = (data: ICheckoutParams) => {
  const { tenantId }: any = getUserInfo();

  if (tenantId === '60dd7e52-3d03-4f84-a6a5-98dd8a7e2e6e') {
    cloverService.checkLog({ checkoutTicketByQueue: data });
  }
  const body = {
    ...data,
    ticketInfo: {
      ...(data?.ticketInfo || {}),
      gratuity: Number(data?.ticketInfo?.gratuity || 0),
    },
  };
  const { ticketInfo, payments } = body;
  let { timezone } = body;

  let createdDate: number | string = moment().valueOf().toString();

  const _createdDate = Number(createdDate);
  //validate request body
  if (!ticketInfo) {
    return toast.error(`ticketInfo field is missing`);
  }
  if (!ticketInfo.staffList || ticketInfo.staffList.length === 0) {
    return toast.error(`staffList field (in ticket) is missing or being empty`);
  }
  if (!ticketInfo.discountType) {
    return toast.error(`body.ticketInfo must include discountType`);
    // throw AppError.GeneralInvalidParameters(['body.ticketInfo must include discountType']);
  }
  if (!payments || payments.length === 0) {
    return toast.error(`payments field is missing or being empty`);
  }

  if (!tenantId) {
    return toast.error(`tenantId is missing`);
  }

  createdDate = moment(_createdDate).unix().toString();

  if (!timezone) {
    timezone = 'US/Central';
  }

  // ticketInfo.ticketId = ticketId;
  // ticketInfo.updatedDate = createdDate;
  // ticketInfo.timezone = timezone;

  const startDate = `${moment(Number(_createdDate)).startOf('day').unix()}`;
  const endDate = `${moment(Number(_createdDate)).endOf('day').unix()}`;

  // const requestBody = {
  //   tenantId,
  //   createdDate,
  //   timezone,
  //   ticketId,
  //   ticketInfo,
  //   payments,
  //   startDate,
  //   endDate,
  //   reasonLinkedExCC,
  //   actualCC,
  //   totalData,
  //   rewardAmount,
  //   visitDiscount,
  //   voucherDiscounts,
  // };

  const formattedParams = { ...formatCheckoutParams(data), tenantId, timezone, startDate, endDate };

  return checkoutTicketExcuter(formattedParams);
};

const checkoutTicketExcuter = (body: any) => {
  return axiosInstance
    .post(`${POS_HOST}/pos/checkout/queue/v2`, body)
    .then((res: any) => {
      return {
        success: true,
        data: res,
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const getListCheckin = () => {
  return axiosInstance
    .get(`${POS_HOST}/checkin`)
    .then((res: any) => {
      return {
        success: true,
        data: res?.data?.data,
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const completeCheckin = (data: any) => {
  return axiosInstance.post(`${POS_HOST}/checkin/complete`, { ...data });
};

const observeCheckoutError = (text: string, extraData: any) => {
  return Sentry.captureMessage(text, {
    extra: {
      ...extraData,
    },
  });
};

const getListTempTicket = (startDate: number, endDate: number) => {
  return axiosInstance
    .get(`${POS_HOST}/temp-ticket?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => {
      return {
        success: true,
        data: res?.data?.data,
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const getTempTicketDetail = (ticketId: string) => {
  return axiosInstance
    .get(`${POS_HOST}/temp-ticket/ticket-detail/${ticketId}`)
    .then((res: any) => {
      return {
        success: true,
        data: res?.data?.data,
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const getLatestIdTempTicket = (data: any) => {
  return axiosInstance.post(`${POS_HOST}/temp-ticket/latest-id`, { ...data });
};

const createTempTicket = (data: any) => {
  return axiosInstance.post(`${POS_HOST}/temp-ticket`, { ...data });
};

const createTempTicketByQueue = (data: any) => {
  console.time(`TICKET:${data.body._id}`);

  return axiosInstance.post(
    `${POS_HOST}/temp-ticket/queue`,
    { ...data },
    {
      params: {
        messageGroupId: data.storeId,
        messageDeduplicationId: uuidv4(),
      },
    },
  );
};

const updateTempTicket = (data: any) => {
  return axiosInstance.put(`${POS_HOST}/temp-ticket`, { ...data });
};

const completeTempTicket = (data: any) => {
  return axiosInstance.post(`${POS_HOST}/temp-ticket/complete`, { ...data });
};

const getTurnTracker = (startDate: string, endDate: string) => {
  return axiosInstance.get(`${POS_HOST}/turn-tracker?startDate=${startDate}&endDate=${endDate}`);
};

const updateTurnTracker = (ticketId: string, staffId: string, turnColor: string) => {
  return axiosInstance.put(`${POS_HOST}/turn-tracker`, { ticketId, staffId, turnColor });
};

const setScheduleSendMsg = (data: any) => {
  return axiosInstance.post(`${BASE_URL}/mail/v2/sns`, { ...data });
};

const updateGratuity = (data: any) => {
  return axiosInstance.put(`${POS_HOST}/pos/checkout/gratuity`, { ...data });
};

const checkoutService = {
  checkoutTicket,
  checkoutTicketByQueue,
  getListCheckin,
  completeCheckin,
  observeCheckoutError,

  getListTempTicket,
  getTempTicketDetail,
  getLatestIdTempTicket,
  createTempTicket,
  createTempTicketByQueue,
  updateTempTicket,
  completeTempTicket,
  getTurnTracker,
  updateTurnTracker,
  setScheduleSendMsg,
  updateGratuity,
};

export default checkoutService;
