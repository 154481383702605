import axios from 'axios';
import { CLOVER_DOMAIN, CLOUD_DOMAIN } from 'services/appDomain';
import axiosInstance from './axiosInstance';
const CLOVER_HOST = CLOVER_DOMAIN || 'https://239c-2405-4802-90ff-33b0-492c-48b9-9306-9abf.ap.ngrok.io';

// ========== SERVICE SETUP ==========

const refundPaymentOnClover = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/transactions/refund`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const showDisplayOrder = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/show-display-order`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const backToMainScreen = () => {
  return axios
    .get(`${CLOVER_HOST}/show-welcome-screen`)
    .then(() => {
      return {
        success: true,
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const initCloverSession = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/clover/init`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const disconnectCloverSession = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/clover/disconnect`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const checkCloverReady = (data: any) => {
  // if (window.location.href.includes('http://localhost:3002')) {
  //   return {
  //     success: false,
  //     // data: { ...error },
  //   };
  // }
  return axios
    .post(`${CLOVER_HOST}/clover/test`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printRefund = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/sales/print`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printAllPayroll = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/print/payroll`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printStaffPayroll = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/print/payroll-staff`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printTransaction = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/print/transaction`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printTicket = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/print/ticket`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printReport = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/print/total-report`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printIncome = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/print/income-report`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const createSale = (data: any, sourceAxios: any) => {
  return axios
    .post(
      `${CLOVER_HOST}/sales`,
      { ...data },
      {
        cancelToken: sourceAxios.token,
      },
    )
    .then((res) => {
      return {
        success: true,
        data: res,
      };
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return {
          success: false,
          isCancel: true,
          data: { ...error },
        };
      } else {
        return {
          success: false,
          isCancel: false,
          data: { ...error },
        };
      }
    });
};

const cancelSale = (data: any) => {
  return axios.post(`${CLOVER_HOST}/sales/cancel`, { ...data });
};

const openCashDrawer = () => {
  return axios
    .post(`${CLOVER_HOST}/cash-drawer`, {})
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

// cloud
const testConnectionCloud = (data: any) => {
  const { deviceId, posId, cloverToken } = data;
  return axiosInstance
    .post(
      `${CLOUD_DOMAIN}/test`,
      {},
      {
        headers: {
          'x-clover-device-id': deviceId,
          'x-pos-id': posId,
          'x-clover-access-token': cloverToken,
        },
      },
    )
    .then((res: any) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const createSaleCloud = (data: any, sourceAxios: any) => {
  const { deviceId, posId, cloverToken, id, amount, tippableAmount, tipSettings } = data;

  return axiosInstance
    .post(
      `${CLOUD_DOMAIN}/sale`,
      { id, amount, tippableAmount, tipSettings },
      {
        cancelToken: sourceAxios.token,
        headers: {
          'x-clover-device-id': deviceId,
          'x-pos-id': posId,
          'x-clover-access-token': cloverToken,
        },
      },
    )
    .then((res: any) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error: any) => {
      if (axios.isCancel(error)) {
        return {
          success: false,
          isCancel: true,
          data: { ...error },
        };
      } else {
        return {
          success: false,
          isCancel: false,
          data: { ...error },
        };
      }
    });
};

const refundCloud = (data: any) => {
  const { deviceId, posId, cloverToken, paymentId, fullRefund } = data;
  return axiosInstance
    .post(
      `${CLOUD_DOMAIN}/refund`,
      { paymentId, fullRefund },
      {
        headers: {
          'x-clover-device-id': deviceId,
          'x-pos-id': posId,
          'x-clover-access-token': cloverToken,
        },
      },
    )
    .then((res: any) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error: any) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const cancelSaleCloud = (data: any) => {
  const { deviceId, posId, cloverToken } = data;
  return axiosInstance.post(
    `${CLOUD_DOMAIN}/cancel`,
    {},
    {
      headers: {
        'x-clover-device-id': deviceId,
        'x-pos-id': posId,
        'x-clover-access-token': cloverToken,
      },
    },
  );
};

const addTransactionTip = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/transactions/add-tip`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const checkLog = (data: any) => {
  return axiosInstance.post(`${CLOUD_DOMAIN}/log`, { ...data });
};

const getListCloverTransaction = (data: any) => {
  const { deviceId, posId, cloverToken, merchantId, search, startDate, endDate, isSaleCloverCredit } = data;

  return axiosInstance.post(
    `${CLOUD_DOMAIN}/transaction/list`,
    {
      merchantId,
      search,
      startDate,
      endDate,
      isSaleCloverCredit,
    },
    {
      headers: {
        'x-clover-device-id': deviceId,
        'x-pos-id': posId,
        'x-clover-access-token': cloverToken,
      },
    },
  );
};

// const getCloverTransactionTicketId = (data: any) => {
//   const { cloverToken, merchantId, tenantId, startDate, endDate, ticketId } = data;

//   return axiosInstance.post(
//     `${CLOUD_DOMAIN}/transaction/ticket`,
//     {
//       merchantId,
//       tenantId,
//       startDate,
//       endDate,
//       ticketId,
//     },
//     {
//       headers: {
//         'x-clover-device-id': '',
//         'x-pos-id': '',
//         'x-clover-access-token': cloverToken,
//       },
//     },
//   );
// };

// Pax
const connectPaxDevice = (ip: string) => {
  return axios
    .post(`${CLOVER_HOST}/pax/connect?ip=${ip}`)
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const createSalePax = (data: any) => {
  return axios
    .post(`${CLOVER_HOST}/pax/payment`, { ...data })
    .then((res) => {
      return {
        success: true,
        data: res,
      };
    })
    .catch((error) => {
      return {
        success: false,
        isCancel: false,
        data: { ...error },
      };
    });
};

const printPdf = (data: any) => {
  const formData = new FormData();
  formData.append('file', data, 'receipt.pdf');

  return axios
    .post(`${CLOVER_HOST}/print/v2`, formData)
    .then((res) => {
      return {
        success: true,
        data: { ...res },
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: { ...error },
      };
    });
};

const printHtml = async (data: string) => {
  if (data === '') return { success: false };
  try {
    const res = await axios.post(`${CLOVER_HOST}/print/v2/print-html`, { htmlContent: data });
    return {
      success: true,
      data: { ...res },
    };
  } catch (error: any) {
    return {
      success: false,
      data: { ...error },
    };
  }
};

const getPrinterDevices = async () => {
  try {
    const res = await axios.get(`${CLOVER_HOST}/print/v2/get-prints`);
    return {
      success: true,
      data: { ...res },
    };
  } catch (error: any) {
    return {
      success: false,
      data: { ...error },
    };
  }
};

const setPrinter = async (data: any) => {
  try {
    const res = await axios.post(`${CLOVER_HOST}/print/v2/set-print-name`, { name: data }, { headers: { 'Content-Type': 'application/json' } });
    return {
      success: true,
      data: { ...res },
    };
  } catch (error: any) {
    return {
      success: false,
      data: { ...error },
    };
  }
};

const getStatusOfCashDrawer = async () => {
  try {
    const res = await axios.get(`${CLOVER_HOST}/status-open-cash-drawer`);
    return {
      success: true,
      data: { ...res },
    };
  } catch (error: any) {
    return {
      success: false,
      data: { ...error },
    };
  }
};

const setCashDrawer = async (data: any) => {
  try {
    const res = await axios.post(`${CLOVER_HOST}/set-cash-drawer`, { isOpen: data }, { headers: { 'Content-Type': 'application/json' } });
    return {
      success: true,
      data: { ...res },
    };
  } catch (error: any) {
    return {
      success: false,
      data: { ...error },
    };
  }
};

const openCashDrawerPrinter = async (data: { comPort: string | null; printerName: string }) => {
  try {
    const res = await axios.post(
      `${CLOVER_HOST}/open-cash-drawer-printer`,
      { comPort: data.comPort, printerName: data.printerName },
      { headers: { 'Content-Type': 'application/json' } },
    );
    return {
      success: true,
      data: { ...res },
    };
  } catch (error: any) {
    return {
      success: false,
      data: { ...error },
    };
  }
};

const cloverService = {
  refundPaymentOnClover,
  initCloverSession,
  disconnectCloverSession,
  checkCloverReady,
  openCashDrawer,
  createSale,
  cancelSale,

  printRefund, // dang nam o checkout version cu
  printAllPayroll, // done
  printStaffPayroll, // done
  printTransaction, // done
  printTicket, // done
  printReport, // done
  printIncome, // done
  printPdf,
  printHtml,

  backToMainScreen,
  showDisplayOrder,
  testConnectionCloud,
  createSaleCloud,
  refundCloud,
  cancelSaleCloud,
  addTransactionTip,
  getListCloverTransaction,

  connectPaxDevice,
  createSalePax,

  getPrinterDevices,
  setPrinter,
  getStatusOfCashDrawer,
  setCashDrawer,
  openCashDrawerPrinter,
  checkLog,
};

export default cloverService;
