import qs from 'qs';
import axiosInstance from './axiosInstance';
import { BASE_URL, CATALOG_DOMAIN, STAFF_DOMAIN, CUSTOMER_DOMAIN, TAG_DOMAIN, TENANT_DOMAIN, POS_DOMAIN, STAFF_SERVICE_DOMAIN } from 'services/appDomain';
import axios from 'axios';

const CATALOG_HOST = CATALOG_DOMAIN || 'https://xutv4ec32l.execute-api.ap-southeast-1.amazonaws.com/dev';

const STAFF_HOST = STAFF_DOMAIN || 'https://4i98pacai4.execute-api.ap-southeast-1.amazonaws.com/dev';

const CUSTOMER_HOST = CUSTOMER_DOMAIN || 'https://4i98pacai4.execute-api.ap-southeast-1.amazonaws.com/dev';

const TAG_HOST = TAG_DOMAIN || 'https://4i98pacai4.execute-api.ap-southeast-1.amazonaws.com/dev';

const BUSINESS_HOST = TENANT_DOMAIN || 'https://yiwrcb0o4g.execute-api.ap-southeast-1.amazonaws.com/dev';

const TENANT_HOST = TENANT_DOMAIN || 'https://yiwrcb0o4g.execute-api.ap-southeast-1.amazonaws.com/dev';

interface products {
  PK: string;
  SK: string;
  catName: string;
  productName: string;
  productId: string;
  data: string;
}
interface categories {
  PK: string;
  SK: string;
  catId: string;
  catName: string;
  data: string;
  products: Array<products>;
}

interface service {
  staff: {
    PK: string;
    SK: string;
  };
  categories: Array<categories>;
}
// const BASE_URL = BASE_URL;

// ========== SERVICE SETUP ==========
const getAllProducts = () => {
  return axiosInstance.get(`${CATALOG_HOST}/catalog/services`);
};
const createProduct = (data: any) => {
  return axiosInstance.post(`${CATALOG_HOST}/catalog/services`, { ...data });
};

const updateProduct = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/catalog/services`, { ...data });
};

const updateProductOrder = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/services`, [...data]);
};

const deleteProduct = (id: any) => {
  return axiosInstance.delete(`${CATALOG_HOST}/catalog/services`, {
    data: {
      productId: id,
    },
  });
};

const createCategory = (data: any) => {
  return axiosInstance.post(`${CATALOG_HOST}/catalog/categories`, { ...data });
};

const updateCategory = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/catalog/categories`, { ...data });
};

const updateCategoryOrder = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/categories`, [...data]);
};

const deleteCategory = (id: any) => {
  return axiosInstance.delete(`${CATALOG_HOST}/catalog/categories`, {
    data: {
      catId: id,
    },
  });
};
// ========== END OF SERVICE SETUP ==========

// ========== STAFF MANAGEMENT ==========
const getAllStaff = (status?: boolean) => {
  // undefined to get all staffs
  // true to get deleted staffs
  // false to get available staffs
  return status !== undefined ? axiosInstance.get(`${STAFF_HOST}/staffs?status=${status}`) : axiosInstance.get(`${STAFF_HOST}/staffs`);
};

const getAllStaffWithWorkingHours = (startDate: string, endDate: string, allStaff?: boolean) => {
  const getAllStaffParam = allStaff ? `&allStaff=${allStaff}` : '';

  return axiosInstance.get(`${STAFF_HOST}/staffs/working-hours?startDate=${startDate}&endDate=${endDate}${getAllStaffParam}`);
};

const getStaffDetails = (data: any) => {
  return axiosInstance.post(`${STAFF_HOST}/staffs/info`, { ...data });
};

const getStaffSchedule = (staffId: string, startWeek: number, endWeeek: number) => {
  return axiosInstance.get(`${STAFF_HOST}/staffs/schedule?staffId=${staffId}&startWeek=${startWeek}&endWeek=${endWeeek}`);
};

const createStaff = (data: any) => {
  return axiosInstance.post(`${STAFF_HOST}/staffs`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const updateStaff = (data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs/info`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const updateStaffCommission = (data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs/commission`, { ...data });
};

const updateStaffOrder = (data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs`, { staffOrder: [...data] });
};

const updateStaffSchedule = (staffId: string, data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs/${staffId}/schedule`, { ...data });
};

const deleteStaff = (data: any) => {
  const staffId = data.SK.split('#')[1].concat('%23', data.SK.split('#')[2]);
  return axiosInstance.delete(`${STAFF_HOST}/staffs/${staffId}`);
};

const deleteStaffPermanently = (data: any) => {
  return axiosInstance.delete(`${STAFF_HOST}/staffs/info/removePermanently`, { data: data });
};

const recoverStaff = (data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs/info/recover`, { ...data });
};

const getRoleAndPermission = () => {
  return axiosInstance.get(`${BASE_URL}/roles/permission`);
};

const updateRoleAndPermission = (data: any) => {
  return axiosInstance.put(`${BASE_URL}/roles/permission`, { ...data });
};

const loginPasscode = (data: any) => {
  return axiosInstance
    .post(`${BASE_URL}/roles/login-passcode`, { ...data })
    .then((res: any) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error: any) => {
      const messageKey = error?.response?.data?.messageKey;

      let message = '';
      switch (messageKey) {
        case 'Forbidden':
          message = 'You don’t have enough perrmission.';
          break;
        case 'WrongPasscode':
          message = 'Passcode is incorrect';
          break;

        default:
          message = '';
          break;
      }

      return {
        success: false,
        data: {
          messageKey,
          message,
        },
      };
    });
};
// ========== END OF STAFF MANAGEMENT ==========

// ========== CUSTOMER MANAGEMENT ==========
const getAllCustomer = () => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers`);
};

const getLimitCustomer = (params: any) => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/list`, { params: { ...params } });
};

const getCustomerDetails = (data: any) => {
  const customerId = data.SK.split('#')[1].concat('%23', data.SK.split('#')[2]);
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/customer-details/${customerId}`);
};

const createCustomer = (data: any) => {
  return axiosInstance.post(`${CUSTOMER_HOST}/customers`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const importCustomers = (data: any) => {
  return axiosInstance.post(`${CUSTOMER_HOST}/customers/import`, { data: [...data] });
};

const updateCustomer = (data: any) => {
  return axiosInstance.put(`${CUSTOMER_HOST}/customers/info`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const deleteCustomer = (data: any) => {
  const customerId = data.SK.split('#')[1].concat('%23', data.SK.split('#')[2]);
  return axiosInstance.delete(`${CUSTOMER_HOST}/customers/${customerId}/remove`, {
    ...data,
  });
};

const getCustomerHistory = (customerId: any) => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/history/${customerId}`);
};

const getCustomerPastServices = (customerId: any) => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/info/past-services/${customerId}`);
};

const getCustomerPointHistory = (customerId: any) => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/point-history/${customerId}`);
};

// const getCustomerGiftCards = (customerId: any) => {
//   return axiosInstance.get(`${CUSTOMER_HOST}/customers/gift-card/list/${customerId}`);
// };

const getCustomerGiftCards = (customerId: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/gift-card/customer/${customerId}`);
};

const getGiftCardById = (giftCardId: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/gift-card/${giftCardId}`);
};

const getGiftCardHistory = (giftCardId: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/gift-card/history/${giftCardId}`);
};

const updateGiftCard = (data: any) => {
  return axiosInstance.put(`${POS_DOMAIN}/gift-card`, data);
};

const createGiftVoucher = (data: any) => {
  return axiosInstance.post(`${CUSTOMER_HOST}/customers/gift-voucher`, { ...data });
};

// ========== END OF CUSTOMER MANAGEMENT ==========

// ========== TAG MANAGEMENT ==========

const getAllTag = () => {
  return axiosInstance.get(`${TAG_HOST}/tags`);
};

const getAllAvailableTag = () => {
  return axiosInstance.get(`${TAG_HOST}/tags/campaigns`);
};

const createTag = (data: any) => {
  return axiosInstance.post(`${TAG_HOST}/tags`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const updateTag = (data: any) => {
  return axiosInstance.put(`${TAG_HOST}/tags`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const deleteTag = (data: any) => {
  const tagId = data.SK.split('#')[1].concat('%23', data.SK.split('#')[2]);
  return axiosInstance.delete(`${TAG_HOST}/tags/remove/${tagId}`).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const assignCustomerTag = (data: any) => {
  return axiosInstance.put(`${CUSTOMER_HOST}/customers/tags`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};
// ========== END OF TAG MANAGEMENT ==========

// ========== PAYMENT SETTING ==========
const getPaymentInfo = () => {
  const url = `${TENANT_HOST}/tenants/payment-method`;
  return axiosInstance.get(url);
};

const updatePaymentInfo = (data: any) => {
  const url = `${TENANT_HOST}/tenants/payment-method`;
  return axiosInstance.put(url, { ...data });
};
// ========== END OF PAYMENT SETTING ==========

// ========== BUSINESS INFO ==========
const getBusinessInfo = () => {
  const url = `${BUSINESS_HOST}/tenants/business-info/`;
  return axiosInstance.get(url);
};

const updateBusinessInfo = (data: any) => {
  return axiosInstance.put(`${BUSINESS_HOST}/tenants/business-info`, { ...data });
};
// ========== END OF BUSINESS INFO ==========

// ========== CLOVER SETTING ==========
const getCloverSettings = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/clover`);
};

const updateCloverSettings = (data: any) => {
  return axiosInstance.put(`${TENANT_HOST}/tenants/clover`, { ...data });
};
// ========== END OF CLOVER SETTING ==========

// ========== CHECKOUT SETTING ==========
const getCheckoutSettings = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/checkout-settings`);
};

const updateCheckoutSettings = (data: any) => {
  return axiosInstance.put(`${TENANT_HOST}/tenants/checkout-settings`, { ...data });
};

// ========== CHECKIN SETTING ==========
const getCheckinSettings = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/checkin-settings`);
};

const updateCheckinSettings = (data: any) => {
  return axiosInstance.put(`${TENANT_HOST}/tenants/checkin-settings`, { ...data });
};
// ========== END OF CHECKOUT SETTING ==========

// ========== GIFTCARD SETTING ==========
const getAllGiftCard = () => {
  return axiosInstance.get(`${CATALOG_HOST}/catalog/giftcards`);
};
const createGiftCard = (data: any) => {
  return axiosInstance.post(`${CATALOG_HOST}/catalog/giftcards`, { ...data });
};

const updateGiftCardOld = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/catalog/giftcards`, { ...data });
};

const deleteGiftCard = (data: any) => {
  return axiosInstance.delete(`${CATALOG_HOST}/catalog/giftcards`, {
    data,
  });
};

const getListGiftCard = (data: any) => {
  return axiosInstance.post(`${POS_DOMAIN}/gift-card/list`, { ...data });
};

// ========== END OF GIFTCARD SETTING ==========

const getIncomeReport = (data: any) => {
  return axiosInstance.post(`${BASE_URL}/report/income`, { ...data });
};

const getFees = (params: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/fees`, { params: { ...params } });
};

const getStaffServices = (staffId: string) => {
  return axiosInstance.get(`${STAFF_SERVICE_DOMAIN}/staffs/${staffId}/services`);
};

const updateStaffServices = (data: service) => {
  return axiosInstance.put(`${BASE_URL}/staff-services/staffs/services`, { ...data });
};

// Working hours
const getStaffInfoBeforeChecking = (code: string, startDate: string, endDate: string) => {
  return axiosInstance
    .get(`${STAFF_HOST}/passcode?code=${code}&startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error: any) => {
      const messageKey = error?.response?.data?.messageKey;
      let message = '';
      switch (messageKey) {
        case 'Forbidden':
          message = 'You don’t have enough perrmission.';
          break;
        case 'WrongPasscode':
          message = 'Passcode is incorrect';
          break;

        default:
          message = '';
          break;
      }
      return {
        success: false,
        data: {
          messageKey,
          message,
        },
      };
    });
};

const getStaffInfoByPassCode = (code: string) => {
  return axiosInstance
    .get(`${STAFF_HOST}/passcode?code=${code}`)
    .then((res: any) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error: any) => {
      const messageKey = error?.response?.data?.messageKey;

      let message = '';
      switch (messageKey) {
        case 'Forbidden':
          message = 'You don’t have enough perrmission.';
          break;
        case 'WrongPasscode':
          message = 'Passcode is incorrect';
          break;

        default:
          message = '';
          break;
      }

      return {
        success: false,
        data: {
          messageKey,
          message,
        },
      };
    });
};

const staffCheckIn = (data: { staffId: string }) => {
  return axiosInstance.post(`${BASE_URL}/working-hours/check-in`, { ...data });
};

const staffCheckOut = (data: { PK: string; SK: string }) => {
  return axiosInstance.put(`${BASE_URL}/working-hours/check-out`, { ...data });
};

const updateStaffTurnAmount = (data: { PK: string; SK: string; staffId: string; actualTurnAmount: number; updatedTurnAmount: number }) => {
  return axiosInstance.put(`${BASE_URL}/working-hours/turn`, { ...data });
};

const updateStaffCheckingTime = (data: { PK: string; SK: string; checkInAt: string; checkOutAt: string }) => {
  return axiosInstance.put(`${BASE_URL}/working-hours`, { ...data });
};

const getGoogleReviewAuthUrl = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/google-review/auth-url`);
};

const getGoogleReviewAuthTokens = (code: string) => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/google-review/auth-tokens?code=${code}`);
};

const getGoogleReview = (pageToken: string) => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/google-review${pageToken ? `?pageToken=${pageToken}` : ''}`);
};

const replyGoogleReview = (data: { reviewId: string; replyText: string }) => {
  return axiosInstance.put(`${TENANT_HOST}/tenants/google-review/reply`, { ...data });
};

const deleteReplyGoogleReview = (reviewId: string) => {
  return axiosInstance.delete(`${TENANT_HOST}/tenants/google-review/reply/${reviewId}`);
};

const getInternalReview = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/review/internal`);
};

const getStaffAvatarSignedUrl = (data: { fileType: string; filePath: string }) => {
  return axiosInstance.post(`${BASE_URL}/auth/s3/signed-url`, { ...data });
};

const replyInternalReview = (data: any) => {
  return axiosInstance.post(`${TENANT_HOST}/tenants/review/reply`, { ...data });
};

const sendInternalReplyReview = (data: any) => {
  return axiosInstance.post(`${BASE_URL}/mail/v2/reply`, { ...data });
};

const businessService = {
  getAllProducts,
  createProduct,
  updateProduct,
  updateProductOrder,
  deleteProduct,

  createCategory,
  updateCategory,
  updateCategoryOrder,
  deleteCategory,

  getAllStaff,
  getAllStaffWithWorkingHours,
  getStaffDetails,
  getStaffSchedule,
  createStaff,
  updateStaff,
  deleteStaff,
  deleteStaffPermanently,
  recoverStaff,
  updateStaffCommission,
  updateStaffOrder,
  updateStaffSchedule,
  getRoleAndPermission,
  updateRoleAndPermission,
  loginPasscode,

  getAllCustomer,
  getLimitCustomer,
  getCustomerDetails,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerHistory,
  getCustomerPastServices,
  getCustomerPointHistory,
  getCustomerGiftCards,
  getGiftCardById,
  getGiftCardHistory,
  updateGiftCard,
  createGiftVoucher,
  importCustomers,

  getAllTag,
  getAllAvailableTag,
  createTag,
  updateTag,
  deleteTag,
  assignCustomerTag,

  getPaymentInfo,
  updatePaymentInfo,

  getBusinessInfo,
  updateBusinessInfo,

  getCloverSettings,
  updateCloverSettings,

  getCheckoutSettings,
  updateCheckoutSettings,

  getCheckinSettings,
  updateCheckinSettings,

  getAllGiftCard,
  createGiftCard,
  updateGiftCardOld,
  deleteGiftCard,
  getListGiftCard,

  getIncomeReport,
  getFees,
  getStaffServices,
  updateStaffServices,
  getStaffInfoBeforeChecking,
  staffCheckIn,
  staffCheckOut,
  getStaffInfoByPassCode,
  updateStaffTurnAmount,
  updateStaffCheckingTime,
  getGoogleReviewAuthUrl,
  getGoogleReviewAuthTokens,
  getInternalReview,
  getStaffAvatarSignedUrl,

  replyInternalReview,
  sendInternalReplyReview,
  getGoogleReview,
  replyGoogleReview,
  deleteReplyGoogleReview,
};

export default businessService;
