import PropTypes from 'prop-types';
import { memo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
// @mui
import { Stack } from '@mui/material';

import { WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import { NavListRoot } from './NavList';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { MODIFY_STATUS } = WS_ACTION_TYPE;

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

type NavSectionHorizontalProps = {
  navConfig: Array<any>;
};

function NavSectionHorizontal({ navConfig }: NavSectionHorizontalProps) {
  const { sendJsonMessage } = useAppSelector((state: RootState) => state.global);
  const { currentTicket } = useAppSelector((state: RootState) => state.checkout);
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
      onClick={() => {
        if (currentTicket?._id) {
          const updateSocketParams = {
            identify: 'updateTicketLockingStatus-5',
            eventName: MODIFY_STATUS,
            data: {
              tenantId: currentTicket.tenantId,
              _id: currentTicket._id,
              ticketId: currentTicket.ticketId,
              activeProfile: '',
            },
          };
          sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
        }
      }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list: any) => (
              <NavListRoot key={list.title} list={list} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
