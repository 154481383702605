import { Box, TextField, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    flex: '1',
    // width: '100%',
    minHeight: '56px',
    paddingRight: '12px',
  },
  buttonPrimary: {
    borderRadius: '8px',
    height: 36,
    width: 120,
    minWidth: 120,
    background: '#D8E9FB4D',
    fontSize: '16px',
    fontWeight: 300,
    border: '1px solid #0B70E1',
    color: '#0B70E1',
    opacity: 0,
    visibility: 'hidden',
  },
  btnActive: {
    opacity: 1,
    visibility: 'visible',
  },
  datePicker: {},

  gridFlex: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
    width: '100%',
  },
  boxFlex: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },

  boxGrid: {
    display: 'grid',
    rowGap: 6,
    gridTemplateColumns: 'repeat(13,1fr)',
    [theme.breakpoints.only('xl')]: {
      gridTemplateColumns: 'repeat(12,1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(10,1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(8,1fr)',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(4,1fr)',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(4,1fr)',
    },
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: 'repeat(3,1fr)',
    },
  },
  boxItem: {
    minHeight: 56,
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
  },

  iconPassword: {
    position: 'absolute',
    top: '50%',
    right: '0%',
    transform: 'translateY(-50%)',
  },
  numpadItem: {
    fontFamily: 'monospace',
    padding: '2.5rem',
    lineHeight: '1rem',
    fontSize: '27px',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '50%',
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    cursor: 'pointer',
    color: 'rgba(14, 115, 228, 1)',
    border: '1px solid rgba(14, 115, 228, 1)',
    margin: '0 auto',
    '& img': {
      height: '16px',
      width: '16px',
    },
    '@media screen and (max-width: 1440px)': {
      padding: '1.8rem',
    },
  },
  numpadDel: {
    border: '1px solid rgba(242, 124, 30, 1)',
    '&:hover': {
      backgroundColor: 'rgba(221, 76, 35, 0.08)',
    },
  },
  numpadOK: {
    border: '1px solid rgba(76, 175, 80, 1)',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.08)',
    },
  },
  input: {
    fontSize: '32px',
    lineHeight: '24px',
    width: '20px',
    fontWeight: 400,
    color: 'rgba(11, 112, 225, 1)',
    textAlign: 'center',
    padding: 0,
    border: 'none',
    outline: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input': {
      padding: 0,
      fontWeight: 700,
      color: 'rgba(11, 112, 225, 1)',
      textAlign: 'center',
      borderBottom: '2px solid rgba(11, 112, 225, 1)',
      '&::placeholder': {
        fontFamily: 'Livvic !important',
      },
    },
  },
  iconAction: {
    width: '2rem',
    height: '2rem',
    cursor: 'pointer',
    padding: '0.25rem',
    maxWidth: 'unset',
    '&:hover': {
      transform: 'scale(1.3)',
      transition: 'all 0.3s ease',
    },
  },
}));

const PasscodeWrapper = styled(Box)<any>(({ bgColor }: any) => ({
  width: '100%',
  padding: '16px',
  background: bgColor || '#FFFFFF',
  boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.16)',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  flex: '1 1 100%',
}));

const VerificationInput = styled(TextField)(({ type }: any) => ({
  border: 'none',
  fontSize: '32px',
  lineHeight: '24px',
  width: '20px',
  fontWeight: 400,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& input': {
    fontFamily: type === 'password' && 'Password ',
    fontWeight: 700,
    '&::placeholder': {
      fontFamily: 'Livvic !important',
    },
  },
  input: { color: 'rgba(11, 112, 225, 1)', textAlign: 'center ', padding: 0, borderBottom: '2px solid rgba(11, 112, 225, 1)' },
}));

export { PasscodeWrapper, VerificationInput, useStyles };
