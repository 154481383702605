import useNetwork from 'hooks/useNetwork';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchListTempTicket, updateBaseTicketDate, updateManageTicketItem, updateManageTicketItemId, updateManageTicketStatus } from 'redux/slices/checkout';
import { updateIsOpenUpdateVersionDialog, updateLastJsonMessage, updateSendJsonMessage } from 'redux/slices/global';
import { RootState } from 'redux/store';
import authService from 'services/auth.service';
import { getUserInfo, handleLogout, updateUserInfo } from 'services/axiosInstance';
import WebsocketService from 'services/websocket.service';
import { LIST_ENV_ALLOW_LOCKING_TICKET, WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { INSERT, MODIFY, MODIFY_STATUS, REMOVE } = WS_ACTION_TYPE;

const useWebsocketConnection = () => {
  const isOnline = useNetwork();
  const dispatch: any = useAppDispatch();

  //Public API that will echo messages sent to it back to the client
  const { profileId } = useAppSelector((state: RootState) => state.global);
  const { currentTicket } = useAppSelector((state: RootState) => state.checkout);
  const [socketUrl] = useState(WebsocketService.getPosWebsocketConnectionUrl());
  const [hash, setHash] = useState(uuidv4());

  const { tenantId: storeId, accessToken: authorizer }: any = getUserInfo() || {};
  const { sendJsonMessage, lastJsonMessage, readyState }: any = useWebSocket(socketUrl, {
    onOpen: async () => {
      console.log('===: onOpen');
      dispatch(updateSendJsonMessage(sendJsonMessage));
      if (currentTicket) {
        updateSocketTempTicket({ sendJsonMessage, currentTicket, profileId });
      }
      // await sleep(1000);
      // connection.current.close();
      // handleFetchListTempTicket();
      // dispatch(fetchListTempTicket());
    },
    onClose: () => {
      console.log('===: onClose');
    },
    onError: async (e) => {
      console.error('===: onError', e);
      try {
        const userInfo = getUserInfo();
        const { refreshToken } = userInfo;
        const res: any = await authService.refreshToken({ refreshToken });
        if (!res?.data?.success) {
          handleLogout();
        }

        updateUserInfo({
          accessToken: res?.data?.data,
        });
      } catch (err: any) {
        handleLogout();
      }
    },
    shouldReconnect: () => {
      console.log('===: shouldReconnect');
      return true;
    },
    // reconnectAttempts: 10,
    reconnectInterval: 1000,
    onReconnectStop: () => {
      console.log('===: onReconnectStop');
      setHash(uuidv4());
    },
    share: true,
    retryOnError: true,
    queryParams: {
      authorizer,
      storeId,
      profileId,
      hash,
    },
  });

  const updateSocketTempTicket: any = useCallback(
    debounce(async ({ sendJsonMessage, ticketItem, profileId }: any) => {
      if (ticketItem?._id) {
        // if (isTicketHasService(ticketItem)) {
        delete ticketItem.createdAt;
        delete ticketItem.updatedAt;

        const updateSocketParams = {
          identify: 'updateTicketLockingStatus-1',
          eventName: MODIFY_STATUS,
          data: {
            tenantId: ticketItem.tenantId,
            _id: ticketItem._id,
            ticketId: ticketItem.ticketId,
            activeProfile: '',
          },
        };

        if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
          updateSocketParams.data.activeProfile = profileId;
        }

        sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
        // }
      }
    }, 200),
    [],
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      dispatch(updateLastJsonMessage(lastJsonMessage));

      console.log('***: receive ws message', lastJsonMessage.eventName);
      console.timeEnd(`TICKET:${lastJsonMessage?.data?._id}`);
      if (lastJsonMessage.actionType === 'TEMP_TICKET') {
        switch (lastJsonMessage.eventName) {
          case INSERT:
            dispatch(updateManageTicketItemId(lastJsonMessage.data));
            break;
          case MODIFY:
            dispatch(updateManageTicketItem(lastJsonMessage.data));
            break;
          case MODIFY_STATUS:
            if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
              dispatch(updateManageTicketStatus(lastJsonMessage.data));
            }
            break;
          default:
            break;
        }
      } else if (lastJsonMessage.actionType === 'NEW_DEPLOYMENT') {
        dispatch(updateIsOpenUpdateVersionDialog(true));
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (isOnline) {
      setHash(uuidv4());
    }
  }, [isOnline]);

  useEffect(() => {
    const connectionStatus: any = {
      '0': 'Connecting',
      '1': 'Open',
      '2': 'Closing',
      '3': 'Closed',
      '4': 'Uninstantiated',
    };
    dispatch(updateBaseTicketDate(new Date()));
    console.log('WS connection status:', connectionStatus[readyState]);
  }, [readyState]);

  const onVisibilitychange = () => {
    if (document.visibilityState === 'visible') {
      setHash(uuidv4());
      dispatch(fetchListTempTicket());
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilitychange);

    return () => {
      window.removeEventListener('visibilitychange', onVisibilitychange);
    };
  }, [readyState]);

  return null;
};

export default useWebsocketConnection;
