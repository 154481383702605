import { jsPDF } from 'jspdf';
import { fNumberFixed3 } from './formatNumber';
import moment from 'moment';
import { UPLOAD_IMAGE_PARAMS } from './constants';

export function includeString(str1: string, str2: string) {
  return str1.toUpperCase().includes(str2.toUpperCase());
}

export function getValueByPercentageOfTotal(total = 0, percentage = 0) {
  return fNumberFixed3((Number(percentage) / 100) * Number(total), 2);
}

export function getPriceWithCashDiscount(price = 0, percentage = 0) {
  return fNumberFixed3(Number(price) + getValueByPercentageOfTotal(price, percentage), 2);
}

export function formatNumberPrice(number: any, minimumFractionDigits?: number) {
  const instance = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: minimumFractionDigits || 2 });
  if (isNaN(Number(number))) return '0.00';
  const _number = instance.format(number);

  return _number;
}

export function upperCaseFirstLetter(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getGiftCardId(value: string) {
  // Ẽample: %B6010561081923456^27/WORDMARK/VN22^25010004000060205984.          ?;6010561081923404=25010004000060205984?
  if (value.includes('%B') && value.includes('?;')) {
    return value?.split(';')[1].split('=')[0];
  }

  // Example: ;20220501?'
  if (value[0] === ';' && value[value.length - 1] === '?') {
    return value.slice(1, value.length - 1);
  }

  return value;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const retry = async (fn: Function, maxAttempts: number, interval: number) => {
  let attempts = 0;
  while (attempts < maxAttempts) {
    try {
      await fn();
      return;
    } catch (error) {
      attempts++;

      if (attempts === maxAttempts) {
        throw error;
      }
      // wait for interval before retry
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      setTimeout(() => {
        retry(fn, maxAttempts - attempts, interval);
      }, interval);
    }
  }
};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const createPDF = async (image: any) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const scaleFactor = 203 / img.width;
      const width = 203; //203pt ~ 270px
      const height = scaleFactor * img.height;

      // Desired number of pages
      const desiredNumberOfPages = Math.ceil(height / 800); // modify the denominator value to suit your needs

      // Calculate fixed page height
      let fixedPageHeight = height / desiredNumberOfPages;

      // Adjust for small heights
      if (height < width) {
        fixedPageHeight = width + 0.1;
      }

      const doc = new jsPDF({ orientation: 'portrait', unit: 'pt', format: [width, fixedPageHeight] });

      let y = 0;
      while (y < height) {
        doc.addImage(image, 'png', 0, -y, width, height);
        y += fixedPageHeight;
        // If there is more content to add, add a new page
        // Adding an extra condition here to avoid an empty last page
        if (y < height && height - y > 1) {
          doc.addPage([width, fixedPageHeight], 'portrait');
        }
      }
      resolve(doc.output('blob'));
    };
    img.onerror = reject;
    img.src = image;
  });
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function numberToPhone(number: string) {
  if (number) {
    return number.replace(/\D+/g, '').replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '($1) $2-$3'); //mask numbers (xxx) xxx-xxxx
  } else {
    return '';
  }
}

export const formatNumber = (num: number, size: number) => {
  let number = num.toString();
  while (number.length < size) number = '0' + number;

  return number;
};

// Format number with leading zeros
export function pad(num: number, size: number) {
  const s = '000000000' + num;
  return s.substr(s.length - size);
}

export function calculateTimeDiff(start: number, end: number) {
  const startTime = moment.unix(start);
  const endTime = moment.unix(end);
  // calculate total duration
  const duration = moment.duration(endTime.diff(startTime));
  // duration in hours
  const hours = parseInt(duration.asHours().toString());
  // duration in minutes
  const minutes = parseInt(duration.asMinutes().toString()) % 60;

  return { hours, minutes };
}

export const genTextWarningExternal = (actualCC: number, currentCC: number) => {
  if (actualCC === currentCC) {
    return '';
  }
  const diffCC = currentCC - actualCC;

  const diffCCFormated = diffCC < 0 ? (diffCC * -1).toFixed(2) : diffCC.toFixed(2);
  const noteExternalCredit = `External CC is ${diffCC < 0 ? 'lower' : 'higher'} than actual CC amount: $${diffCCFormated}`;

  return noteExternalCredit;
};

export function urlify(text: string) {
  const urlRegex = /([\s]|^)(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url: string) {
    return '<a href="' + url + '">' + url + '</a>';
  });
}

export function getValidationErrors(err: any) {
  const validationErrors: any = {};
  err.inner &&
    err.inner.forEach((error: { path: string; message: string }) => {
      if (error.path) {
        if (!(error.path in validationErrors)) validationErrors[error.path] = error.message;
      }
    });

  return validationErrors;
}

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const getCroppedImg = async (imageSrc: string, crop: { x: number; y: number; width: number; height: number }) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = UPLOAD_IMAGE_PARAMS.WIDTH;
  canvas.height = UPLOAD_IMAGE_PARAMS.HEIGHT;
  ctx && ctx.drawImage(image as CanvasImageSource, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png');
  });
};

export const getURLS3 = (prefix: string, imgId: string) => {
  if (imgId === '') return '';
  return `https://rich-pos-files-${process.env.REACT_APP_ENV?.trim().toLowerCase() || 'dev'}.s3.amazonaws.com/${prefix}/${imgId}`;
};

export const convertNumber = (number: string) => {
  // Remove commas
  const numberWithNoCommas = number.replace(/,/g, '');
  // Convert the result back to a number (if needed)
  const result = parseFloat(numberWithNoCommas);

  return isNaN(result) ? null : result;
};

export const formatPhone = (businessPhone: string) => {
  const number = businessPhone.replace(/\D/g, '');

  return numberToPhone(number);
};

export function roundToFixed(value: number, decimals = 2): number {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}

export function formatPriceInputValue(currentValue: string, inputValue: string | number) {
  const temp = parseInt(`${currentValue || 0}`.replace(/\D/g, ''));
  const finalValue = inputValue === 'Delete' ? Number(`${temp}`.slice(0, -1)) : Number(`${temp}${inputValue}`);
  return (finalValue / 100).toFixed(2);
}
