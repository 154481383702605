const getPosWebsocketConnectionUrl = () => {
  // const { tenantId: storeId, accessToken: authorizer }: any = getUserInfo() || {};
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return `wss://qajulexq97.execute-api.ap-southeast-1.amazonaws.com/dev`;
    // return `wss://tvgy1d0m0i.execute-api.ap-southeast-1.amazonaws.com/dev?authorizer=${authorizer}&storeId=${storeId}&profileId=${profileId}`;
    case 'staging':
      return `wss://re1nbyan7i.execute-api.us-east-2.amazonaws.com/staging`;
    // return `wss://j4jk6u52bd.execute-api.us-east-2.amazonaws.com/staging?authorizer=${authorizer}&storeId=${storeId}&profileId=${profileId}`;
    case 'prod':
      return `wss://jrc42e13fk.execute-api.us-east-1.amazonaws.com/prod`;
    // return `wss://u4uwpzlsm5.execute-api.us-east-1.amazonaws.com/prod?authorizer=${authorizer}&storeId=${storeId}&profileId=${profileId}`;
    default:
      return `wss://qajulexq97.execute-api.ap-southeast-1.amazonaws.com/dev`;
    // return `wss://tvgy1d0m0i.execute-api.ap-southeast-1.amazonaws.com/dev?authorizer=${authorizer}&storeId=${storeId}&profileId=${profileId}`;
  }
};

const websocketService = {
  getPosWebsocketConnectionUrl,
};

export default websocketService;
