import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import ic_confirm_clock_in from 'assets/images/icons/ic-confirm_clock_in.svg';
import ic_confirm_clock_out from 'assets/images/icons/ic-confirm_clock_out.svg';
import ic_confirm_clock_none from 'assets/images/icons/ic-confirm_clock_none.svg';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { IModalConfirmClock } from 'layouts/MainLayout';
import { STAFF_ROLE } from 'config';
import { useState } from 'react';

interface IProps {
  modalData: IModalConfirmClock;
  open: boolean;
  isChecking: boolean;
  onClose: () => void;
  onSubmit: (agr: any) => Promise<void>;
  title: string;
}

const ButtonNo = styled(Button)(() => ({
  background: '#FFFFFF',
  color: '#DD4C23',
  borderRadius: '8px',
  width: '192px',
  height: '54px',
  fontSize: '18px',
  border: '1px solid rgba(145, 158, 171, 0.32)',
  '&:hover': {
    background: '#FFFFFF',
  },
}));

const ButtonYes = styled(Button)(() => ({
  background: '#0B70E1',
  color: '#FFFFFF',
  borderRadius: '8px',
  width: '192px',
  height: '54px',
  fontSize: '18px',
  boxShadow: '0px 8px 16px 0px rgba(13, 114, 227, 0.24)',
  '&:hover': {
    background: '#0B70E1',
  },
}));

const ModalConfirmClock = ({ modalData, open, onClose, onSubmit, title, isChecking }: IProps) => {
  const isOwner = modalData.role === STAFF_ROLE.Owner;
  const [loadedImg, setLoadedImg] = useState(false);
  const handleCloseModal = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(modalData);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseModal}>
      <DialogTitle sx={{ mb: '60px', fontSize: '24px !important', fontWeight: '700', color: '#061340' }}>
        {modalData.role !== STAFF_ROLE.Owner && title}
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 10, top: 20, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon sx={{ width: '28px', height: '28px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: '60px' }}>
        {loadedImg ? <></> : <Skeleton variant="rectangular" width={232} height={212} />}
        <img
          style={loadedImg ? {} : { display: 'none' }}
          onLoad={() => setLoadedImg(true)}
          src={isOwner ? ic_confirm_clock_none : !modalData.isCheckIn || modalData.isCheckOut ? ic_confirm_clock_in : ic_confirm_clock_out}
          alt="clock"
        />
        {isOwner ? (
          <Typography data-hj-allow mt="25px" color="#061340" fontSize="20px" fontWeight={600} align="center">
            The{' '}
            <Typography data-hj-allow color="#DD4C23" fontSize="20px" fontWeight={600} component="span">
              {`'Owner'`}
            </Typography>{' '}
            role does not have access to this feature
          </Typography>
        ) : (
          <Typography data-hj-allow mt="25px" color="#061340" fontSize="20px" fontWeight={600}>
            Hi{' '}
            <Typography data-hj-allow color="#0B70E1" fontSize="20px" fontWeight={600} component="span">
              {modalData.staffName}
            </Typography>
            , Do you want to Clock {!modalData.isCheckIn || modalData.isCheckOut ? 'in' : 'out'} ?
          </Typography>
        )}
      </DialogContent>

      <Stack mt={2.5} mb={2.5} justifyContent={'center'} gap={2} direction={'row'}>
        {isOwner ? (
          <ButtonYes onClick={handleCloseModal}>Ok</ButtonYes>
        ) : (
          <>
            <ButtonNo onClick={handleCloseModal}>No</ButtonNo>
            <ButtonYes onClick={handleSubmit}>{isChecking ? <CircularProgress color="inherit" /> : 'Yes'}</ButtonYes>
          </>
        )}
      </Stack>
    </Dialog>
  );
};

export default ModalConfirmClock;
