import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchListTempTicket } from 'redux/slices/checkout';
import { updateProfileId } from 'redux/slices/global';
import { RootState } from 'redux/store';
import authService from 'services/auth.service';
import { getUserInfo, updateUserInfo } from 'services/axiosInstance';
import { LIST_ENV_PREVENT_LOCKING_TICKET } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';

const WAITING_REFRESH_ACCESS__TOKEN_TIME = 100 * 60 * 60 * 6; // 6 hours
const WAITING_REFRESH_TICKET_ID_TIME = 3000; // 3s

const useInitializeUtilites = () => {
  const dispatch: any = useAppDispatch();

  const { profileId } = useAppSelector((state: RootState) => state.global);
  const { manageTicket } = useAppSelector((state: RootState) => state.checkout);

  // Function
  const refreshAccessToken = async () => {
    try {
      const userInfo = getUserInfo();
      const { refreshToken } = userInfo;
      const res: any = await authService.refreshToken({ refreshToken });
      if (res?.data?.success) {
        updateUserInfo({
          accessToken: res?.data?.data,
        });
      }
    } catch (err: any) {}
  };

  const refreshTicketId = async (cloneManageTicket: any) => {
    try {
      let isRefreshTicketId = false;
      const listTicket = Object.keys(cloneManageTicket);
      for (const ticketUUId of listTicket) {
        if (!cloneManageTicket[ticketUUId].ticketId) {
          isRefreshTicketId = true;
          break;
        }
      }
      if (isRefreshTicketId) {
        dispatch(fetchListTempTicket());
      }
    } catch (err: any) {}
  };

  // HOOKS:
  // Remove log on specific env
  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_LOG === 'true') {
      console.log = function () {};
      console.warn = function () {};
    }
  }, []);

  // Refresh access token
  useEffect(() => {
    setInterval(() => {
      refreshAccessToken();
    }, WAITING_REFRESH_ACCESS__TOKEN_TIME);
  }, []);

  // Setting profileId
  useEffect(() => {
    if (LIST_ENV_PREVENT_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
      dispatch(updateProfileId(''));
    } else if (!profileId) {
      const newProfileId = uuidv4();
      // localStorage.setItem(`${LOCALSTORAGE_KEY.PROFILE_ID}`, profileId);
      dispatch(updateProfileId(newProfileId));
    }
  }, [profileId]);

  // Refresh ticket id
  useEffect(() => {
    const timer = setTimeout(() => {
      refreshTicketId({ ...manageTicket });
    }, WAITING_REFRESH_TICKET_ID_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, [JSON.stringify(manageTicket)]);

  // useEffect(() => {
  //   // if (!window.location.href.includes('http://localhost:')) {
  //   window.addEventListener('focus', onFocus);
  //   // }
  //   return () => {
  //     window.removeEventListener('focus', onFocus);
  //   };
  // }, []);

  return null;
};

export default useInitializeUtilites;
