import { CheckoutPrintCustomer } from 'components/print/CheckoutPrintCustomer';
import Missing from 'features/missingPage';
import { AnimatePresence, motion } from 'framer-motion';
import useChangeRoute from 'hooks/useChangeRoute';
import useCheckPassCode from 'hooks/useCheckPassCode';
import useGuard from 'hooks/useGuard';
import Loadable from 'layouts/Loadable';
import MainLayout from 'layouts/MainLayout';
import { lazy, useEffect } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import CheckoutGuard from './guard/CheckoutGuard';
import PassCodeGuard from './guard/PassCodeGuard';
import { lazyRetry } from './routeUtils';
import cloverService from 'services/clover.service';
import { RootState, dispatch, useAppSelector } from 'redux/store';
import { updateIsMainScreen } from 'redux/slices/global';
import { ETYPE_PAYMENT } from 'utils/constants';
import packageJson from '../../package.json';

const lazyImport: any = (component: any) => Loadable(lazy(() => lazyRetry(() => component)));
const CheckoutNails123 = lazyImport(import('features/checkout-nails123'));
const CheckoutDetailsNails123 = lazyImport(import('features/checkout-nails123/CheckoutDetails'));

const ReportTransactions = lazyImport(import('features/report/transactions'));
const ReportTransactionDetail = lazyImport(import('features/report/transactions/transaction_detail'));

const ReportTickets = lazyImport(import('features/report/tickets'));
const ReportTicketDetail = lazyImport(import('features/report/tickets/ticket_detail'));

const ReportPayrolls = lazyImport(import('features/report/payrolls'));
const ReportPayrollsByStaff = lazyImport(import('features/report/payrolls/PayrollByStaff'));

const IncomeReport = lazyImport(import('features/report/income-report'));
const Report = lazyImport(import('features/report'));
const Campaign = lazyImport(import('features/campaign'));
const CampaignList = lazyImport(import('features/campaign/pages/campaign'));
const ReminderCampaign = lazyImport(import('features/campaign/pages/reminder'));
const CampaignDetail = lazyImport(import('features/campaign/pages/campaign_detail'));

const TurnTracker = lazyImport(import('features/turn-tracker'));

const StoreSettings = lazyImport(import('features/store-settings'));
const CustomerManagerInfo = lazyImport(import('features/store-settings/customer-manager'));
const Services = lazyImport(import('features/store-settings/services'));
const BusinessDetails = lazyImport(import('features/store-settings/business-details'));
const GiftCard = lazyImport(import('features/store-settings/gift-card'));
const GiftCardHistory = lazyImport(import('features/store-settings/gift-card/components/GiftCardHistory'));
const DiscountPromotion = lazyImport(import('features/store-settings/discount-promotion'));

const StaffManagement = lazyImport(import('features/store-settings/staff-manager'));
const StaffManagementInfo = lazyImport(import('features/store-settings/staff-manager/page/staff-info'));
const StaffRolePermission = lazyImport(import('features/store-settings/staff-manager/page/role-permission'));
const StaffCheckInAndOut = lazyImport(import('features/store-settings/staff-manager/page/check-in-and-out'));

const ConfigSettings = lazyImport(import('features/config-settings'));
const PaymentSettings = lazyImport(import('features/config-settings/payment-settings'));
const CheckoutSettings = lazyImport(import('features/config-settings/checkout-settings'));
const CheckinSettings = lazyImport(import('features/config-settings/checkin-settings'));
const DisplaySettings = lazyImport(import('features/config-settings/display-settings'));
const Printer = lazyImport(import('features/config-settings/printer'));
const CustomerReceipt = lazyImport(import('features/config-settings/printer/page/custom-receipt'));
const Catalogue = lazyImport(import('features/config-settings/printer/page/catalogue'));
const AdminSettings = lazyImport(import('features/config-settings/admin-settings'));
const TurnTrackerSettings = lazyImport(import('features/config-settings/turn-tracker'));
const Notification = lazyImport(import('features/config-settings/notification'));
const Review = lazyImport(import('features/config-settings/review'));
const ReportDev = lazyImport(import('features/config-settings/report-dev'));
const Booking = lazyImport(import('features/booking'));

const Page = ({ children }: any) => {
  const location = useLocation();
  const locationArr = location.pathname?.split('/') ?? [];
  return (
    <motion.div
      key={locationArr[1]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      style={{ height: '100%', width: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export default function RootRoutes() {
  const isAuth = useGuard();
  useChangeRoute();
  const { isMainScreen, checkoutSettings } = useAppSelector((state: RootState) => state.global);
  const location = useLocation();
  const checkPassCode = useCheckPassCode();

  useEffect(() => {
    const { pathname }: any = location;

    if (!(pathname.includes('checkout') && pathname?.length > 11) && !isMainScreen) {
      if (checkoutSettings?.cloverSettings?.chosen === ETYPE_PAYMENT.USB) {
        cloverService.backToMainScreen();
      }
      dispatch(updateIsMainScreen(true));
    }
  }, [location]);

  useEffect(() => {
    const clearCacheAndReload = async () => {
      const version = localStorage.getItem('version');
      if (version !== packageJson.version) {
        try {
          const names = await caches.keys();
          for (const name of names) {
            await caches.delete(name);
          }
          sessionStorage.clear();
          localStorage.clear();
        } catch (error) {
          console.error('Cache API is not supported by this browser.', error);
        }
        localStorage.setItem('version', packageJson.version);
        window.location.reload(); // Forcing reload without cache
      }
    };

    clearCacheAndReload();
  }, []);

  if (!isAuth) {
    return <></>;
  }
  const renderLayout = () => {
    return (
      <Page>
        {checkPassCode && <PassCodeGuard isCheckout fullScreen />}
        {!checkPassCode && <MainLayout />}
      </Page>
    );
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location}>
        <Route path="/" element={renderLayout()}>
          <Route path="checkout" element={<CheckoutGuard />}>
            <Route path="" element={<CheckoutNails123 />} />
            <Route path=":checkoutId" element={<CheckoutDetailsNails123 />} />
          </Route>
          <Route path="test" element={<CheckoutPrintCustomer />} />
          <Route path="campaign" element={checkoutSettings?.showCampaign === false ? <Missing /> : !checkPassCode ? <Outlet /> : <PassCodeGuard />}>
            <Route path="" element={<Campaign />} />
            <Route path="list" element={<CampaignList />} />
            <Route path="reminder" element={<ReminderCampaign />} />
            <Route path="detail" element={<CampaignDetail />} />
          </Route>

          <Route path="report" element={!checkPassCode ? <Outlet /> : <PassCodeGuard />}>
            <Route path="" element={<Report />} />
            <Route path="payrolls" element={<ReportPayrolls />} />
            <Route path="payrolls/payroll_by_staff" element={<ReportPayrollsByStaff />} />

            <Route path="transactions" element={<ReportTransactions />} />
            <Route path="transactions/detail" element={<ReportTransactionDetail />} />

            <Route path="tickets" element={<ReportTickets />} />
            <Route path="tickets/detail" element={<ReportTicketDetail />} />

            <Route path="income" element={<IncomeReport />} />
          </Route>
          <Route path="turn-tracker" element={checkoutSettings?.turnTracker === false ? <Missing /> : !checkPassCode ? <Outlet /> : <PassCodeGuard />}>
            <Route path="" element={<TurnTracker />} />
          </Route>

          {/* settings routes */}
          <Route path="store-settings" element={!checkPassCode ? <Outlet /> : <PassCodeGuard />}>
            <Route path="" element={<StoreSettings />} />
            <Route path="details" element={<BusinessDetails />} />
            <Route path="services" element={<Services />} />
            <Route path="customer_setting" element={<CustomerManagerInfo />} />
            <Route path="staffs" element={<StaffManagement />} />
            <Route path="staffs/info" element={<StaffManagementInfo />} />
            <Route path="staffs/role-permission" element={<StaffRolePermission />} />
            <Route path="staffs/clock-in-and-out" element={checkoutSettings?.staffCheckInAndOut === false ? <Missing /> : <StaffCheckInAndOut />} />
            <Route path="income_report" element={<IncomeReport />} />

            {/* <Route path="giftcard_setting" element={<GiftCardSettings />} /> */}
            <Route path="gift-card" element={<GiftCard />} />
            <Route path="gift-card/history" element={<GiftCardHistory />} />
            <Route path="discount-promotion" element={<DiscountPromotion />} />
          </Route>

          <Route path="config-settings" element={!checkPassCode ? <Outlet /> : <PassCodeGuard />}>
            <Route path="" element={<ConfigSettings />} />
            <Route path="payment" element={<PaymentSettings />} />
            <Route path="checkout_setting" element={<CheckoutSettings />} />
            <Route path="checkin_setting" element={<CheckinSettings />} />
            <Route path="display_setting" element={<DisplaySettings />} />
            <Route path="admin_setting" element={<AdminSettings />} />
            <Route path="printer" element={<Printer />} />
            <Route path="printer/custom-receipt" element={<CustomerReceipt />} />
            <Route path="printer/catalogue" element={<Catalogue />} />
            <Route path="turn_tracker" element={<TurnTrackerSettings />} />
            <Route path="notification" element={<Notification />} />
            <Route path="Review" element={<Review />} />
            <Route path="report-dev" element={<ReportDev />} />
          </Route>
          <Route path="booking" element={!checkPassCode ? <Outlet /> : <PassCodeGuard />}>
            <Route path="" element={<Booking />} />
          </Route>
          <Route path="*" element={<Missing />} />
          {/* catch all */}
        </Route>
      </Routes>
    </AnimatePresence>
  );
}
