import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import checkoutService from 'services/checkout.service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ButtonCancel = styled(Button)(({ theme }) => ({
  background: '#EEEFF8',
  color: '#0B70E1',
  borderRadius: '8px',
  width: '192px',
  height: '54px',
  marginRight: '16px',
}));

const ButtonDelete = styled(Button)(({ theme }) => ({
  background: '#DD4C23',
  color: '#FFFFFF',
  borderRadius: '8px',
  width: '192px',
  height: '54px',
  '&:hover': {
    background: '#DD4C23',
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Typography data-hj-allow sx={{ m: 0, p: 0, fontSize: '24px !important', fontWeight: '500', color: '#545454' }} variant="h3" gutterBottom component="div">
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function ModalDeleteTicketCheckout({ open, onClose, title = 'Delete Ticket', maxWidth, modalData, isSubmitting, onSubmitData }: any) {
  const { ticketId, PK, SK } = modalData || {};
  const [loading, setLoading] = useState<boolean>(false);
  const handleDeleteTicket = async (PK: any, SK: any) => {
    setLoading(true);
    try {
      const response = await checkoutService.completeCheckin({ pk: PK, sk: SK });
      if (!response.data.success) throw new Error('Error');
      onSubmitData(ticketId);
    } catch (error) {
      console.log('error :>> ', error);
    }
    setLoading(false);
  };
  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth={maxWidth}
      sx={{
        width: '600px',
        margin: '0 auto',
      }}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography data-hj-allow>This ticket will be deleted. You won’t be able to recover it.</Typography>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onClose}>Cancel</ButtonCancel>
        <ButtonDelete
          onClick={() => {
            if (PK && SK) {
              handleDeleteTicket(PK, SK);
            } else {
              onSubmitData(ticketId);
            }
          }}
          disabled={isSubmitting || loading}
        >
          {isSubmitting || loading ? <CircularProgress color="inherit" /> : 'Delete'}{' '}
        </ButtonDelete>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default ModalDeleteTicketCheckout;
