import { RootState, useAppSelector } from '../redux/store';
import { get } from 'lodash';
import moment from 'moment';
import { fNumberFixed, fNumberInterger } from 'utils/formatNumber';
import { calculateLatestTicketId, genTicketId } from 'utils/random';

function useManageTicket() {
  // ========================= GLOBAL STATE =========================
  const { manageTicket } = useAppSelector((state: RootState) => state.checkout);
  const { checkoutSettings } = useAppSelector((state: RootState) => state.global);

  const cashDiscountAndTransactionFee = checkoutSettings?.cashDiscountAndTransactionFee || {};
  const { cashDiscount } = cashDiscountAndTransactionFee || {};
  const isCashDiscount = cashDiscountAndTransactionFee.chosen === 'cashDiscount' && cashDiscountAndTransactionFee.cashDiscount > 0;

  const caculatorTotal = (currentTicket: any) => {
    const { discountType = 'DiscountByPercent', discountAmount = 0 } = currentTicket;
    const manageCart = currentTicket.manageCart || {};
    let totalDiscount = 0;
    let _subTotal = 0;
    for (const cartKey in manageCart) {
      const staffDiscount = manageCart[cartKey].listProduct.reduce((acc: number, curr: any) => {
        const price = Number(curr?.price || 0);
        const serviceDiscount = curr.serviceDiscount || 0;
        const _discountAmount = (price * serviceDiscount) / 100;
        return Number(acc) + Number(_discountAmount);
      }, 0);
      const staffSubTotal = manageCart[cartKey].listProduct.reduce((acc: number, curr: any) => {
        const price = Number(curr?.price || 0);
        const sub = price;
        return Number(acc) + Number(sub);
      }, 0);
      totalDiscount = totalDiscount + staffDiscount;
      _subTotal = _subTotal + staffSubTotal;
    }

    const _listColor: Array<string> = [];
    Object.values(manageCart).forEach(() => {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      _listColor.push(color);
    });

    let total: any = 0;
    let discountAmountTotal = 0;
    let subTotal = 0;

    if (discountType === 'DiscountByPercent') {
      discountAmountTotal = fNumberInterger(totalDiscount);
    } else {
      discountAmountTotal = discountAmount;
    }

    subTotal = _subTotal;
    total = fNumberFixed(subTotal - discountAmountTotal, 2);

    return {
      subTotal,
      discountAmountTotal,
      total,
    };
  };

  const getTicketsActive = (staff: any) => {
    const listTicket: any = [];
    const staffPK = staff?.PK;
    Object.keys(manageTicket || {}).forEach((element) => {
      const ticketData = get(manageTicket, `${element}`, {});
      const { statusTicket } = ticketData;
      const manageCart = get(ticketData, 'manageCart', {});
      let totalProduct: any = [];
      Object.keys(manageCart).forEach((cart) => {
        const listProduct = get(manageCart, `${cart}.listProduct`, []);
        totalProduct = [...totalProduct, ...listProduct];
      });

      const dataTotal = caculatorTotal(ticketData);
      if (Object.keys(manageCart).includes(staffPK) && statusTicket !== 'failed') {
        listTicket.push({
          ...ticketData,
          total: dataTotal?.total,
          listService: [...totalProduct],
        });
      }
    });
    return listTicket;
  };

  const isTicketHasService = (ticketItem: any) => {
    if (!ticketItem?.manageCart) {
      return false;
    }
    const listStaff = Object.keys(ticketItem?.manageCart || {});
    for (const staffItem of listStaff) {
      if (ticketItem?.manageCart[staffItem].listProduct.length > 0) {
        return true;
      }
    }
  };
  const getNewOfflineTicketId = (manageTicket: any) => {
    const cloneManageTicket = { ...manageTicket };
    let listTicket = [];
    for (const item in cloneManageTicket) {
      listTicket.push(cloneManageTicket[item]);
    }
    const startDate = `${moment().startOf('day').valueOf()}`;
    const endDate = `${moment().endOf('day').valueOf()}`;

    listTicket = listTicket
      .filter((item) => item.createdDate >= startDate && item.createdDate <= endDate)
      .sort((a, b) => {
        return b.createdDate - a.createdDate;
      });

    const latestLast4Id = listTicket[0] ? calculateLatestTicketId(listTicket[0].ticketId) : '0001';

    return genTicketId(latestLast4Id);
  };

  return {
    getTicketsActive,
    isTicketHasService,
    getNewOfflineTicketId,
  };
}

export default useManageTicket;
