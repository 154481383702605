import axios from 'axios';
import { AUTH_DOMAIN } from 'services/appDomain';

const AUTH_HOST = AUTH_DOMAIN || 'https://4qili1vtzh.execute-api.ap-southeast-1.amazonaws.com/dev';

// ========== SERVICE SETUP ==========

const refreshToken = (data: any) => {
  return axios.post(`${AUTH_HOST}/auth/refresh-token`, { ...data });
};

const authService = {
  refreshToken,
};

export default authService;
