// @mui
import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';

import { dispatch } from 'redux/store';
import { NAV_SUB_HEADER, WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import { NavListRoot } from './NavList';
import { Fragment } from 'react';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { MODIFY_STATUS } = WS_ACTION_TYPE;

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props: any) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }: any) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type NavSectionVerticalProps = {
  isCollapse: boolean;
  navConfig: Array<any>;
  other?: any;
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionVerticalProps) {
  const { sendJsonMessage } = useAppSelector((state: RootState) => state.global);
  const { currentTicket } = useAppSelector((state: RootState) => state.checkout);
  const { checkoutSettings } = useAppSelector((state: RootState) => state.global);

  return (
    <Box
      {...other}
      onClick={() => {
        if (currentTicket?._id) {
          const updateSocketParams = {
            identify: 'updateTicketLockingStatus-6',
            eventName: MODIFY_STATUS,
            data: {
              tenantId: currentTicket.tenantId,
              _id: currentTicket._id,
              ticketId: currentTicket.ticketId,
              activeProfile: '',
            },
          };
          sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
        }
      }}
    >
      {navConfig.map((navConfigItem: any) => {
        const a = Array.isArray(navConfigItem?.items) && navConfigItem?.items[0].title.toLowerCase().split(' ');
        a.push('route');
        const isHidden =
          (navConfigItem.subheader === NAV_SUB_HEADER.TURN_TRACKER && checkoutSettings?.turnTracker === false) ||
          (navConfigItem.subheader === NAV_SUB_HEADER.CAMPAIGN && checkoutSettings?.showCampaign === false);
        return isHidden ? (
          <Fragment key={navConfigItem.subheader}></Fragment>
        ) : (
          <List
            data-test-id={a.join('-')}
            key={navConfigItem.subheader}
            disablePadding
            sx={{ px: isCollapse ? '2px' : 2, cursor: 'pointer' }}
            onClick={() => {
              if (navConfigItem.onClickAction) {
                dispatch(navConfigItem.onClickAction);
              }
            }}
          >
            {/* <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle> */}

            {navConfigItem.items.map((list: any) => (
              <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
