import navConfig from 'layouts/MainLayout/navbar/NavConfig';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCurrentPage, updatePasscode } from 'redux/slices/global';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import { formatNavConfig } from 'utils/formatNavConfig';

// List redirect path without need to verify passcode
const REDIRECT_PATH = ['?code=', PATH_DASHBOARD.config_settings.printer_custom_receipt];

const useChangeRoute = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentPage } = useAppSelector((state: RootState) => state.global);
  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/checkout`);
    }

    const currentPath = currentPage.path.split('/')[1] || '------';

    const newPath = location.pathname.split('/')[1] || '******';

    if (newPath !== currentPath && REDIRECT_PATH.every((path) => !location.pathname.includes(path))) {
      //remove passcode in redux: global
      dispatch(updatePasscode({}));
    }
    const listData = formatNavConfig(navConfig);
    const currentNav = listData.filter((item) => item.path === location.pathname);
    if (!currentNav.length) return;
    dispatch(setCurrentPage(currentNav[0]));
  }, [location.pathname]);
};

export default useChangeRoute;
