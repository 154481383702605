import { useNavigate, useParams } from 'react-router-dom';
import { updateCurrentTicket, updateManageTicket, updateModalDeleteTicket } from 'redux/slices/checkout';
import { RootState, dispatch, useAppSelector } from 'redux/store';
import checkoutService from 'services/checkout.service';
import { WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import useManageTicket from 'hooks/useManageTicket';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { REMOVE } = WS_ACTION_TYPE;

function useCheckin() {
  const { manageTicket, modalDeleteTicket } = useAppSelector((state: RootState) => state.checkout);
  const { sendJsonMessage } = useAppSelector((state: RootState) => state.global);
  const { checkoutId } = useParams();
  const navigate = useNavigate();
  const { isTicketHasService } = useManageTicket();

  const handleCompleteTicketCheckin = async (data: any, type: string) => {
    const { PK, SK, _id } = data;
    try {
      if (type === 'delete') {
        handleDeleteTicket(_id, type, PK, SK);
        return;
      }
      const response = await checkoutService.completeCheckin({ pk: PK, sk: SK });
      if (!response.data.success) throw new Error('Error');
      handleDeleteTicket(_id, type);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleDeleteTicket = (ticketId: any, type?: string, PK?: any, SK?: any) => {
    const cloneObj = { ...manageTicket };
    const data = cloneObj[`${ticketId}`];

    if (!isTicketHasService(data)) {
      const deleteTicket = cloneObj[`${ticketId}`];

      delete cloneObj[`${ticketId}`];
      const completeTicketParams = {
        // pk: `store#${deleteTicket.tenantId}#temp_ticket`,
        // sk: `temp_ticket#${deleteTicket.ticketId}`,
        id: deleteTicket.ticketId,
        _id: deleteTicket._id,
        tenantId: deleteTicket.tenantId,
        isHardDelete: true,
      };
      // checkoutService.completeTempTicket(completeTicketParams);
      dispatch(updateManageTicket(cloneObj));
      dispatch(updateModalDeleteTicket({ ...modalDeleteTicket, open: false, data: null }));

      const completeParams = {
        identify: 'handleDeleteTicket-6',
        eventName: REMOVE,
        data: completeTicketParams,
      };
      sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...completeParams });

      if (checkoutId === ticketId) {
        dispatch(updateCurrentTicket(null));
        navigate('/checkout');
      }
    } else {
      if (type !== 'complete') {
        dispatch(updateModalDeleteTicket({ ...modalDeleteTicket, open: true, data: { ticketId, PK, SK } }));
      }
    }
  };

  return {
    handleCompleteTicketCheckin,
    handleDeleteTicket,
  };
}

export default useCheckin;
