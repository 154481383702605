import moment from 'moment';

export const genCharacter = (type: string, length: number) => {
  let result = '';
  let characters = '';
  switch (type) {
    case 'number':
      characters = '0123456789';
      break;
    case 'upperCase':
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      break;
    case 'lowerCase':
      characters = 'abcdefghijklmnopqrstuvwxyz';
      break;
    case 'mixed':
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      break;
    default:
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      break;
  }
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const genCurrentDate = () => {
  const dateStr = moment().format('MM.DD.YY');
  const listStr = dateStr.split('.');
  return listStr[0] + listStr[1] + listStr[2];
};

export const genTicketId = (last4?: string) => {
  const newLast4 = last4 || genCharacter('number', 4);
  return `W${genCharacter('upperCase', 1)}-${genCurrentDate()}-${newLast4}`;
};

export const randomStringNumber = (length: number) => {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const calculateLatestTicketId = (previousTicketId: string) => {
  let newId = `${Number(previousTicketId.split('-')[2]) + 1}`;
  if (newId.length < 4) {
    do {
      newId = '0' + `${newId}`.toString();
    } while (newId.length < 4);
  }
  return newId;
};
