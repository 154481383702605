import moment from 'moment';
import { RootState, useAppSelector } from 'redux/store';
import { numberToPhone, upperCaseFirstLetter } from 'utils/common';
import { CUSTOMER_TICKET_FIELDS, PAYMENT_METHODS } from 'utils/constants';
import { customerLayoutCss, passDataToCustomerPrintLayout } from 'features/config-settings/printer/components/CustomerTicketPreview';

export const CheckoutPrintCustomer = ({ printData, reportTitle }: any) => {
  const { checkoutSettingsStatus, checkoutSettings, businessInfo } = useAppSelector((state: RootState) => state.global);
  const entryLocation = checkoutSettingsStatus === 'idle' ? checkoutSettings?.entryLocation : 'onTabletScreen';
  const {
    discount = 0,
    total,
    staffs = [],
    receiptNo,
    subTotal,
    transactionFee,
    tax,
    tip,
    transactionNo,
    customerName = '',
    payments = [],
    entryMethod,
    customerPoint,
    cashPaid,
    changeDue,
    giftCardIds = [],
    isPartialPayment = false,
    cashDiscountAmount,
  } = (printData as any) || {};

  const genPaymentMethodStr = (paymentMethod: string, cardLastNumbers: any, giftCardIds: Array<any>, isPartialPayment: boolean) => {
    switch (paymentMethod) {
      case 'external credit':
      case 'credit':
        return `${upperCaseFirstLetter(paymentMethod)} (${cardLastNumbers})`;
      case 'cash':
        return `${upperCaseFirstLetter(paymentMethod)}`;
      case 'gift card':
        if (isPartialPayment) {
          return `${upperCaseFirstLetter(paymentMethod)} - ${giftCardIds[0]}`;
        }
        return `${upperCaseFirstLetter(paymentMethod)} (${giftCardIds.length})`;
      case PAYMENT_METHODS.OTHER.BANK_TRANSFER:
        return 'Bank Transfer';
      default:
        return `${upperCaseFirstLetter(paymentMethod)}`;
    }
  };

  const getPaymentMethodV2 = (payments: Array<any>, isPartialPayment: boolean) => {
    let paymentMethodStr = '';
    const listPayment: any = [];
    if (!isPartialPayment) {
      const cardLastNumbers = payments[0]?.cardLastNumbers;
      paymentMethodStr = genPaymentMethodStr(payments[0]?.paymentMethod || '', cardLastNumbers, giftCardIds, isPartialPayment);
    } else {
      paymentMethodStr = 'Partial';
      payments.forEach((payment: any) => {
        listPayment.push({
          paymentMethodStr: genPaymentMethodStr(payment?.paymentMethod, payment?.cardLastNumbers, payment?.giftCardIds, isPartialPayment),
          total: payment?.total,
        });
      });
    }
    return {
      paymentMethodStr,
      listPayment,
    };
  };

  const paymentMethod: string[] = [];
  let giftCardIdList: string[] = [];
  let giftCardTotals: number[] = [];
  payments.map((payment: { paymentMethod: string; giftCardIds: Array<string>; giftCardTotals: Array<number> }) => {
    paymentMethod.push(payment.paymentMethod);
    if (payment?.paymentMethod === PAYMENT_METHODS.GIFT_CARD) {
      giftCardIdList = [...payment.giftCardIds];
      giftCardTotals = [...payment.giftCardTotals];
    }
    return;
  });
  const objectPaymentMethod = getPaymentMethodV2(payments, isPartialPayment);
  const { paymentMethodStr, listPayment } = objectPaymentMethod;
  const printLayout = checkoutSettings?.printLayout?.customerLayout || CUSTOMER_TICKET_FIELDS;
  const { businessName = '', businessPhone = '', address = '', address2 = '', state = '', city = '', zipCode = '' } = businessInfo;

  const getPreviewField = (index: number) => {
    // Pass property noteContent in case user adds new notes
    return printLayout[index]?.display
      ? passDataToCustomerPrintLayout(printLayout[index]?.key, printLayout[index]?.noteContent, '', {
          receiptNo,
          date: {
            from: moment()
              .tz(businessInfo?.timezone || 'US/Central')
              .format('MM/DD/YYYY hh:mm A'),
          },
          transactionNo,
          entryMethod,
          customerName,
          paymentMethod,
          paymentMethodStr,
          subTotal,
          tax,
          total,
          listService: staffs,
          listPayment,
          entryLocation,
          customerPoint,
          cashPaid,
          changeDue,
          tip,
          transactionFee,
          giftCardIds: giftCardIdList || [],
          giftCardTotals: giftCardTotals || [],
          isPartialPayment,
          discount,
          currency: businessInfo.currency,
          businessName,
          businessAddress: {
            address1: address,
            address2,
            state,
            city,
            zipCode,
          },
          businessPhone: numberToPhone(businessPhone.replace(/\D/g, '')),
          customerReceipt: reportTitle,
          cashDiscountAmount,
        })
      : '';
  };

  return (
    <html>
      <head>
        <style>{customerLayoutCss}</style>
      </head>
      <body>
        <div className={'customer-receipt-layout'}>
          <div className={'header'}>
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(0) }} />
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(1) }} />
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(2) }} />
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(3) }} />
          </div>
          <div className={'root'}>
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(4) }} />
            <div className={'section'}>
              <div className={'info-section'}>
                <div dangerouslySetInnerHTML={{ __html: getPreviewField(5) }} />
                <div dangerouslySetInnerHTML={{ __html: getPreviewField(6) }} />
                <div dangerouslySetInnerHTML={{ __html: getPreviewField(7) }} />
                <div dangerouslySetInnerHTML={{ __html: getPreviewField(8) }} />
                <div dangerouslySetInnerHTML={{ __html: getPreviewField(9) }} />
                <div dangerouslySetInnerHTML={{ __html: getPreviewField(10) }} />
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(11) }} />
            <div className={'section'}>
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(12) }} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(13) }} />
            <div className={'section'}>
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(14) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(15) }} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: getPreviewField(16) }} />
            <div className={'section'}>
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(17) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(18) }} />
              <div dangerouslySetInnerHTML={{ __html: getPreviewField(19) }} />
            </div>
          </div>
          {printLayout.map((_: any, index: number) => index >= 20 && <div key={index} dangerouslySetInnerHTML={{ __html: getPreviewField(index) }} />)}{' '}
        </div>
      </body>
    </html>
  );
};
