import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState, useAppSelector } from 'redux/store';

const useCheckPassCode = () => {
  const [checkPassCode, setCheckPassCode] = useState(false);
  const location = useLocation();
  const { passcode = {}, checkoutPasscodeModal } = useAppSelector((state: RootState) => state.global);

  useEffect(() => {
    if (checkoutPasscodeModal.open) {
      setCheckPassCode(true);
    } else if (!location.pathname.includes('checkout') && Object.keys(passcode).length < 1) {
      setCheckPassCode(true);
    } else {
      setCheckPassCode(false);
    }
  }, [JSON.stringify(location), JSON.stringify(passcode), JSON.stringify(checkoutPasscodeModal)]);

  return checkPassCode;
};

export default useCheckPassCode;
