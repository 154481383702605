import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IBooking, ICategory } from 'features/booking/interface';

interface BookingState {
  bookings: Array<IBooking>;
  categories: Array<ICategory>;
}

const initialState: BookingState = {
  bookings: [],
  categories: [],
};

export const bookingSlice = createSlice({
  name: 'booking',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setBookings: (state: BookingState, action: PayloadAction<any>) => {
      state.bookings = [...action.payload];
    },
    setCategories: (state: BookingState, action: PayloadAction<any>) => {
      state.categories = [...action.payload];
    },
  },
});

export const { setBookings, setCategories } = bookingSlice.actions;

export default bookingSlice.reducer;
