// ----------------------------------------------------------------------
export default function CssBaseline() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        // '@font-face': {
        //   fontFamily: 'Password',
        //   fontStyle: 'normal',
        //   fontWeight: 400,
        //   // src: `url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf)`,
        //   // src: `url(assets/fonts/password.ttf)`,
        //   // src: url(`./assets/fonts/password.ttf`) format('truetype'),
        // },
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
      },
    },
  };
}
