// Actions

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITicketTransactionCloud, IModalRewardPoint } from 'common/interfaces';
import moment from 'moment';
import businessService from 'services/business.service';
import checkoutService from 'services/checkout.service';

// Define a type for the slice state
export interface CheckoutState {
  status_device: boolean;

  manageTicket: any;
  externalCreditExtraInfo: Array<ITicketTransactionCloud>;

  loadServiceStatus: 'idle' | 'loading' | 'failed';
  loadTempTicketStatus: 'idle' | 'loading' | 'failed';
  listService: Array<any>;
  listTempTicket: Array<any>;

  listOfflineTicket: Array<any>;
  listEmptyTicket: Array<any>;

  baseTicketDate?: Date;
  currentTicket: null | {
    _id: string;
    staffName: string;
    ticketId: string;
    createdDate: string;
    phoneNumber?: string;
    manageCart?: null | {
      [key: string]: {
        staffPK?: string;
        staffSK?: string;
        staffName: string;
        staffPhone: string;
        listProduct: Array<any>;
      };
    };
    customerId?: string;
    customerName?: string;
    customerPhone?: string;
    visitDiscountAmount?: number;
    rewardAmount?: number;
    disableBtnPayment?: boolean;
    [key: string]: any;
  };

  // modal
  modalEditCustomer: {
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalRewardPoint: IModalRewardPoint;

  modalEditService: {
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalEditDiscount: {
    type: string; // SERVICE, STAFF, TOTAL
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalEditGratuity: {
    open: boolean;
    isSubmitting: boolean;
  };

  modalCustomAmount: {
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalDeleteTicket: {
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalPayment: {
    status: 'process' | 'pending' | 'error' | 'warning' | 'success';
    paymentMethod?: 'cash' | 'credit' | 'external_credit_card' | undefined;
    open: boolean;
    data: any;
    isSubmitting: boolean;
  };

  modalConfirmCashChange: {
    open: boolean;
    data: any;
  };

  modalPayroll: {
    open: boolean;
    data: any;
  };

  modalConfirmExCC: {
    open: boolean;
    data: any;
  };

  modalConfirmPrint: {
    open: boolean;
    data: any;
  };

  modalDivideGratuity: {
    open: boolean;
    data: any;
  };

  modalOtherPaymentMethods: {
    open: boolean;
    data: any;
    onSubmit: any;
  };

  isLoadingCreateTempTicket: boolean;
  reasonLinkedExCC: string;
}

// Define the initial state using that type
const initialState: CheckoutState = {
  status_device: false,

  manageTicket: {},

  loadServiceStatus: 'loading',
  loadTempTicketStatus: 'loading',
  listService: [],
  listTempTicket: [],
  listOfflineTicket: [],
  listEmptyTicket: [],

  baseTicketDate: new Date(),
  currentTicket: null,

  // modal
  modalEditCustomer: {
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalRewardPoint: {
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalEditService: {
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalEditDiscount: {
    type: 'SERVICE',
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalEditGratuity: {
    open: false,
    isSubmitting: false,
  },

  modalCustomAmount: {
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalDeleteTicket: {
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalPayment: {
    status: 'process',
    paymentMethod: 'cash',
    open: false,
    data: null,
    isSubmitting: false,
  },

  modalConfirmCashChange: {
    open: false,
    data: null,
  },

  modalConfirmExCC: {
    open: false,
    data: null,
  },

  modalPayroll: {
    open: false,
    data: null,
  },

  modalConfirmPrint: {
    open: false,
    data: null,
  },

  modalDivideGratuity: {
    open: false,
    data: null,
  },

  modalOtherPaymentMethods: {
    open: false,
    data: null,
    onSubmit: null,
  },

  isLoadingCreateTempTicket: false,
  externalCreditExtraInfo: [],
  reasonLinkedExCC: '',
};

export const checkoutSlice: any = createSlice({
  name: 'checkout',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateStatusDevice: (state: CheckoutState, action: PayloadAction<any>) => {
      state.status_device = action.payload;
    },

    updateManageTicket: (state: CheckoutState, action: PayloadAction<any>) => {
      state.manageTicket = action.payload;
    },

    updateExternalCreditExtraInfo: (state: CheckoutState, action: PayloadAction<any>) => {
      state.externalCreditExtraInfo = action.payload;
    },

    updateReasonLinkedCC: (state: CheckoutState, action: PayloadAction<any>) => {
      state.reasonLinkedExCC = action.payload;
    },

    addManageTicketItem: (state: CheckoutState, action: PayloadAction<any>) => {
      const newData = { ...state.manageTicket };
      newData[`${action.payload._id}`] = { ...action.payload };
      state.manageTicket = { ...newData };
    },

    updateBaseTicketDate: (state: CheckoutState, action: PayloadAction<any>) => {
      // const nextState = state.baseTicketDate;
      state.baseTicketDate = action.payload;
    },

    updateManageTicketItem: (state: CheckoutState, action: PayloadAction<any>) => {
      state.manageTicket = updateTempTicketData({ ...state.manageTicket }, action.payload);
    },

    updateManageTicketStatus: (state: CheckoutState, action: PayloadAction<any>) => {
      state.manageTicket = updateTempTicketStatusData({ ...state.manageTicket }, action.payload);
    },

    updateManageTicketItemId: (state: CheckoutState, action: PayloadAction<any>) => {
      state.manageTicket = mappingTempTicketId({ ...state.manageTicket }, action.payload);
    },

    updateDiscountAmount: (state: CheckoutState, action: PayloadAction<{ _id: string; discountAmount: number }>) => {
      state.manageTicket = {
        ...state.manageTicket,
        [action.payload._id]: {
          ...state.manageTicket[action.payload._id],
          discountAmount: action.payload.discountAmount,
        },
      };
    },

    removeManageTicketItem: (state: CheckoutState, action: PayloadAction<any>) => {
      state.manageTicket = removeTempTicketData({ ...state.manageTicket }, action.payload);
    },

    updateCurrentTicket: (state: CheckoutState, action: PayloadAction<any>) => {
      state.currentTicket = action.payload ? { ...action.payload } : null;
    },

    updateBtnPayment: (state: CheckoutState, { payload: { _id, status } }: PayloadAction<{ _id: string; status: boolean }>) => {
      state.manageTicket[_id].disableBtnPayment = status;
    },

    updateListOfflineTicket: (state: CheckoutState, action: PayloadAction<any>) => {
      state.listOfflineTicket = [...action.payload];
    },

    //modal
    updateModalEditCustomer: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalEditCustomer = { ...action.payload };
    },

    updateModalRewardPoint: (state: CheckoutState, action: PayloadAction<IModalRewardPoint>) => {
      state.modalRewardPoint = { ...action.payload };
    },

    updateModalEditDiscount: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalEditDiscount = { ...action.payload };
    },

    updateModalEditGratuity: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalEditGratuity = { ...action.payload };
    },

    updateModalCustomAmount: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalCustomAmount = { ...action.payload };
    },

    updateModalDeleteTicket: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalDeleteTicket = { ...action.payload };
    },

    updateModalPayment: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalPayment = { ...action.payload };
    },

    updateModalConfirmCashChange: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalConfirmCashChange = { ...action.payload };
    },

    updateModalPayroll: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalPayroll = { ...action.payload };
    },

    updateModalConfirmExCC: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalConfirmExCC = { ...action.payload };
    },

    updateModalConfirmPrint: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalConfirmPrint = { ...action.payload };
    },

    resetListEmptyTicket: (state: CheckoutState) => {
      state.listEmptyTicket = [];
    },

    updateIsLoadingCreateTempTicket: (state: CheckoutState, action: PayloadAction<any>) => {
      state.isLoadingCreateTempTicket = action.payload;
    },

    updateModalDivideGratuity: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalDivideGratuity = { ...action.payload };
    },

    updateModalOtherPaymentMethods: (state: CheckoutState, action: PayloadAction<any>) => {
      state.modalOtherPaymentMethods = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch list service
      .addCase(fetchListService.pending, (state) => {
        state.loadServiceStatus = 'loading';
      })
      .addCase(fetchListService.fulfilled, (state, action) => {
        state.loadServiceStatus = 'idle';
        state.listService = [...action.payload];
      })
      .addCase(fetchListService.rejected, (state) => {
        state.loadServiceStatus = 'failed';
      })

      // Fetch list temp ticket with
      .addCase(fetchListTempTicket.pending, (state) => {
        state.loadTempTicketStatus = 'loading';
      })
      .addCase(fetchListTempTicket.fulfilled, (state, action) => {
        state.loadTempTicketStatus = 'idle';
        state.manageTicket = mappingTempTicketDataToObject({ ...state.manageTicket }, [...action.payload], {
          ...state.currentTicket,
        });
      })
      .addCase(fetchListTempTicket.rejected, (state) => {
        state.loadTempTicketStatus = 'failed';
      });
  },
});

const updateTempTicketData = (currentManageTicket: any, ticketData: any) => {
  currentManageTicket[`${ticketData._id}`] = { ...currentManageTicket[`${ticketData._id}`], ...ticketData, activeProfile: ticketData.activeProfile || '' };
  return currentManageTicket;
};

const updateTempTicketStatusData = (currentManageTicket: any, ticketData: any) => {
  if (currentManageTicket[`${ticketData._id}`]) {
    currentManageTicket[`${ticketData._id}`] = { ...currentManageTicket[`${ticketData._id}`], activeProfile: ticketData.activeProfile || '' };
  }
  return currentManageTicket;
};

const mappingTempTicketId = (currentManageTicket: any, ticketData: any) => {
  if (currentManageTicket[`${ticketData._id}`]) {
    currentManageTicket[`${ticketData._id}`].ticketId = ticketData.ticketId;
  } else {
    currentManageTicket[`${ticketData._id}`] = ticketData;
  }
  return currentManageTicket;
};

const removeTempTicketData = (currentManageTicket: any, ticketUUId: any) => {
  delete currentManageTicket[`${ticketUUId}`];
  return currentManageTicket;
};

const mappingTempTicketDataToObject = (currentManageTicket: any, listTicket: any, currentTicket: any) => {
  for (const ticketItem of listTicket) {
    if (ticketItem._id === currentTicket._id) {
      currentManageTicket[`${ticketItem._id}`] = {
        ...currentManageTicket[`${ticketItem._id}`],
        ticketId: ticketItem.ticketId,
        activeProfile: ticketItem.activeProfile,
      };
    } else if (ticketItem._id !== currentTicket._id) {
      currentManageTicket[`${ticketItem._id}`] = { ...currentManageTicket[`${ticketItem._id}`], ...ticketItem, activeProfile: ticketItem.activeProfile };
    }
  }

  const lisTicketItem = Object.keys(currentManageTicket);

  for (const tickeUUId of lisTicketItem) {
    if (!listTicket.find((item: any) => item._id === tickeUUId)) {
      delete currentManageTicket[tickeUUId];
    }
  }

  return currentManageTicket;
};

export const fetchListStaff = createAsyncThunk(
  'checkout/fetchListStaff',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllStaff(false);
    return response?.data?.data || [];
  },
);

export const fetchListService = createAsyncThunk(
  'checkout/fetchListService',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllProducts();
    return response?.data?.data || [];
  },
);

export const fetchListTempTicket = createAsyncThunk('checkout/fetchListTempTicket', async () => {
  const startDate = moment().startOf('day').unix();
  const endDate = moment().endOf('day').unix();
  const response = await checkoutService.getListTempTicket(startDate, endDate);
  return response?.data || [];
});

export const {
  updateStatusDevice,
  addManageTicketItem,
  updateManageTicket, // Update all
  updateManageTicketItem,
  updateManageTicketStatus,
  updateManageTicketItemId,
  removeManageTicketItem,
  updateCurrentTicket,
  updateListOfflineTicket,
  updateBtnPayment,
  updateDiscountAmount,

  updateModalEditCustomer,
  updateModalRewardPoint,
  updateModalEditDiscount,
  updateModalEditGratuity,
  updateModalCustomAmount,
  updateModalDeleteTicket,
  updateModalPayment,
  updateModalConfirmCashChange,
  updateModalPayroll,
  updateModalConfirmExCC,
  updateModalConfirmPrint,
  resetListEmptyTicket,
  updateIsLoadingCreateTempTicket,
  updateBaseTicketDate,
  updateExternalCreditExtraInfo,
  updateReasonLinkedCC,
  updateModalDivideGratuity,
  updateModalOtherPaymentMethods,
} = checkoutSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default checkoutSlice.reducer;
