export const TICKET_SOURCE = {
  POS: 'POS',
  CHECKIN: 'CHECKIN',
  APPOINTMENT: 'APPOINTMENT',
};

export enum CLEAN_FEE_TYPE {
  CASH = 'Cash',
  CHECK = 'Check',
}

export enum EDIT_TURN_TYPE {
  TOTAL_TURN = 'Total_turn',
  TURN_DETAILS = 'Turn_details',
  TOTAL_AMOUNT = 'Total_amount',
  NONE = 'None',
}

export enum ITEM_TYPE {
  SERVICE = 'service',
  PRODUCT = 'product',
}

export enum EEVENT_NAME {
  REMOVE = 'REMOVE',
}

export enum EACTION_TYPE {
  TEMP_TICKET = 'TEMP_TICKET',
  TURN_INFO = 'TURN_INFO',
}

export enum EPAYMENT_METHOD {
  CASH = 'cash',
  CREDIT = 'credit',
  EXTERNAL_CREDIT = 'externalCredit',
  GIFT_CARD = 'giftCard',
}

export enum EPAYMENT_METHOD_BE {
  EXTERNAL_CREDIT = 'External credit',
  GIFT_CARD = 'gift card',
  CREDIT = 'credit',
}

export enum EDISCOUNT_TYPE {
  TIP_INCLUDING = 'Tip Including Discount',
  TIP_EXCLUDING = 'Tip Excluding Discount',
}

export enum ETYPE_PAYMENT {
  CLOUD = 'cloud',
  USB = 'USB',
  PAX = 'pax',
}

export enum ETYPE_TAX {
  EXCLUDING_TAX = 'Excluding Tax',
  INCLUDING_TAX = 'Including Tax',
}

export enum TRANSACTION_STATE {
  CLOSED = 'CLOSED',
}

export enum TIP_SETTING_TYPE {
  DISABLED = 'disabled',
  ON_TABLE_SCREEN = 'onTabletScreen',
  ON_PRINTED_RECEIPT = 'onPrintedReceipt',
}

export enum GRATUITY_DIVIDES_BY {
  EVENLY = 'DivideAll',
  RATIO = 'Ratio',
  CUSTOM = 'Custom',
}

export enum DISCOUNT_TYPE {
  STAFF_ONLY = 'Staff Only',
  OWNER_ONLY = 'Owner Only',
  OWNER_AND_STAFF = 'Owner and Staff',
}

export const WS_ACTION_TYPE = {
  INSERT: 'INSERT',
  MODIFY: 'MODIFY',
  REMOVE_TICKET: 'REMOVE_TICKET',
  MODIFY_STATUS: 'MODIFY_STATUS',
  REMOVE: 'REMOVE',
  INSERT_CHECKOUT: 'INSERT_CHECKOUT',
  UPDATE_TURN_TOTAL: 'UPDATE_TURN_TOTAL',
  REFUND_TICKET: 'REFUND_TICKET',
  RESTORE_TICKET: 'RESTORE_TICKET',
};

export const WS_ACTION = {
  TEMP_TICKET_RESOLVER: 'temp-ticket-resolver',
  TURN_INFO_RESOLVER: 'turn-info-resolver',
};

export const LIST_ENV_ALLOW_LOCKING_TICKET = ['dev', 'staging'];
export const LIST_ENV_PREVENT_LOCKING_TICKET = ['prod'];

export const TICKET_STATUS = {
  AVAILABLE: 'DONE',
  DELETED: 'HIDE',
};

export const TRANSACTION_STATUS = {
  AVAILABLE: 'AVAILABLE',
  CREDIT_CARD: 'CREDIT_CARD',
};

export const STAFF_STATUS = {
  AVAILABLE: 'AVAILABLE',
  DELETED: 'DELETED',
};

export const ITEM_TYPES = {
  MERCHANT_TICKET_FIELDS: 'MERCHANT_TICKET_FIELDS',
  CUSTOMER_TICKET_FIELDS: 'CUSTOMER_TICKET_FIELDS',
  STAFF_CARDS: 'STAFF_CARDS',
  SERVICE_CARDS: 'SERVICE_CARDS',
  PRODUCT_CARDS: 'PRODUCT_CARDS',
  PAYROLL_REPORT: 'PAYROLL_REPORT',
  TECHNICIAN_REPORT: 'TECHNICIAN_REPORT',
  BOOKING_CARDS: 'BOOKING_CARDS',
};

export const PAYMENT_METHODS = {
  GIFT_CARD: 'gift card',
  CASH: 'cash',
  CREDIT: 'credit',
  EXTERNAL_CREDIT: 'external credit',
  OTHER: {
    ZELLE: 'zelle',
    VENMO: 'venmo',
    CHECK: 'check',
    BANK_TRANSFER: 'bankTransfer',
  },
};

export const LAYOUT_MODE = {
  PREVIEW: 'preview',
};

export const MERCHANT_TICKET_KEYS = {
  BUSINESS_NAME: 'Business Name',
  BUSINESS_ADDRESS: 'Business Address',
  BUSINESS_PHONE: 'Business Phone',
  CUSTOMER_RECEIPT: 'Customer Receipt',
  NEW_LINE: 'New Line',
  RECEIPT_NO: 'Receipt No',
  DATE: 'Date',
  TRANSACTION_NO: 'Transaction No',
  ENTRY_METHOD: 'Entry Method',
  CUSTOMER_NAME: 'Customer Name',
  PAYMENT_METHOD: 'Payment Method',
  LIST_SERVICE: 'List Service',
  SUB_TOTAL: 'Sub Total',
  TAX: 'Tax',
  TOTAL: 'Total',
  GRATUITY_AMOUNT: 'Gratuity Amount',
  SIGNATURE: 'Signature',
  NOTES: 'Notes',
  LAST_TOTAL: 'TOTAL',
};

export const MERCHANT_TICKET_FIELDS = [
  { id: 1, text: 'Business Name', key: MERCHANT_TICKET_KEYS.BUSINESS_NAME, display: true },
  { id: 2, text: 'Business Address', key: MERCHANT_TICKET_KEYS.BUSINESS_ADDRESS, display: true },
  { id: 3, text: 'Business Phone', key: MERCHANT_TICKET_KEYS.BUSINESS_PHONE, display: true },
  { id: 4, text: 'Customer Receipt', key: MERCHANT_TICKET_KEYS.CUSTOMER_RECEIPT, display: true },
  { id: 5, text: 'New Line', key: MERCHANT_TICKET_KEYS.NEW_LINE, display: true },
  { id: 6, text: 'Receipt No', key: MERCHANT_TICKET_KEYS.RECEIPT_NO, display: true },
  { id: 7, text: 'Date', key: MERCHANT_TICKET_KEYS.DATE, display: true },
  { id: 8, text: 'Transaction No', key: MERCHANT_TICKET_KEYS.TRANSACTION_NO, display: true },
  { id: 9, text: 'Entry Method', key: MERCHANT_TICKET_KEYS.ENTRY_METHOD, display: true },
  { id: 10, text: 'Customer Name', key: MERCHANT_TICKET_KEYS.CUSTOMER_NAME, display: true },
  { id: 11, text: 'Payment Method', key: MERCHANT_TICKET_KEYS.PAYMENT_METHOD, display: true },
  { id: 12, text: 'New Line', key: MERCHANT_TICKET_KEYS.NEW_LINE, display: true },
  { id: 13, text: 'List Service', key: MERCHANT_TICKET_KEYS.LIST_SERVICE, display: true },
  { id: 14, text: 'New Line', key: MERCHANT_TICKET_KEYS.NEW_LINE, display: true },
  { id: 15, text: 'Sub Total', key: MERCHANT_TICKET_KEYS.SUB_TOTAL, display: true },
  { id: 16, text: 'Tax', key: MERCHANT_TICKET_KEYS.TAX, display: true },
  { id: 17, text: 'New Line', key: MERCHANT_TICKET_KEYS.NEW_LINE, display: true },
  { id: 18, text: 'Total', key: MERCHANT_TICKET_KEYS.TOTAL, display: true },
  { id: 19, text: 'Gratuity Amount', key: MERCHANT_TICKET_KEYS.GRATUITY_AMOUNT, display: true },
  { id: 20, text: 'TOTAL', key: MERCHANT_TICKET_KEYS.LAST_TOTAL, display: true },
  { id: 21, text: 'Signature', key: MERCHANT_TICKET_KEYS.SIGNATURE, display: true },
  { id: 22, text: 'Notes', key: MERCHANT_TICKET_KEYS.NOTES, display: true },
];

export const CUSTOMER_TICKET_KEYS = {
  BUSINESS_NAME: 'Business Name',
  BUSINESS_ADDRESS: 'Business Address',
  BUSINESS_PHONE: 'Business Phone',
  CUSTOMER_RECEIPT: 'Customer Receipt',
  NEW_LINE: 'New Line',
  RECEIPT_NO: 'Receipt No',
  DATE: 'Date',
  TRANSACTION_NO: 'Transaction No',
  ENTRY_METHOD: 'Entry Method',
  CUSTOMER_NAME: 'Customer Name',
  PAYMENT_METHOD: 'Payment Method',
  LIST_SERVICE: 'List Service',
  SUB_TOTAL: 'Sub Total',
  TAX: 'Tax',
  TOTAL: 'Total',
  GRATUITY_AMOUNT: 'Gratuity Amount',
  SIGNATURE: 'Signature',
  NOTES: 'Notes',
  LAST_TOTAL: 'TOTAL',
};

export const CUSTOMER_TICKET_FIELDS = [
  { id: 1, text: 'Business Name', key: CUSTOMER_TICKET_KEYS.BUSINESS_NAME, display: true },
  { id: 2, text: 'Business Address', key: CUSTOMER_TICKET_KEYS.BUSINESS_ADDRESS, display: true },
  { id: 3, text: 'Business Phone', key: CUSTOMER_TICKET_KEYS.BUSINESS_PHONE, display: true },
  { id: 4, text: 'Customer Receipt', key: CUSTOMER_TICKET_KEYS.CUSTOMER_RECEIPT, display: true },
  { id: 5, text: 'New Line', key: CUSTOMER_TICKET_KEYS.NEW_LINE, display: true },
  { id: 6, text: 'Receipt No', key: CUSTOMER_TICKET_KEYS.RECEIPT_NO, display: true },
  { id: 7, text: 'Date', key: CUSTOMER_TICKET_KEYS.DATE, display: true },
  { id: 8, text: 'Transaction No', key: CUSTOMER_TICKET_KEYS.TRANSACTION_NO, display: true },
  { id: 9, text: 'Entry Method', key: CUSTOMER_TICKET_KEYS.ENTRY_METHOD, display: true },
  { id: 10, text: 'Customer Name', key: CUSTOMER_TICKET_KEYS.CUSTOMER_NAME, display: true },
  { id: 11, text: 'Payment Method', key: CUSTOMER_TICKET_KEYS.PAYMENT_METHOD, display: true },
  { id: 12, text: 'New Line', key: CUSTOMER_TICKET_KEYS.NEW_LINE, display: true },
  { id: 13, text: 'List Service', key: CUSTOMER_TICKET_KEYS.LIST_SERVICE, display: true },
  { id: 14, text: 'New Line', key: CUSTOMER_TICKET_KEYS.NEW_LINE, display: true },
  { id: 15, text: 'Sub Total', key: CUSTOMER_TICKET_KEYS.SUB_TOTAL, display: true },
  { id: 16, text: 'Tax', key: CUSTOMER_TICKET_KEYS.TAX, display: true },
  { id: 17, text: 'New Line', key: CUSTOMER_TICKET_KEYS.NEW_LINE, display: true },
  { id: 18, text: 'Total', key: CUSTOMER_TICKET_KEYS.TOTAL, display: true },
  { id: 19, text: 'Gratuity Amount', key: CUSTOMER_TICKET_KEYS.GRATUITY_AMOUNT, display: true },
  { id: 20, text: 'TOTAL', key: CUSTOMER_TICKET_KEYS.LAST_TOTAL, display: true },
  { id: 21, text: 'Signature', key: CUSTOMER_TICKET_KEYS.SIGNATURE, display: true },
];

export const PAYROLL_REPORT_KEYS = {
  BUSINESS_NAME: 'Business Name',
  BUSINESS_ADDRESS: 'Business Address',
  BUSINESS_PHONE: 'Business Phone',
  PAYROLL_REPORT: 'Payroll Report',
  NEW_LINE: 'New Line',
  STAFF: 'Staff',
  DATE: 'Date',
  TICKET: 'Ticket',
  LIST_SERVICE: 'List Service',
  DAY: 'Day',
  SERVICES: 'Services',
  SC: 'Sc',
  LIST_SERVICE_GRATUITY: 'List Service Gratuity',
  TOTAL_SERVICES: 'Total Services',
  DISCOUNT: 'Discount',
  HARD_SALARY: 'Hard Salary',
  COMMISSION: 'Commission',
  CLEAN_FEE: 'Clean Fee',
  TOTAL_GRATUITY: 'Total Gratuity',
  SUB_TOTAL: 'Sub Total',
  CHECK: 'Check',
  CASH: 'Cash',
  LAST_TOTAL: 'Total',
};

export const TECHNICIAN_REPORT_KEYS = {
  BUSINESS_NAME: 'Business Name',
  BUSINESS_ADDRESS: 'Business Address',
  BUSINESS_PHONE: 'Business Phone',
  TECHNICIAN_REPORT: 'Technician Report',
  NEW_LINE: 'New Line',
  STAFF: 'Staff',
  DATE: 'Date',
  PRINT_TIME: 'Print Time',
  LIST_SERVICE: 'List Service',
  TIME: 'Time',
  SERVICES: 'Services',
  LIST_SERVICE_PRICE: 'List Service Price',
  LIST_SERVICE_DISCOUNT: 'List Service Discount',
  LIST_SERVICE_GRATUITY: 'List Service Gratuity',
};

export const PAYROLL_REPORT_FIELDS = [
  { id: 1, text: 'Business Name', key: PAYROLL_REPORT_KEYS.BUSINESS_NAME, display: true },
  { id: 2, text: 'Business Address', key: PAYROLL_REPORT_KEYS.BUSINESS_ADDRESS, display: false },
  { id: 3, text: 'Business Phone', key: PAYROLL_REPORT_KEYS.BUSINESS_PHONE, display: true },
  { id: 4, text: 'Payroll Report', key: PAYROLL_REPORT_KEYS.PAYROLL_REPORT, display: true },
  { id: 5, text: 'New Line', key: PAYROLL_REPORT_KEYS.NEW_LINE, display: true },
  { id: 6, text: 'Staff', key: PAYROLL_REPORT_KEYS.STAFF, display: true },
  { id: 7, text: 'Date', key: PAYROLL_REPORT_KEYS.DATE, display: true },
  { id: 8, text: 'New Line', key: PAYROLL_REPORT_KEYS.NEW_LINE, display: true },
  { id: 9, text: 'Ticket', key: PAYROLL_REPORT_KEYS.TICKET, display: true },
  { id: 10, text: 'New Line', key: PAYROLL_REPORT_KEYS.NEW_LINE, display: true },
  {
    id: 12,
    text: 'List Service',
    key: PAYROLL_REPORT_KEYS.LIST_SERVICE,
    display: true,
    subItems: [
      {
        id: '16-1',
        text: 'Day',
        key: PAYROLL_REPORT_KEYS.DAY,
        display: true,
      },
      {
        id: '16-2',
        text: 'Services',
        key: PAYROLL_REPORT_KEYS.SERVICES,
        display: true,
      },
      {
        id: '16-3',
        text: 'Gratuity',
        key: PAYROLL_REPORT_KEYS.LIST_SERVICE_GRATUITY,
        display: true,
      },
    ],
  },
  { id: 13, text: 'New Line', key: PAYROLL_REPORT_KEYS.NEW_LINE, display: true },
  { id: 14, text: 'Total Services', key: PAYROLL_REPORT_KEYS.TOTAL_SERVICES, display: true },
  { id: 15, text: 'Hard Salary', key: PAYROLL_REPORT_KEYS.HARD_SALARY, display: false },
  { id: 16, text: 'Commission', key: PAYROLL_REPORT_KEYS.COMMISSION, display: true },
  { id: 17, text: 'Discount', key: PAYROLL_REPORT_KEYS.DISCOUNT, display: true },
  { id: 18, text: 'Supply Charge', key: PAYROLL_REPORT_KEYS.SC, display: true },
  { id: 19, text: 'Cleaning Fee', key: PAYROLL_REPORT_KEYS.CLEAN_FEE, display: true },
  { id: 20, text: 'New Line', key: PAYROLL_REPORT_KEYS.NEW_LINE, display: true },
  { id: 21, text: 'Sub Total', key: PAYROLL_REPORT_KEYS.SUB_TOTAL, display: true },
  { id: 22, text: 'Total Gratuity', key: PAYROLL_REPORT_KEYS.TOTAL_GRATUITY, display: true },
  { id: 23, text: 'New Line', key: PAYROLL_REPORT_KEYS.NEW_LINE, display: true },
  { id: 24, text: 'Total', key: PAYROLL_REPORT_KEYS.LAST_TOTAL, display: true },
  { id: 25, text: 'Check', key: PAYROLL_REPORT_KEYS.CHECK, display: true },
  { id: 26, text: 'Cash', key: PAYROLL_REPORT_KEYS.CASH, display: true },
];

export const TECHNICIAN_REPORT_FIELDS = [
  { id: 1, text: 'Business Name', key: TECHNICIAN_REPORT_KEYS.BUSINESS_NAME, display: true },
  { id: 2, text: 'Business Address', key: TECHNICIAN_REPORT_KEYS.BUSINESS_ADDRESS, display: true },
  { id: 3, text: 'Business Phone', key: TECHNICIAN_REPORT_KEYS.BUSINESS_PHONE, display: true },
  { id: 4, text: 'Technician Report', key: TECHNICIAN_REPORT_KEYS.TECHNICIAN_REPORT, display: true },
  { id: 5, text: 'New Line', key: TECHNICIAN_REPORT_KEYS.NEW_LINE, display: true },
  { id: 6, text: 'Staff', key: TECHNICIAN_REPORT_KEYS.STAFF, display: true },
  { id: 7, text: 'Date', key: TECHNICIAN_REPORT_KEYS.DATE, display: true },
  { id: 8, text: 'Print Time', key: TECHNICIAN_REPORT_KEYS.PRINT_TIME, display: true },
  { id: 9, text: 'New Line', key: TECHNICIAN_REPORT_KEYS.NEW_LINE, display: true },
  {
    id: 10,
    text: 'List Service',
    key: TECHNICIAN_REPORT_KEYS.LIST_SERVICE,
    display: true,
    subItems: [
      {
        id: '10-1',
        text: 'Time',
        key: TECHNICIAN_REPORT_KEYS.TIME,
        display: true,
      },
      {
        id: '10-2',
        text: 'Services',
        key: TECHNICIAN_REPORT_KEYS.SERVICES,
        display: true,
      },
      {
        id: '10-3',
        text: 'Price',
        key: TECHNICIAN_REPORT_KEYS.LIST_SERVICE_PRICE,
        display: true,
      },
      {
        id: '10-4',
        text: 'Disc',
        key: TECHNICIAN_REPORT_KEYS.LIST_SERVICE_DISCOUNT,
        display: true,
      },
      {
        id: '10-5',
        text: 'Grat',
        key: TECHNICIAN_REPORT_KEYS.LIST_SERVICE_GRATUITY,
        display: true,
      },
    ],
  },
];

export const NAV_SUB_HEADER = {
  GENERAL: 'general',
  REPORT_MANAGE: 'report-manage',
  MANAGEMENT: 'management',
  CAMPAIGN: 'campaign',
  TURN_TRACKER: 'turn-tracker',
  STORE_SETTINGS: 'store-settings',
  CONFIG_SETTINGS: 'config-settings',
  BOOKING: 'booking',
};

export const PREFIX_CURRENCY = {
  USD: '$',
  VND: 'đ',
  EUR: '€',
  CAD: 'CAD$',
};

export const GRATUITY_SERVICE = 'gratuity';
export const DISCOUNT_SERVICE = 'discount';

export const OFFER_VALUE_UNIT = {
  PERCENT: '%',
  CASH: '$',
};

export const RICH_REVIEW_RATING = {
  PERFECT: 'Perfect',
  OK: 'Ok',
  TERRIBLE: 'Terrible',
};

export const UPLOAD_IMAGE_PARAMS = {
  MIN_WIDTH: 180,
  HEIGHT: 300,
  WIDTH: 300,
  MIN: 1,
  MAX: 3,
  STEP: 0.1,
  ASPECT: 1,
};

export const DEFAULT_NOTIFICATION = {
  SMS_NOTIFICATION: 'Your feedback at {{Business}} helps us improve our service. Could you share your thoughts in a quick review? Thanks a lot',
};

export const DOWNLOAD_DRIVER_LINK = 'https://drive.google.com/drive/folders/12kEtOvPLtiRj-aKBtdUTRtqq2e2XY5ad';

export const LOGO_WIDTH = 70;

export const STAFF_AVATAR_PREFIX = 'avatar/staff';

export const FORMAT_DATE = 'MM/DD/YYYY';

export enum ETaxSettings {
  IncludingTax = 'Including Tax',
  ExcludingTax = 'Excluding Tax',
  IncludingCashDiscount = 'Tip Including Discount',
  ExcludingCashDiscount = 'Tip Excluding Discount',
}

export const LIST_CART_COLOR = [
  {
    color: '#0C53B7',
    background: 'rgba(24, 144, 255, 0.24)',
  },
  {
    color: '#7A0C2E',
    background: 'rgba(221, 76, 35, 0.24)',
  },
  {
    color: '#007B55',
    background: 'rgba(0, 171, 85, 0.24)',
  },
  {
    color: '#B78103',
    background: 'rgba(255, 193, 7, 0.24)',
  },
  {
    color: '#006400',
    background: '#00FF7F',
  },
  {
    color: '#CDAD00',
    background: '#EEDD82',
  },
  {
    color: '#8B3A62',
    background: '#FFB5C5',
  },
  {
    color: '#9932CC',
    background: '#FFBBFF',
  },
  {
    color: '#551A8B',
    background: '#D15FEE',
  },
  {
    color: '#8B7B8B',
    background: '#D8BFD8',
  },
];

export const CASH_DISCOUNT_TYPE = {
  CASH_DISCOUNT: 'cashDiscount',
  TRANSACTION_FEE: 'transactionFee',
};

export const TRANSACTION_FEE_TYPE = {
  AMOUNT: 'Amount',
  PERCENTAGE: 'Percentage',
};

export const DISCOUNT_BY = {
  PERCENT: 'DiscountByPercent',
  AMOUNT_CUSTOM: 'DiscountByAmount-Custom',
  AMOUNT_DIVIDE_ALL: 'DiscountByAmount-DivideAll',
  AMOUNT_DIVIDE_RATIO: 'DiscountByAmount-DivideRatio',
};

export const CALCULATE_CD_AND_TF_BASED_ON = {
  ORIGINAL_PRICE: 'originPrice',
  REDUCED_PRICE: 'reducedPrice',
};

export const CALCULATE_TAXES_BASED_ON = {
  ORIGINAL_PRICE: 'originPrice',
  REDUCED_PRICE: 'reducedPrice',
};

export const VIRTUAL_KEYBOARD_HEIGHT = 435;
export const LIMIT_RECORDS_IMPORT_PER_REQUEST = 300;
export const CREDIT_TRANSACTIONS = 'credit-transactions';
export const LIST_IN_SERVICE = 'list-in-service';
