import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// config
import { NAVBAR } from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from './nav-section';
//
import navConfig from './NavConfig';
import CollapseButton from './CollapseButton';
import MenuPopover from 'components/MenuPopover';
import ic_clock_in from 'assets/images/icons/ic-clock_in.svg';
import ic_cash_drawer from 'assets/images/icons/ic-cash_drawer.svg';
import ic_more_options from 'assets/images/icons/ic-more_options.svg';
import { useAppSelector } from 'redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onClickAttendance: PropTypes.func,
  onClickCashDrawer: PropTypes.func,
  isClosePopover: PropTypes.bool,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar, onClickAttendance, onClickCashDrawer, isClosePopover }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { checkoutSettings } = useAppSelector((state) => state.global);
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'quick-tools-popover' : undefined;

  const menuItems = [
    { text: 'Clock in / out', icon: ic_clock_in, display: checkoutSettings.staffCheckInAndOut, onClick: onClickAttendance },
    { text: 'Cash Drawer', icon: ic_cash_drawer, display: true, onClick: onClickCashDrawer },
  ];

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    isClosePopover && setAnchorEl(null);
  }, [isClosePopover]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        '& .simplebar-content-wrapper': { overflow: 'hidden !important' },
        background: isCollapse ? '#0B70E1' : '#ffffff',
        transition: 'background 1.5s',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <Logo /> */}
          <Box></Box>
          <Box sx={{ height: '56px' }}>{isDesktop && !isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}</Box>
        </Stack>
        <Box sx={{ height: '150px' }} />
        {/* <NavbarAccount isCollapse={isCollapse} /> */}
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      <Box position="absolute" bottom={60} left="50%" sx={{ transform: 'translate(-50%, 0)' }}>
        <img src={ic_more_options} alt="more" onClick={handleClick} style={{ cursor: 'pointer', transform: anchorEl ? 'rotate(45deg)' : 'rotate(0)' }} />
        <MenuPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          arrow="left-center"
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          sx={{
            p: 0,
            ml: 7,
            width: '220px',
            height: `${menuItems.filter((item) => item.display).length * 60}px`,
            cursor: 'pointer',
            '& .MuiMenuItem-root': {
              borderRadius: 0.75,
              boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
            },
          }}
        >
          {menuItems
            .filter((item) => item.display)
            .map((item, index) => (
              <React.Fragment key={index}>
                <Stack direction="row" py={2} pl={3} alignItems="center" gap="10px" onClick={item.onClick}>
                  <img src={item.icon} alt="icon" />
                  <Typography data-hj-allow>{item.text}</Typography>
                </Stack>
                {index !== menuItems.filter((item) => item.display).length - 1 && <Box borderBottom="1px dashed #ccc" />}
              </React.Fragment>
            ))}
        </MenuPopover>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          // variant="persistent"
          variant="permanent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              // position: "inherit",
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
