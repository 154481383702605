import { Box, TextField, Theme, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  iconPassword: {
    position: 'absolute',
    top: '50%',
    right: '0%',
    transform: 'translateY(-50%)',
  },
  numpadItem: {
    fontFamily: 'monospace',
    lineHeight: '1rem',
    fontSize: '27px',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '50%',
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    cursor: 'pointer',
    color: 'rgba(14, 115, 228, 1)',
    border: '1px solid rgba(14, 115, 228, 1)',
    margin: '0 auto',
    '& img': {
      height: '16px',
      width: '16px',
    },
    padding: '1.8rem',
  },
  numpadDel: {
    border: '1px solid rgba(242, 124, 30, 1)',
    '&:hover': {
      backgroundColor: 'rgba(221, 76, 35, 0.08)',
    },
  },
  numpadOK: {
    border: '1px solid rgba(76, 175, 80, 1)',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.08)',
    },
  },
  input: {
    fontSize: '32px',
    lineHeight: '24px',
    width: '20px',
    fontWeight: 400,
    color: 'rgba(11, 112, 225, 1)',
    textAlign: 'center',
    padding: 0,
    border: 'none',
    outline: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input': {
      padding: 0,
      fontWeight: 700,
      color: 'rgba(11, 112, 225, 1)',
      textAlign: 'center',
      borderBottom: '2px solid rgba(11, 112, 225, 1)',
      '&::placeholder': {
        fontFamily: 'Livvic !important',
      },
    },
  },
}));

const PasscodeWrapper = styled(Box)<any>(({ bgColor }: any) => ({
  width: '100%',
  padding: '16px',
  background: bgColor || '#FFFFFF',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  flex: '1 1 100%',
  position: 'relative',
}));

const VerificationInput = styled(TextField)(({ type }: any) => ({
  border: 'none',
  fontSize: '32px',
  lineHeight: '24px',
  width: '20px',
  fontWeight: 400,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& input': {
    fontFamily: type === 'password' && 'Password ',
    fontWeight: 700,
    '&::placeholder': {
      fontFamily: 'Livvic !important',
    },
  },
  input: { color: 'rgba(11, 112, 225, 1)', textAlign: 'center ', padding: 0 },
}));

export { useStyles, PasscodeWrapper, VerificationInput };
