import './index.css';

export default function index() {
  return (
    <div className="bounce-loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
