import { IVoucherDiscount } from 'common/interfaces';
import {
  CALCULATE_CD_AND_TF_BASED_ON,
  CALCULATE_TAXES_BASED_ON,
  CASH_DISCOUNT_TYPE,
  DISCOUNT_SERVICE,
  DISCOUNT_TYPE,
  GRATUITY_SERVICE,
  PAYMENT_METHODS,
} from './constants';
import { Service, Staff } from 'features/report/tickets/interface';
import { fNumberFixed3 } from './formatNumber';
import { IVisitSetting } from 'common/interfaces/checkout-settings';

interface IParams {
  cashDiscountPercent: number;
  transactionFeePercent: number;
  transactionFeeAmount: number;
  taxesPercent: number;
  gratuityAmount?: number;
  products: Array<{
    productId: string;
    price: number;
    removeTax: boolean;
  }>;
  settings: {
    enableCashDiscount: boolean;
    enableTransactionFee: boolean;
    gratuity: {
      includingCDOrTF: boolean;
      includingTaxes: boolean;
    };
    calCDAndTFBasedOn: string;
    taxes: {
      calBasedOn: string;
      paymentMethod: {
        cash: boolean;
        credit: boolean;
        externalCredit: boolean;
        giftCard: boolean;
      };
    };
  };
  rewardAmount: number;
  visitDiscountAmount: number;
  discountAmount: number;
  voucherDiscounts: IVoucherDiscount[];
  transactionList: Array<{ paymentMethod: string; total: number }>;
  paymentMethod: string;
}

export const checkoutCalculation = ({
  cashDiscountPercent,
  transactionFeePercent,
  transactionFeeAmount,
  taxesPercent,
  gratuityAmount,
  products,
  settings,
  rewardAmount,
  visitDiscountAmount,
  discountAmount,
  voucherDiscounts = [],
  transactionList = [],
  paymentMethod,
}: IParams) => {
  const services = products.filter((product) => !(product.productId.includes(GRATUITY_SERVICE) || product.productId.includes(DISCOUNT_SERVICE)));
  const gratuityServices = products.filter((product) => product.productId.includes(GRATUITY_SERVICE));

  let subTotal = 0;
  let totalDiscount = 0;
  let taxes = 0;
  let gratuity = 0;
  let rawGratuity = 0;
  let gratuityWithCashDiscount = 0;
  let total = 0;
  let totalPaid = 0;

  services.forEach((service) => {
    // Subtotal
    subTotal += Number(service.price);
    // Taxes
    taxes += service.removeTax ? 0 : Number(service.price) * (taxesPercent / 100);
  });

  // Calc totalDiscount
  totalDiscount = totalDiscount + rewardAmount + visitDiscountAmount + discountAmount;
  voucherDiscounts.forEach((voucherDiscount) => {
    totalDiscount += voucherDiscount.discountAmount;
  });

  gratuityServices.forEach((gratuityService) => {
    rawGratuity += Number(gratuityService.price);
    gratuity += settings.gratuity.includingTaxes
      ? Number(gratuityService.price) + Number(gratuityService.price) * (taxesPercent / 100)
      : Number(gratuityService.price);
    gratuityWithCashDiscount += settings.gratuity.includingTaxes
      ? Number(gratuityService.price) + Number(gratuityService.price) * (taxesPercent / 100) + Number(gratuityService.price) * (cashDiscountPercent / 100)
      : Number(gratuityService.price) + Number(gratuityService.price) * (cashDiscountPercent / 100);
  });

  if (settings.taxes.calBasedOn === CALCULATE_TAXES_BASED_ON.REDUCED_PRICE) taxes = taxes - (taxesPercent * totalDiscount) / 100;

  const transactionFee =
    transactionFeeAmount ||
    (subTotal -
      (settings.calCDAndTFBasedOn === CALCULATE_CD_AND_TF_BASED_ON.REDUCED_PRICE ? totalDiscount : 0) +
      (settings.gratuity.includingCDOrTF && gratuityAmount === undefined ? rawGratuity : 0)) *
      (transactionFeePercent / 100);

  const cashDiscount =
    (subTotal -
      (settings.calCDAndTFBasedOn === CALCULATE_CD_AND_TF_BASED_ON.REDUCED_PRICE ? totalDiscount : 0) +
      (settings.gratuity.includingCDOrTF && gratuityAmount === undefined ? rawGratuity : 0)) *
    (cashDiscountPercent / 100);

  const { totalPaidWithCash, totalPaidWithoutCash, totalPaidWithCashForGratuityAndTaxes, totalPaidWithoutCashForGratuityAndTaxes } = calTotalPaid(
    gratuity,
    taxes,
    taxesPercent,
    transactionList,
  );

  if (paymentMethod === PAYMENT_METHODS.CASH) {
    totalPaid =
      totalPaidWithCash +
      totalPaidWithoutCashForGratuityAndTaxes +
      fNumberFixed3((totalPaidWithoutCash - totalPaidWithoutCashForGratuityAndTaxes) / (cashDiscountPercent / 100 + 1), 2);
  } else {
    totalPaid =
      totalPaidWithoutCash + totalPaidWithCash + fNumberFixed3(((totalPaidWithCash - totalPaidWithCashForGratuityAndTaxes) * cashDiscountPercent) / 100, 2);
  }

  total = fNumberFixed3(
    subTotal -
      totalDiscount +
      taxes +
      (gratuityAmount === undefined ? gratuity : gratuityAmount) +
      (settings.enableCashDiscount ? cashDiscount : 0) +
      (settings.enableTransactionFee ? transactionFeeAmount || transactionFee : 0),
    2,
  );

  const remain = total - fNumberFixed3(totalPaid, 2);

  return {
    subTotal,
    subTotalWithCashDiscount: fNumberFixed3(subTotal + cashDiscount, 2),
    totalDiscount,
    totalDiscountWithCashDiscount: totalDiscount + (totalDiscount * cashDiscountPercent) / 100,
    taxes: fNumberFixed3(taxes, 2),
    rawGratuity: gratuityAmount !== undefined ? gratuityAmount : fNumberFixed3(gratuity, 2),
    gratuity:
      gratuityAmount !== undefined
        ? gratuityAmount
        : settings.gratuity.includingCDOrTF && settings.enableCashDiscount
        ? fNumberFixed3(gratuityWithCashDiscount, 2)
        : fNumberFixed3(gratuity, 2),
    total,
    transactionFee: fNumberFixed3(transactionFee, 2),
    cashDiscount: fNumberFixed3(cashDiscount, 2),
    remain: fNumberFixed3(remain, 2),
    totalPaid: fNumberFixed3(totalPaid, 2),
  };
};

export const calTotalPaid = (gratuity: number, taxes: number, taxesPercent: number, transactionList: Array<{ paymentMethod: string; total: number }>) => {
  let totalPaidWithoutCashForGratuityAndTaxes = 0;
  let totalPaidWithCashForGratuityAndTaxes = 0;
  let temp1: number, temp2: number;
  temp1 = temp2 = gratuity + taxes;

  const totalPaidWithoutCash = transactionList.reduce(
    (prevItem, nextItem) => Number(prevItem) + Number(nextItem.paymentMethod !== PAYMENT_METHODS.CASH ? nextItem.total : 0),
    0,
  );
  const totalPaidWithCash = transactionList.reduce(
    (prevItem, nextItem) => Number(prevItem) + Number(nextItem.paymentMethod === PAYMENT_METHODS.CASH ? nextItem.total : 0),
    0,
  );

  transactionList.forEach((transaction: { paymentMethod: string; total: number }) => {
    if (transaction.paymentMethod === PAYMENT_METHODS.CASH) {
      temp1 = Math.max(temp1 - transaction.total, 0);
      const temp = Math.min(transaction.total, temp2);
      temp2 -= temp;
      totalPaidWithCashForGratuityAndTaxes = totalPaidWithCashForGratuityAndTaxes + temp;
    } else {
      temp2 = Math.max(temp2 - transaction.total, 0);
      const temp = Math.min(transaction.total, temp1);
      temp1 -= temp;
      totalPaidWithoutCashForGratuityAndTaxes = totalPaidWithoutCashForGratuityAndTaxes + temp;
    }
  });

  return {
    totalPaidWithCash: fNumberFixed3(totalPaidWithCash, 2),
    totalPaidWithoutCash: fNumberFixed3(totalPaidWithoutCash, 2),
    totalPaidWithCashForGratuityAndTaxes: fNumberFixed3(totalPaidWithCashForGratuityAndTaxes, 2),
    totalPaidWithoutCashForGratuityAndTaxes: fNumberFixed3(totalPaidWithoutCashForGratuityAndTaxes, 2),
  };
};

export const cdOrTfCalculation = (
  paymentAmount: number,
  paymentMethod: 'cash' | 'credit' | 'external_credit' | 'gift_card',
  cashDiscountPercent: number,
  transactFeePercent: number,
  transactFeeAmount: number,
): { cashDiscountAmount: number; transactionFeeAmount: number } => {
  if (paymentMethod === 'cash' || paymentAmount < 0) {
    return {
      cashDiscountAmount: 0,
      transactionFeeAmount: 0,
    };
  }

  const cashDiscountAmount = paymentAmount * (cashDiscountPercent / 100);

  let transactionFeeAmount = 0;
  if (transactionFeeAmount) {
    transactionFeeAmount = transactFeeAmount;
  }
  if (transactFeePercent) {
    transactionFeeAmount = paymentAmount * (transactFeePercent / 100);
  }

  return {
    cashDiscountAmount,
    transactionFeeAmount,
  };
};

const calPriceOfServiceAfterDiscount = (service: Service) => {
  return service.servicePrice - (service.serviceDiscountAmount || (service.serviceDiscount * service.servicePrice) / 100);
};

export const calTotalNormalServiceOfStaff = (services: Array<Service>) => {
  return services.reduce(
    (total: number, service) =>
      total + (!(service.serviceId.includes(GRATUITY_SERVICE) || service.serviceId.includes(DISCOUNT_SERVICE)) ? calPriceOfServiceAfterDiscount(service) : 0),
    0,
  );
};

export const calTotalGratuityServiceOfStaff = (services: Array<Service>) => {
  return services.reduce((total: number, service) => total + (service.serviceId.includes(GRATUITY_SERVICE) ? service.servicePrice : 0), 0);
};

export const calTotalDiscountServiceOfStaff = (services: Array<Service>) => {
  return services.reduce(
    (total: number, service) => total + (service.serviceId.includes(DISCOUNT_SERVICE) ? service.servicePrice : service.serviceDiscountAmount),
    0,
  );
};

export const calTotalDiscountServiceOfStaffs = (staffs: Array<Staff>) => {
  return staffs.reduce((total: number, staff) => total + calTotalDiscountServiceOfStaff(staff.services), 0);
};

export const calCurrentPriceAtIndex = (services: Array<Service>, index: number) => {
  return services
    .slice(0, index)
    .reduce(
      (total: number, service) =>
        total +
        (!service.serviceId.includes(GRATUITY_SERVICE)
          ? service.serviceId.includes(DISCOUNT_SERVICE)
            ? 0 - service.servicePrice
            : calPriceOfServiceAfterDiscount(service)
          : 0),
      0,
    );
};

export const calTotalNormalServiceOfStaffsAfterDiscount = (staffs: Array<Staff>) => {
  return staffs.reduce(
    (totalOfStaffs: number, staff) =>
      totalOfStaffs +
      staff.services.reduce(
        (totalOfStaff: number, service) =>
          totalOfStaff +
          (service.serviceId.includes(DISCOUNT_SERVICE)
            ? 0 - service.servicePrice
            : !service.serviceId.includes(GRATUITY_SERVICE)
            ? service.servicePrice - service.serviceDiscountAmount
            : 0),
        0,
      ),
    0,
  );
};

export const reCalDiscountServicesOfStaff = (services: Array<Service>) => {
  return services.map((service, index: number) => {
    if (service.serviceId.includes(DISCOUNT_SERVICE)) {
      const currentPriceAtIndex = calCurrentPriceAtIndex(services, index);
      const serviceDiscountAmount = service.serviceDiscount
        ? fNumberFixed3((currentPriceAtIndex * Number(service.serviceDiscount)) / 100, 2)
        : service.serviceDiscountAmount;
      return {
        ...service,
        price: serviceDiscountAmount,
        serviceDiscountAmount,
      };
    } else return service;
  });
};

export const calDiscountOfDiscounter = (
  staffs: Array<Staff>,
  services: Array<Service>,
  discounts: Array<{ id: string; discounter: string; percent: number; amount: number }>,
  discounter: string,
) => {
  let totalDiscountOfStaff = 0;
  let discountAll = 0;
  let totalNormalServiceOfStaffsAfterDiscount = calTotalNormalServiceOfStaffsAfterDiscount(staffs);
  totalDiscountOfStaff += services.reduce(
    (total, service) =>
      total +
      (service.serviceId.includes(DISCOUNT_SERVICE)
        ? service.serviceDiscounter === DISCOUNT_TYPE.OWNER_AND_STAFF
          ? service.servicePrice / 2
          : service.serviceDiscounter === discounter
          ? service.servicePrice
          : 0
        : service.serviceId.includes(GRATUITY_SERVICE)
        ? 0
        : service.serviceDiscounter === DISCOUNT_TYPE.OWNER_AND_STAFF
        ? service.serviceDiscountAmount / 2
        : service.serviceDiscounter === discounter
        ? service.serviceDiscountAmount
        : 0),
    0,
  );

  (discounts || []).forEach((discount) => {
    const currentTotalServiceOfStaffs = totalNormalServiceOfStaffsAfterDiscount;
    const currentDiscountAmount = discount.amount || (discount.percent * currentTotalServiceOfStaffs) / 100;
    totalNormalServiceOfStaffsAfterDiscount -= currentDiscountAmount;
    discountAll +=
      discount.discounter === discounter ? currentDiscountAmount : discount.discounter === DISCOUNT_TYPE.OWNER_AND_STAFF ? currentDiscountAmount / 2 : 0;
  });

  return totalDiscountOfStaff + fNumberFixed3(discountAll / staffs.length, 2);
};

export function formatProductsToCalculate(
  products: Array<{ productId: string; price: string | number; serviceDiscount: string | number; serviceDiscounter: string }>,
) {
  return products.map((product) => ({
    serviceId: product.productId,
    servicePrice: Number(product.price),
    serviceDiscount: Number(product.serviceDiscount) || 0,
    serviceDiscounter: product.serviceDiscounter,
    serviceDiscountAmount: (Number(product.price) * Number(product.serviceDiscount)) / 100 || 0,
  }));
}

export function formatStaffsToCalculate(
  staffs: Array<{ listProduct: Array<{ productId: string; price: string | number; serviceDiscount: string | number; serviceDiscounter: string }> }>,
) {
  return staffs.map((staff) => ({ ...staff, services: formatProductsToCalculate(staff.listProduct) }));
}

export const reCalculateDiscountStaff = (manageCart: any, discounts: Array<any>) => {
  const result: any = {};
  Object.keys(manageCart).forEach((key) => {
    result[key] = {
      ...manageCart[key],
      staffDiscount: fNumberFixed3(
        calDiscountOfDiscounter(
          formatStaffsToCalculate(Object.values(manageCart)) as any,
          formatProductsToCalculate(manageCart[key].listProduct) as any,
          discounts,
          DISCOUNT_TYPE.STAFF_ONLY,
        ),
        2,
      ),

      ownerDiscount: fNumberFixed3(
        calDiscountOfDiscounter(
          formatStaffsToCalculate(Object.values(manageCart)) as any,
          formatProductsToCalculate(manageCart[key].listProduct) as any,
          discounts,
          DISCOUNT_TYPE.OWNER_ONLY,
        ),
        2,
      ),
    };
  });

  return result;
};

export const addGratuityForStaffs = (manageCart: any, gratuityStaffs: Array<{ staffId: string; staffGratuity: number }>) => {
  const result: any = {};
  Object.keys(manageCart).forEach((key) => {
    result[key] = {
      ...manageCart[key],
      staffGratuity: fNumberFixed3(gratuityStaffs.find((staff) => staff.staffId === manageCart[key].staffId)?.staffGratuity || 0, 2),
    };
  });

  return result;
};

export const calcVisitDiscountAmount = (customerVisit: number, visit: IVisitSetting, subTotal: number, discountAmount: number) => {
  if (customerVisit === 0) {
    return 0;
  }

  const isVisitDiscount = customerVisit % visit?.number === 0;

  if (!isVisitDiscount) {
    return 0;
  }

  let visitDiscountAmount = 0;
  if (visit?.percent !== 0) visitDiscountAmount = (subTotal - discountAmount) * (visit?.percent / 100);

  if (visit?.amount !== 0) {
    visitDiscountAmount = visit?.amount;
  }

  return visitDiscountAmount;
};

export const formatCheckoutParams = (params: any) => {
  const { ticketId, ticketInfo, totalData, taxesCalBasedOn, payments, actualCC, reasonLinkedExCC, rewardAmount, visitDiscount, voucherDiscounts } = params;
  return {
    ticketId: ticketId,
    customer: {
      customerEmail: ticketInfo.customerEmail,
      customerId: ticketInfo.customerId,
      customerName: ticketInfo.customerName,
      customerPhone: ticketInfo.customerPhone,
    },
    staffs: ticketInfo.staffList.map((staff: { staffId: string; services: Array<any> }) => {
      return {
        ...staff,
        services: staff.services
          .filter((service) => !service.serviceId.includes(DISCOUNT_SERVICE))
          .map((service) => {
            const { serviceQuantity, cashDiscountPrice, ...rest } = service;
            return { ...rest, removeTax: service.removeTax || false, serviceDiscountAmount: (service.servicePrice * service.serviceDiscount) / 100 };
          }),
        gratuity: ticketInfo.gratuityStaffs.find((gratuityStaff: { staffId: string }) => gratuityStaff.staffId === staff.staffId)?.staffGratuity || 0,
      };
    }),
    cashDiscountAndTransactionFee: {
      ...ticketInfo.cashDiscountAndTransactionFee,
    },
    subTotal: totalData.subTotal,
    totalDiscount: totalData.totalDiscount,
    totalDiscountWithCashDiscount: undefined,
    taxesPercent: ticketInfo.taxes,
    taxesAmount: totalData.taxes,
    taxesCalBasedOn: taxesCalBasedOn,
    gratuity: ticketInfo.gratuity,
    total: fNumberFixed3(
      payments.reduce((total: number, payment: { total: number }) => total + payment.total, 0),
      2,
    ),
    createdDate: ticketInfo.createdDate,
    payments: payments.map((payment: { transactionNo: string | number; giftCardIds: Array<string>; giftCardTotals: Array<number> }) => ({
      ...payment,
      cashDiscount: undefined,
      transactionFee: undefined,
      giftCardId: payment?.giftCardIds ? payment.giftCardIds[0] : undefined,
      giftCardIds: undefined,
      giftCardTotal: payment?.giftCardTotals ? payment.giftCardTotals[0] : undefined,
      giftCardTotals: undefined,
      transactionNo: payment.transactionNo.toString(),
    })),
    actualCC: actualCC,
    reasonLinkedExCC: reasonLinkedExCC,
    rewardAmount: rewardAmount,
    visitDiscount: visitDiscount,
    voucherDiscounts: voucherDiscounts,
    cashDiscount: ticketInfo.cashDiscountAndTransactionFee.chosen === CASH_DISCOUNT_TYPE.CASH_DISCOUNT ? totalData.cashDiscount : 0,
    transactionFee: ticketInfo.cashDiscountAndTransactionFee.chosen === CASH_DISCOUNT_TYPE.TRANSACTION_FEE ? totalData.transactionFee : 0,
  };
};
