// routes
import { PATH_DASHBOARD } from './routes/paths';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.dashboard; // as '/dashboard/app'

// LAYOUT
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 66,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 70,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 70,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 30,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const STAFF_ROLE = {
  Owner: 'Owner',
  Manager: 'Manager',
  Technician: 'Technician',
};

export const DISPLAY_STAFF_SERVICE = {
  0: '18px',
  5: '22.5px',
  10: '27px',
};

export const DISPLAY_CATEGORY = {
  0: '24px',
  5: '30px',
  10: '36px',
};

export const PRINT_TICKET_DATA = {
  isTmp: true, // chua sale
  pageWidth: '320',

  businessName: '',
  businessAddress: {
    address: '',
    address2: '',
    state: '',
    city: '',
    zipCode: '',
  },
  businessState: '',
  businessPhone: '',

  receiptNo: '',
  createdDate: '',

  customerName: '',
  paymentMethod: '', // upper case

  discount: '0',
  subTotal: '0',
  tax: '0',
  total: '0',
};

export const NOTIFICATION = {
  //success
  printCheckoutSuccess: 'Print ticket success.',

  //error
  deviceIsNotReady: 'Device is not ready.',
  printOnDeviceIsNotSuccess: 'Print on device is not success.',
};

export const DISPLAY_FONT_SIZE = {
  staff: {
    0: 18,
    5: 22.5,
    10: 27,
  },
  service: {
    0: 18,
    5: 22.5,
    10: 27,
  },
  category: {
    0: 24,
    5: 30,
    10: 36,
  },
};

export const LIST_PAYMENT_METHOD = [
  {
    key: 'cash',
    value: 'Cash',
  },
  {
    key: 'credit',
    value: 'Credit Card',
  },
  {
    key: 'external_credit_card',
    value: 'External Credit Card',
  },
  {
    key: 'gift_card',
    value: 'Gift Card',
  },
];
