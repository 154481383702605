// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';
import { updateCheckoutPasscodeModal } from 'redux/slices/global';
import { NAV_SUB_HEADER } from 'utils/constants';

// ----------------------------------------------------------------------
const getIconMenu = (name: string) => <SvgIconStyle src={`/icons/menu/${name}.svg`} />;

const ICONS = {
  home: getIconMenu('menu-ic_home'),
  transaction: getIconMenu('menu-ic_transaction'),
  ticket: getIconMenu('menu-ic_ticket'),
  payroll: getIconMenu('menu-ic_payroll'),
  settings: getIconMenu('menu-ic_settings'),
  income: getIconMenu('menu-ic_incomeReport'),
  report: getIconMenu('menu-ic_report'),
  turnTracker: getIconMenu('menu-ic_turnTracker'),
  campaign: getIconMenu('menu-ic_campaign'),
  storeSettings: getIconMenu('menu-ic_storeSettings'),
  booking: getIconMenu('menu-ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: NAV_SUB_HEADER.GENERAL,
    items: [
      {
        title: 'Checkout',
        path: PATH_DASHBOARD.general.checkout,
        icon: ICONS.home,
        hideChildren: true,
        searchInput: {
          haveSearch: true,
          searchText: '',
          placeholder: 'Search name or ID',
        },
        children: [{ title: 'Checkout Details', path: PATH_DASHBOARD.general.checkoutDetails }],
      },
      // {
      //   title: 'Transactions',
      //   path: PATH_DASHBOARD.general.transactions,
      //   icon: ICONS.transaction,
      //   hideChildren: true,
      //   searchInput: {
      //     haveSearch: true,
      //     searchText: '',
      //     placeholder: 'Search name or ID',
      //   },
      //   children: [{ title: 'Transaction Details', path: PATH_DASHBOARD.general.transactionDetail }],
      // },
      // {
      //   title: 'Tickets',
      //   path: PATH_DASHBOARD.general.tickets,
      //   icon: ICONS.ticket,
      //   hideChildren: true,
      //   children: [
      //     { title: 'Ticket Details', path: PATH_DASHBOARD.general.ticketDetail },
      //     { title: 'Edit Ticket', path: PATH_DASHBOARD.general.ticketEdit },
      //   ],
      // },
      // {
      //   title: 'Payrolls',
      //   path: PATH_DASHBOARD.general.payrolls,
      //   icon: ICONS.payroll,
      //   hideChildren: true,
      //   children: [{ title: 'Payroll Of ...', path: PATH_DASHBOARD.general.payrollsByStaff }],
      // },
      // {
      //   title: 'Income Report',
      //   path: PATH_DASHBOARD.general.income,
      //   icon: ICONS.income,
      //   hideChildren: false,
      // },
    ],
    onClickAction: updateCheckoutPasscodeModal({
      open: false,
      action: null,
      actionType: null,
    }),
  },
  // {
  //   subheader: NAV_SUB_HEADER.BOOKING,
  //   items: [
  //     {
  //       title: 'Booking',
  //       path: PATH_DASHBOARD.booking.root,
  //       icon: ICONS.booking,
  //     },
  //   ],
  // },
  {
    subheader: NAV_SUB_HEADER.TURN_TRACKER,
    items: [
      {
        title: 'Turn Tracker',
        path: PATH_DASHBOARD.turn_tracker.root,
        icon: ICONS.turnTracker,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: NAV_SUB_HEADER.REPORT_MANAGE,
    items: [
      // MANAGEMENT : SERVICES
      {
        title: 'Report',
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.report,
        hideChildren: true,
        searchInput: {
          haveSearch: true,
          searchText: '',
          placeholder: 'Search',
        },
        children: [
          { title: 'Payroll', path: PATH_DASHBOARD.report.payroll },
          { title: 'Payroll Of ...', path: PATH_DASHBOARD.report.payrollsByStaff, backPath: PATH_DASHBOARD.report.payroll },

          { title: 'Transactions', path: PATH_DASHBOARD.report.transaction },
          { title: 'Transaction Details', path: PATH_DASHBOARD.report.transactionDetail, backPath: PATH_DASHBOARD.report.transaction },

          { title: 'Tickets', path: PATH_DASHBOARD.report.ticket },
          { title: 'Ticket Details', path: PATH_DASHBOARD.report.ticketDetail, backPath: PATH_DASHBOARD.report.ticket },
          { title: 'Edit Ticket ', path: PATH_DASHBOARD.report.ticketEdit, backPath: PATH_DASHBOARD.report.ticket },

          { title: 'Sales Report', path: PATH_DASHBOARD.report.income },

          { title: 'Customers', path: PATH_DASHBOARD.report.customer },
        ],
      },
    ],
  },
  // {
  //   subheader: NAV_SUB_HEADER.CAMPAIGN,
  //   items: [
  //     {
  //       title: 'Campaign',
  //       path: PATH_DASHBOARD.campaign.root,
  //       icon: ICONS.campaign,
  //       hideChildren: true,
  //       searchInput: {
  //         haveSearch: true,
  //         searchText: '',
  //         placeholder: 'Search setting',
  //       },
  //       children: [
  //         { title: 'Campaign Details', path: PATH_DASHBOARD.campaign.detail, backPath: PATH_DASHBOARD.campaign.list },
  //         {
  //           title: 'List Campaign',
  //           path: PATH_DASHBOARD.campaign.list,
  //           backPath: PATH_DASHBOARD.campaign.root,
  //         },
  //         {
  //           title: 'Reminder',
  //           path: PATH_DASHBOARD.campaign.reminder,
  //           backPath: PATH_DASHBOARD.campaign.root,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    subheader: NAV_SUB_HEADER.STORE_SETTINGS,
    items: [
      {
        title: 'Store Settings',
        path: PATH_DASHBOARD.store_settings.root,
        icon: ICONS.storeSettings,
        hideChildren: true,
        searchInput: {
          haveSearch: true,
          searchText: '',
          placeholder: 'Search',
        },
        children: [
          { title: 'Business Details', path: PATH_DASHBOARD.store_settings.details },
          { title: 'Services', path: PATH_DASHBOARD.store_settings.services },
          { title: 'Customer Settings', path: PATH_DASHBOARD.store_settings.customer_setting },
          { title: 'Gift Card', path: PATH_DASHBOARD.store_settings.gift_card },
          { title: 'Discount Voucher', path: PATH_DASHBOARD.store_settings.discount_promotion },
          { title: 'Staff Management', path: PATH_DASHBOARD.store_settings.staffs },
          { title: 'Staff’s profile', path: PATH_DASHBOARD.store_settings.staffs_info, backPath: PATH_DASHBOARD.store_settings.staffs },
          { title: 'Roles and permissions', path: PATH_DASHBOARD.store_settings.staffs_role_permission, backPath: PATH_DASHBOARD.store_settings.staffs },
          { title: 'Clock in/out', path: PATH_DASHBOARD.store_settings.staffs_clock_in_and_out, backPath: PATH_DASHBOARD.store_settings.staffs },
          { title: 'Gift Card History', path: PATH_DASHBOARD.store_settings.gift_card_history, backPath: PATH_DASHBOARD.store_settings.gift_card },
        ],
      },
    ],
  },
  {
    subheader: NAV_SUB_HEADER.CONFIG_SETTINGS,
    items: [
      {
        title: 'Config',
        path: PATH_DASHBOARD.config_settings.root,
        icon: ICONS.settings,
        hideChildren: true,
        searchInput: {
          haveSearch: true,
          searchText: '',
          placeholder: 'Search',
        },
        children: [
          { title: 'Payment Method', path: PATH_DASHBOARD.config_settings.payment },
          { title: 'Checkout Settings', path: PATH_DASHBOARD.config_settings.checkout_setting },
          { title: 'Checkin Settings', path: PATH_DASHBOARD.config_settings.checkin_setting },
          { title: 'Display Settings', path: PATH_DASHBOARD.config_settings.display_setting },
          { title: 'Admin Settings', path: PATH_DASHBOARD.config_settings.admin_setting },
          { title: 'Printer', path: PATH_DASHBOARD.config_settings.printer },
          { title: 'Custom Receipt', path: PATH_DASHBOARD.config_settings.printer_custom_receipt, backPath: PATH_DASHBOARD.config_settings.printer },
          { title: 'Printer Config', path: PATH_DASHBOARD.config_settings.printer_catalogue, backPath: PATH_DASHBOARD.config_settings.printer },
          { title: 'Turn Tracker', path: PATH_DASHBOARD.config_settings.turn_tracker },
          { title: 'Notification', path: PATH_DASHBOARD.config_settings.notification },
          { title: 'Review', path: PATH_DASHBOARD.config_settings.review },
          { title: 'Report Dev', path: PATH_DASHBOARD.config_settings.report_dev },
        ],
      },
    ],
  },
];

export default navConfig;
