import axiosInstance from './axiosInstance';
import { BASE_URL } from 'services/appDomain';

// ========== SERVICE SETUP ==========
const loggingError = (data: any) => {
  return axiosInstance.post(`${BASE_URL}/report/logging-error`, { ...data });
};

const utilsService = {
  loggingError,
};

export default utilsService;
