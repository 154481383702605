// Actions

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

// Define a type for the slice state
interface PayrollState {
  isLoading: boolean;
  error: null;
  listPayroll: Array<any>;
  filter: {
    startDate: number;
    endDate: number;
    search: string;
  };
  meta: {
    limit: number;
    listKey: Array<any>;
  };
}

// Define the initial state using that type
const initialState: PayrollState = {
  isLoading: false,
  error: null,
  listPayroll: [],
  filter: {
    startDate: moment().toDate().getTime(),
    endDate: moment().toDate().getTime(),
    search: '',
  },
  meta: {
    limit: 20,
    listKey: [],
  },
};

export const payrollSlice = createSlice({
  name: 'payroll',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`

    setFilterPayroll: (state: PayrollState, action: PayloadAction<any>) => {
      state.filter = { ...action.payload };
    },
  },
});

export const { setFilterPayroll } = payrollSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default payrollSlice.reducer;
