import { LOCALSTORAGE_KEY } from 'configs';
import { debounce, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  fetchBusinessInfo,
  fetchCheckoutSettings,
  fetchListService,
  fetchListStaff,
  fetchListStaffWithWorkingHours,
  fetchPaymentSettings,
  updateProfileId,
} from 'redux/slices/global';
import { useAppDispatch } from 'redux/store';
import { MAINDOMAIN_LANDING } from 'services/appDomain';
import sessionService from 'services/session.service';
import { v4 as uuidv4 } from 'uuid';
import { fetchListTempTicket } from 'redux/slices/checkout';

const useGuard = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const userInfoFromUrl = searchParams.get('userInfo');
  const roleUser = searchParams.get('role');

  useEffect(() => {
    if (userInfoFromUrl) {
      const { tenantId } = JSON.parse(userInfoFromUrl) || {};

      const profileId = uuidv4();
      if (roleUser) {
        // redirect from internal page
        sessionStorage.setItem(LOCALSTORAGE_KEY.ACTIVE_LOGIN, tenantId);
        localStorage.setItem(`${LOCALSTORAGE_KEY.USER_INFO}_${tenantId}`, userInfoFromUrl);
        // localStorage.setItem(`${LOCALSTORAGE_KEY.PROFILE_ID}_${tenantId}`, profileId);
      } else {
        // redirect from landing page
        sessionStorage.setItem(LOCALSTORAGE_KEY.ACTIVE_LOGIN, '');
        localStorage.setItem(`${LOCALSTORAGE_KEY.USER_INFO}`, userInfoFromUrl);
        // localStorage.setItem(`${LOCALSTORAGE_KEY.PROFILE_ID}`, profileId);
      }
      dispatch(updateProfileId(profileId));

      setIsAuth(true);
      navigate(`${location.pathname}`);
    } else {
      const activeLogin = sessionStorage.getItem(LOCALSTORAGE_KEY.ACTIVE_LOGIN) || '';
      if (activeLogin) {
        // page logined by support
        const _userInfo = localStorage.getItem(`${LOCALSTORAGE_KEY.USER_INFO}_${activeLogin}`) || '{}';
        if (isEmpty(JSON.parse(_userInfo))) {
          setIsAuth(false);
          sessionService.clearSession();
          window.location.href = `${MAINDOMAIN_LANDING}/login?from=${window.location.origin}`;
        } else {
          setIsAuth(true);
          getBusinessInfo();
          // getTempTicketInfo();
          return;
        }
      } else {
        // page logined by tenant admin
        const _userInfo = localStorage.getItem(`${LOCALSTORAGE_KEY.USER_INFO}`) || '{}';
        if (isEmpty(JSON.parse(_userInfo))) {
          setIsAuth(false);
          sessionService.clearSession();
          window.location.href = `${MAINDOMAIN_LANDING}/login?from=${window.location.origin}`;
        } else {
          setIsAuth(true);
          getBusinessInfo();
          // getTempTicketInfo();
          return;
        }
      }
    }
  }, [userInfoFromUrl?.toString()]);

  const getBusinessInfo = useCallback(
    debounce(async () => {
      dispatch(fetchBusinessInfo());
      dispatch(fetchPaymentSettings());
      dispatch(fetchCheckoutSettings());
      dispatch(fetchListStaff());
      dispatch(fetchListStaffWithWorkingHours());
      dispatch(fetchListService());
      dispatch(fetchListTempTicket());
    }, 200),
    [],
  );
  return isAuth;
};

export default useGuard;
