import { styled, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const VerificationInput = styled(TextField)(({ autoFocus }: any) => ({
  border: 'none',
  fontSize: '32px',
  lineHeight: '24px',
  width: '20px',
  fontWeight: 400,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& input': {
    fontFamily: !autoFocus && 'Password ',
    fontWeight: 700,
    '&::placeholder': {
      fontFamily: 'Livvic !important',
    },
  },

  input: { color: 'rgba(11, 112, 225, 1)', textAlign: 'center ', padding: 0 },
}));

const useStyles = makeStyles(() => ({
  numpadItem: {
    fontFamily: 'monospace',
    padding: '2.5rem',
    lineHeight: '1rem',
    fontSize: '27px',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '50%',
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    cursor: 'pointer',
    color: 'rgba(14, 115, 228, 1)',
    border: '1px solid rgba(14, 115, 228, 1)',
    margin: '0 auto',
    '& img': {
      height: '16px',
      width: '16px',
    },
    '@media screen and (max-width: 1440px)': {
      padding: '1.8rem',
    },
  },
  numpadDel: {
    border: '1px solid rgba(242, 124, 30, 1)',
    '&:hover': {
      backgroundColor: 'rgba(221, 76, 35, 0.08)',
    },
  },
  numpadOK: {
    border: '1px solid rgba(76, 175, 80, 1)',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.08)',
    },
  },
  heading: {
    fontSize: '54px',
    fontWeight: 600,
    color: '#061340',
    lineHeight: '63px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  subHeading: {
    fontSize: '1rem',
    lineHeight: '24px',
    fontWeight: 400,
    color: '#637381',
    marginBottom: '2rem',
  },
  iconPassword: {
    position: 'absolute',
    top: '50%',
    right: '0%',
    transform: 'translateY(-50%)',
  },
  btnBack: {
    borderRadius: '4px',
    border: '1px solid rgba(242, 124, 30, 1)',
    color: 'rgba(221, 76, 35, 1)',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));

export default useStyles;
