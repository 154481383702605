import CloseIcon from '@mui/icons-material/Clear';
import LockIcon from '@mui/icons-material/Https';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icBooking from 'assets/images/icons/ic_checkin.svg';
import MenuPopover from 'components/MenuPopover';
import useCheckin from 'hooks/useCheckin';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState, useAppSelector } from 'redux/store';
import { LIST_ENV_ALLOW_LOCKING_TICKET, TICKET_SOURCE, WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { MODIFY_STATUS } = WS_ACTION_TYPE;

export const useStyles = makeStyles((theme: any) => ({
  button: {
    background: theme.palette.background.main,
    color: theme.palette.primary.text,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '30px',
    height: '42px',
    width: '100px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: -10,
      right: 0,
    },
  },
  ticketItem: {
    padding: '31px 30px',
    borderRadius: '8px',
    border: '1px solid rgba(145, 158, 171, 0.24)',
    boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.16)',
  },
  text: {
    textAlign: 'center',
    width: '92%',
    cursor: 'pointer',
    '&__name': {
      fontWeight: 500,
      fontSize: '27px',
      lineHeight: '18px',
      color: '#061340',
      paddingBottom: '16px',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&__phone': {
      fontWeight: 400,
      fontSize: '27px',
      lineHeight: '22px',
      color: '#7A7A7A',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export default function BookingPopover() {
  const classes: any = useStyles();
  const navigate = useNavigate();
  const { handleCompleteTicketCheckin } = useCheckin();

  // GLOBAL STATE
  const { sendJsonMessage, profileId } = useAppSelector((state: RootState) => state.global);
  const { currentTicket, manageTicket } = useAppSelector((state: RootState) => state.checkout);

  // COMPONENT STATE
  const [open, setOpen] = useState(null);
  const [listBooking, setListBooking] = useState<Array<any>>([]);

  // HOOKS
  useEffect(() => {
    // const _listBooking = Object.values(manageTicket).filter((ticket: any) => ticket?.isTicketBooking === true);
    const _listBooking = Object.values(manageTicket).filter((ticket: any) => ticket?.source === TICKET_SOURCE.APPOINTMENT);
    setListBooking(_listBooking);
  }, [JSON.stringify(manageTicket)]);

  return (
    <>
      <Button onClick={(event: any) => setOpen(event.currentTarget)} disableFocusRipple disableRipple disableElevation className={classes.button}>
        {`Booking`}
        {listBooking.length === 0 ? null : <img src={icBooking} alt="ic checkin"></img>}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          mt: 1.5,
          width: listBooking.length === 0 ? '500px' : '1080px',
        }}
      >
        <Grid
          container
          spacing={1.25}
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: '532px !important',
            overflowY: 'scroll',
          }}
        >
          {listBooking.length === 0 ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                height: '200px',
              }}
            >
              <Typography
                data-hj-allow
                sx={{
                  fontWeight: 600,
                  fontSize: '24px',
                }}
              >
                Empty tickets from booking
              </Typography>
            </Stack>
          ) : (
            listBooking.map((ticketItem: any) => {
              const { customerName, customerPhone } = ticketItem;
              const isUsingByOtherProfile = ticketItem?.activeProfile && ticketItem?.activeProfile !== profileId;
              return (
                <Grid item xs={6} md={4}>
                  <Stack className={classes.ticketItem} direction="row" justifyContent="space-between" alignItems="center">
                    <Box
                      className={classes.text}
                      onClick={() => {
                        if (LIST_ENV_ALLOW_LOCKING_TICKET.includes(process.env.REACT_APP_ENV || 'dev')) {
                          if (ticketItem?.activeProfile && ticketItem?.activeProfile !== profileId) {
                            return toast.warning('This ticket is being edited by another profile!');
                          }
                        }

                        if (currentTicket?._id) {
                          const updateSocketParams = {
                            identify: 'updateTicketLockingStatus-4',
                            eventName: MODIFY_STATUS,
                            data: {
                              tenantId: currentTicket.tenantId,
                              _id: currentTicket._id,
                              ticketId: currentTicket.ticketId,
                              activeProfile: '',
                            },
                          };
                          sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
                        }

                        navigate(`/checkout/${ticketItem?._id || ''}`);
                        setOpen(null);
                      }}
                    >
                      <Typography data-hj-allow className={`${classes.text}__name`}>
                        {customerName}
                      </Typography>
                      <Typography data-hj-allow className={`${classes.text}__phone`}>
                        {`${customerPhone}`.replace(/^(\d{6})/, 'xxxxxx')}
                      </Typography>
                    </Box>

                    {isUsingByOtherProfile ? (
                      <LockIcon />
                    ) : (
                      <IconButton
                        onClick={async () => {
                          await handleCompleteTicketCheckin(ticketItem, 'delete');
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Stack>
                </Grid>
              );
            })
          )}
        </Grid>
      </MenuPopover>
    </>
  );
}
