import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import { LOGO_WIDTH, WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { MODIFY_STATUS } = WS_ACTION_TYPE;

// ----------------------------------------------------------------------

type Props = {
  disabledLink?: boolean;
  sx?: any;
};

export default function Logo({ disabledLink = false, sx }: Props) {
  const { sendJsonMessage } = useAppSelector((state: RootState) => state.global);
  const { currentTicket } = useAppSelector((state: RootState) => state.checkout);
  const logo = (
    <Box sx={{ width: 42, height: 42, ...sx }}>
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="42" height="42" rx="21" fill="url(#paint0_linear_842_16869)" />
        <g clipPath="url(#clip0_842_16869)">
          <path
            d="M31.9695 30.8706V25.6168C31.9695 23.7812 30.9067 22.2049 29.3888 21.522C31.3678 20.3888 32.7095 18.193 32.7095 15.6699C32.7095 11.9864 29.85 9.00037 26.3227 9.00037H14.0967V33.0005H18.8938V23.5752H24.7479C26.091 23.5752 27.1786 24.7117 27.1786 26.1135V27.4764C27.1786 29.3348 27.3768 31.1874 27.7698 33H32.9998V32.3988C32.3215 32.191 31.9695 31.711 31.9695 30.8706Z"
            fill="#DD4D26"
          />
          <path
            d="M14.0792 19.1855H28.3952C28.4656 19.1856 28.5352 19.1712 28.6002 19.1431C28.6652 19.1151 28.7243 19.0739 28.7741 19.022C28.8238 18.9701 28.8633 18.9085 28.8903 18.8406C28.9172 18.7728 28.9311 18.7001 28.9311 18.6266V17.5092H14.0792V19.1855Z"
            fill="white"
          />
          <path
            d="M28.3952 11.1254H14.0792V15.9954H28.9308V11.6843C28.9307 11.536 28.8742 11.3939 28.7738 11.2891C28.6734 11.1842 28.5372 11.1254 28.3952 11.1254Z"
            fill="white"
          />
          <path d="M18.8941 12.0361H9V12.7984H18.8941V12.0361Z" fill="#DD4D26" />
          <path d="M18.8942 13.1124H10.0176V13.8747H18.8942V13.1124Z" fill="#DD4D26" />
          <path d="M18.8941 14.1888H11.036V14.9511H18.8941V14.1888Z" fill="#DD4D26" />
          <path d="M26.9046 12.4795H24.8495V14.6256H26.9046V12.4795Z" fill="#FAB815" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_842_16869" x1="1.5936e-08" y1="21" x2="48.0278" y2="21" gradientUnits="userSpaceOnUse">
            <stop stopColor="#03228F" />
            <stop offset="1" stopColor="#0E73E4" />
          </linearGradient>
          <clipPath id="clip0_842_16869">
            <rect width="24" height="24" fill="white" transform="translate(9 9)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return (
      <Box
        sx={{
          width: LOGO_WIDTH,
          height: '100%',
        }}
      >
        {logo}
      </Box>
    );
  }

  return (
    <RouterLink to="/checkout">
      <Box
        onClick={() => {
          if (currentTicket?._id) {
            const updateSocketParams = {
              identify: 'updateTicketLockingStatus-7',
              eventName: MODIFY_STATUS,
              data: {
                tenantId: currentTicket.tenantId,
                _id: currentTicket._id,
                ticketId: currentTicket.ticketId,
                activeProfile: '',
              },
            };
            sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
          }
        }}
        sx={{
          width: 70,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {logo}
      </Box>
    </RouterLink>
  );
}
