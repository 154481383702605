import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// context
import { GlobalProvider } from 'contexts/GlobalContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//store
import { persistor, store } from 'redux/store';
// components
import App from './App';
// styles
import * as Sentry from '@sentry/react';
import ErrorBoundary from 'containers/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://ced5c9fd5c679c90e28aa8847084ad77@o4506262084517888.ingest.sentry.io/4506703507226624',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkRequestHeaders: ['X-Tenant-Id', 'X-Custom-Header'],
      networkDetailAllowUrls: ['http://localhost:5000/sales', 'https://api.getrichpos.com/pos/pos/checkout/queue/v2'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  environment: `${process.env.REACT_APP_ENV}`.trim().toLowerCase(),
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development',
});

export const queryClient = new QueryClient();
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalProvider>
            <CollapseDrawerProvider>
              <App />
            </CollapseDrawerProvider>
          </GlobalProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
