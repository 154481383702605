import { Box, Button, Grid, Stack, Switch, Tooltip, Typography, tooltipClasses } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import icCheckin from 'assets/images/icons/ic_checkin.svg';
import MenuPopover from 'components/MenuPopover';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState, useAppSelector } from 'redux/store';
import { LIST_IN_SERVICE, TICKET_SOURCE, WS_ACTION, WS_ACTION_TYPE } from 'utils/constants';
import moment from 'moment';
import _ from 'lodash';

import ic_user from 'assets/images/checkout/ic_user.svg';
import ic_clock from 'assets/images/checkout/ic_clock.svg';
import ic_chevron_left from 'assets/images/icons/ic_chevron_left.svg';

const { TEMP_TICKET_RESOLVER } = WS_ACTION;
const { MODIFY_STATUS } = WS_ACTION_TYPE;

export const useStyles = makeStyles((theme: any) => ({
  button: {
    background: theme.palette.background.main,
    color: theme.palette.primary.text,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '30px',
    height: '42px',
    width: '92px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: -10,
      right: 0,
    },
  },
  ticketItem: {
    paddingRight: '20px',
    height: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(24, 144, 255, 0.32)',
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px',
    overflow: 'hidden',
  },
  customerInfo: {
    // textAlign: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&__name': {
      fontWeight: 600,
      fontSize: '23px',
      lineHeight: '26px',
      color: '#061340',
      // paddingBottom: '16px',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&__timer': {
      fontWeight: 400,
      fontSize: '18px',
      color: '#637381',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&__button': {
      width: '36px',
      height: '100%',
      backgroundColor: '#F8F8F8',
    },
    '&__icon': {
      rotate: '180deg',
      transition: '0.5s ease',
    },
    '&:hover &__icon': {
      transform: 'translateX(5px)',
    },
  },
  staffItem: {
    '&__name': {
      fontWeight: 600,
    },
    '&__services': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  buttonIcon: {
    width: '36px',
    height: '100%',
    backgroundColor: '#F8F8F8',
    cursor: 'pointer',
    '&:hover &__icon': {
      transform: 'translateX(5px)',
    },
    '&__icon': {
      transition: '0.5s ease',
    },
  },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #0B70E1',
    // boxShadow: theme.shadows[2],
    fontSize: 16,
    padding: '10px 14px',
  },
}));

export default function CheckinPopover() {
  const classes: any = useStyles();
  const navigate = useNavigate();
  // const { handleCompleteTicketCheckin } = useCheckin();

  // GLOBAL STATE
  const { sendJsonMessage, profileId } = useAppSelector((state: RootState) => state.global);
  const { currentTicket, manageTicket } = useAppSelector((state: RootState) => state.checkout);

  // COMPONENT STATE
  const [open, setOpen] = useState(null);
  const [listCheckin, setListCheckin] = useState<Array<any>>([]);
  const [listInService, setListInService] = useState<any>(JSON.parse(localStorage.getItem(LIST_IN_SERVICE) || '{}'));
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  const tooltipRefs = useRef<any>([]);
  const positionsRef = useRef(new Map());

  const handleTooltipToggle = (id: any) => {
    setActiveTooltip((prevIndex: any) => (prevIndex === id ? null : id));
  };

  const renderTooltip = (staffs: any) => {
    const tooltipContent = staffs.map((staff: any) => {
      const { listProduct } = staff;
      return (
        <Box key={staff.staffId}>
          <Typography component="span" marginRight={1} fontWeight={600}>
            {staff.staffName}:
          </Typography>
          {(listProduct || []).map((product: any, index: number) => {
            const isLastProduct = index === listProduct.length - 1;
            return (
              <Typography component="span" key={product.productId}>
                {product.productName} {!isLastProduct && <Typography component="span">, </Typography>}
              </Typography>
            );
          })}
        </Box>
      );
    });
    return tooltipContent;
  };

  const handleChangeStatus = (id: any) => (e: any) => {
    const _listInService = {
      ...listInService,
      [id]: e.target.checked,
    };
    setListInService(_listInService);
  };

  const sortListCheckin = (data: any) => {
    const result = [...data].sort((a: any, b: any) => {
      const ticketAInService = listInService[a._id] === true;
      const ticketBInService = listInService[b._id] === true;

      if (ticketAInService && !ticketBInService) {
        return 1;
      } else if (!ticketAInService && ticketBInService) {
        return -1;
      }
      const unixA = parseInt(a.createdDate);
      const unixB = parseInt(b.createdDate);
      return unixB - unixA;
    });
    return result;
  };

  // HOOKS
  useEffect(() => {
    // const _listCheckin = Object.values(manageTicket).filter((ticket: any) => ticket?.isTicketCheckin === true);
    const _listCheckin = Object.values(manageTicket).filter((ticket: any) => ticket?.source === TICKET_SOURCE.CHECKIN);
    const sortedList = sortListCheckin(_listCheckin);
    setTimeout(() => {
      setListCheckin(sortedList);
    }, 400);

    // Remove _id ticket when _id don't exist in list checkin
    const missingKey = _.difference(
      Object.keys(listInService),
      _listCheckin.map((item: any) => item._id),
    );
    if (missingKey.length > 0) {
      missingKey.forEach((key) => {
        delete listInService[key];
      });
      setListInService(listInService);
    }
  }, [JSON.stringify(manageTicket)]);

  useEffect(() => {
    localStorage.setItem(LIST_IN_SERVICE, JSON.stringify(listInService));
    const _sortedList = sortListCheckin(listCheckin);
    setListCheckin(_sortedList);
  }, [JSON.stringify(listInService)]);

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (activeTooltip && tooltipRefs.current[activeTooltip] && !tooltipRefs.current[activeTooltip]?.contains(e.target)) setActiveTooltip(null);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeTooltip]);

  useEffect(() => {
    const positions = new Map();
    listCheckin.forEach((item: any) => {
      const domNode = document.querySelector(`[data-key="${item._id}"]`) as HTMLElement;
      if (domNode) {
        const rect = domNode.getBoundingClientRect();
        positions.set(item._id, rect);

        const oldPos = positionsRef.current.get(item._id);
        if (oldPos) {
          const deltaX = oldPos.left - rect.left;
          const deltaY = oldPos.top - rect.top;

          if (deltaX || deltaY) {
            const animate = () => {
              domNode.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
              domNode.style.transition = 'transform 0s';

              requestAnimationFrame(() => {
                domNode.style.transform = '';
                domNode.style.transition = 'transform 500ms';
              });
            };
            requestAnimationFrame(animate);
          }
        }
      }
    });

    positionsRef.current = positions;
  }, [listCheckin, listInService]);

  return (
    <>
      <Button onClick={(event: any) => setOpen(event.currentTarget)} disableFocusRipple disableRipple disableElevation className={classes.button}>
        {`Check-in`}
        {listCheckin.length === 0 ? null : <img src={icCheckin} alt="ic checkin"></img>}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => {
          setOpen(null);
          setActiveTooltip(null);
        }}
        disabledArrow={true}
        sx={{
          mt: listCheckin.length === 0 ? 1.5 : 2.5,
          width: listCheckin.length === 0 ? '500px' : 'calc(100% - 90px)',
          left: listCheckin.length === 0 ? 'inherit' : 'unset !important',
          right: listCheckin.length === 0 ? 'inherit' : '10px',
          height: listCheckin.length === 0 ? 'auto' : 'calc(100vh - 80px)',
        }}
      >
        <Grid
          container
          spacing={1.25}
          sx={{
            // width: '100%',
            height: 'auto',
            maxHeight: 'calc(100vh - 80px) !important',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          {listCheckin.length === 0 ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                height: '200px',
              }}
            >
              <Typography
                data-hj-allow
                sx={{
                  fontWeight: 600,
                  fontSize: '24px',
                }}
              >
                Empty tickets from checkin
              </Typography>
            </Stack>
          ) : (
            listCheckin.map((ticketItem: any) => {
              const { customerName, createdDate, timezone, manageCart, _id } = ticketItem;
              const listStaff: any = [];
              Object.keys(manageCart).forEach((cart) => listStaff.push(manageCart[cart]));
              // const isUsingByOtherProfile = ticketItem?.activeProfile && ticketItem?.activeProfile !== profileId;
              return (
                <Grid item xs={6} key={ticketItem?._id} data-key={_id}>
                  <Box className={classes.ticketItem}>
                    <Grid container gap={1} flexWrap="nowrap">
                      <Grid
                        item
                        xs={5}
                        className={classes.customerInfo}
                        onClick={() => {
                          if (ticketItem?.activeProfile && ticketItem?.activeProfile !== profileId) {
                            return toast.warning('This ticket is being edited by another profile!');
                          }

                          if (currentTicket?._id) {
                            const updateSocketParams = {
                              identify: 'updateTicketLockingStatus-3',
                              eventName: MODIFY_STATUS,
                              data: {
                                tenantId: currentTicket.tenantId,
                                _id: currentTicket._id,
                                ticketId: currentTicket.ticketId,
                                activeProfile: '',
                              },
                            };
                            sendJsonMessage && sendJsonMessage({ action: TEMP_TICKET_RESOLVER, ...updateSocketParams });
                          }

                          navigate(`/checkout/${ticketItem?._id || ''}`);
                          setOpen(null);
                        }}
                      >
                        <Grid container>
                          <Grid item xs={2}>
                            <Stack className={`${classes.customerInfo}__button`} justifyContent="center">
                              <Box className={`${classes.customerInfo}__icon`}>
                                <img src={ic_chevron_left} width={28} height={28} alt="" />
                              </Box>
                            </Stack>
                          </Grid>
                          <Grid item xs={10} sx={{ padding: '20px 0 20px 0' }}>
                            <Stack spacing={2}>
                              <Stack direction="row" alignItems="flex-start" spacing={1.5}>
                                <img src={ic_user} width={24} height={24} alt="ic customer" />
                                <Typography data-hj-allow className={`${classes.customerInfo}__name`}>
                                  {customerName}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start" spacing={1.5}>
                                <img src={ic_clock} width={24} height={24} alt="ic clock" />
                                <Typography data-hj-allow className={`${classes.customerInfo}__timer`}>
                                  {moment.unix(Number(createdDate)).tz(timezone).format('hh:mm A')}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sx={{ padding: '20px 0 20px 0' }}>
                        {listStaff.length === 0 ? (
                          <Typography className={`${classes.staffItem}__name`}>N/A</Typography>
                        ) : (
                          <Box>
                            <LightTooltip
                              key={_id}
                              open={activeTooltip === _id}
                              onClose={() => setActiveTooltip(null)}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              placement="top-start"
                              title={renderTooltip(listStaff)}
                            >
                              <Box
                                ref={(element) => {
                                  if (activeTooltip === _id) tooltipRefs.current[_id] = element;
                                }}
                                sx={{ cursor: 'help' }}
                                onClick={() => handleTooltipToggle(_id)}
                              >
                                {listStaff.map((item: any) => {
                                  const { listProduct } = item;
                                  return (
                                    <Stack className={classes.staffItem} key={item.staffId} direction="row" spacing={1}>
                                      <Typography component="span" className={`${classes.staffItem}__name`}>
                                        {item.staffName}:
                                      </Typography>

                                      <Typography className={`${classes.staffItem}__services`}>
                                        {listProduct.map((product: any, index: number) => {
                                          const { productName } = product;
                                          const abbrName = productName.match(/\b\w/g).join('').toUpperCase();
                                          const lastItem = index === listProduct.length - 1;
                                          return (
                                            <Typography component="span" color="#0B70E1">
                                              {abbrName}
                                              {!lastItem && ' | '}
                                            </Typography>
                                          );
                                        })}
                                      </Typography>
                                    </Stack>
                                  );
                                })}
                              </Box>
                            </LightTooltip>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={1} alignSelf="center">
                        <Switch onChange={handleChangeStatus(_id)} checked={listInService[_id]} />
                      </Grid>
                    </Grid>

                    {/* {isUsingByOtherProfile ? (
                      <LockIcon />
                    ) : (
                      <IconButton
                        onClick={async () => {
                          await handleCompleteTicketCheckin(ticketItem, 'delete');
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )} */}
                  </Box>
                </Grid>
              );
            })
          )}
        </Grid>
      </MenuPopover>
    </>
  );
}
