import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useSettings from '../../hooks/useSettings';
import { HEADER, NAVBAR } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import useOffSetTop from 'hooks/useOffSetTop';
import ModalConfirmCashChange from 'features/checkout-nails123/components/Modal/ModalConfirmCashChange';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { updateModalConfirmCashChange } from 'redux/slices/checkout';
import { ModalPasscode } from 'features/checkout-nails123/components/Modal/ModalPasscode';
import { EPasscode } from 'constants/enums/Passcode';
import businessService from 'services/business.service';
import ModalConfirmClock from './modal/ModalConfirmClock';
import moment from 'moment';
import { toast } from 'react-toastify';
import { updateListStaffWithWorkingHours } from 'redux/slices/global';
import cloverService from 'services/clover.service';

export interface IModalConfirmClock {
  staffId: string;
  staffName: string;
  role: string;
  isCheckIn: boolean;
  isCheckOut: boolean;
  PK: string;
  SK: string;
}

const RootStyle = styled('main')<any>(({ iscollapse, verticalLayout, theme }: any) => ({
  top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  padding: 10,
  right: 0,
  position: 'absolute',
  borderRadius: '16px',
  width: '100%',
  height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)`,
  zIndex: theme.zIndex.appBar + 1,
  background: '#F4F6FA',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px - 48px)`,
    height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
    ...(iscollapse === 'true' && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(verticalLayout === 'true' && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: 'calc(100%)',
  },
}));

const PASSCODE_TYPE = {
  CALENDAR: 'calendar',
  ATTENDANCE: 'attendance',
  CASH_DRAWER: 'cash-drawer',
};

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useAppDispatch();
  const dialogRef = useRef<any>();
  const datepickerCB = useRef<any>();
  const [isChecking, setIsChecking] = useState(false);
  const [passcodeType, setPasscodeType] = useState(PASSCODE_TYPE.ATTENDANCE);
  const { modalConfirmCashChange } = useAppSelector((state: RootState) => state.checkout);
  const { businessInfo, listStaffWithWorkingHours, checkoutSettings } = useAppSelector((state: RootState) => state.global);
  const [modalConfirmClock, setModalConfirmClock] = useState({
    display: false,
    modalData: { staffName: '', staffId: '', role: '', isCheckIn: false, PK: '', SK: '' } as IModalConfirmClock,
  });
  const { collapseClick, isCollapse }: any = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const [open, setOpen] = useState(false);
  const [isClosePopover, setIsClosePopover] = useState(false);
  const verticalLayout = themeLayout === 'vertical';
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const timezone = businessInfo?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onConfirmCashChange = () => {
    dispatch(
      updateModalConfirmCashChange({
        ...modalConfirmCashChange,
        open: false,
      }),
    );
  };

  const handleClickCashDrawer = () => {
    setIsClosePopover(false);
    setPasscodeType(PASSCODE_TYPE.CASH_DRAWER);
    dialogRef.current.onOpen();
  };

  const handleSubmitPasscodeCashDrawer = async (passcode: Array<string>) => {
    const response = await businessService.loginPasscode({
      passcode: passcode.join(''),
      page: EPasscode['/checkout'],
    });
    if (!response.success) throw new Error(response?.data?.message || 'Something went wrong, please try again later');
    const { permission } = response.data.data;
    if (!permission.some((per: string) => per === 'cashDrawer')) throw new Error('You don’t have enough perrmission.');
    dialogRef.current.onClose();
    setIsClosePopover(true);
    checkoutSettings?.cashDrawerConnect
      ? await cloverService.openCashDrawerPrinter({
          comPort: null,
          printerName: checkoutSettings?.printerDevices?.length > 0 ? checkoutSettings.printerDevices[0]?.name : '',
        })
      : await cloverService.openCashDrawer();
  };

  const handleClickCalendar = ({ trigger, currentValue }: any) => {
    datepickerCB.current = {
      trigger,
      currentValue,
    };
    setPasscodeType(PASSCODE_TYPE.CALENDAR);
    dialogRef.current.onOpen();
  };

  const handleSubmitPasscodeCalendar = async (passcode: Array<string>) => {
    const response = await businessService.loginPasscode({
      passcode: passcode.join(''),
      page: EPasscode['/report'],
    });
    if (!response.success) throw new Error(response?.data?.message || 'Something went wrong, please try again later');
    const { permission } = response.data.data;
    if (!permission.ticket.some((per: string) => per === 'editTicket')) throw new Error('You don’t have enough perrmission.');
    dialogRef.current.onClose();
    datepickerCB.current?.trigger?.(true);
  };

  const handleClickAttendance = () => {
    setIsClosePopover(false);
    setPasscodeType(PASSCODE_TYPE.ATTENDANCE);
    dialogRef.current.onOpen();
  };

  const handleSubmitPasscodeAttendance = async (passcode: Array<string>) => {
    const startDate = `${moment().tz(timezone).startOf('day').unix()}`;
    const endDate = `${moment().tz(timezone).endOf('day').unix()}`;
    const res = await businessService.getStaffInfoBeforeChecking(passcode.join(''), startDate, endDate);
    if (!res.success) throw new Error(res?.data?.message || 'Something went wrong, please try again later');
    const { data: response } = res;
    dialogRef.current.onClose();
    setIsClosePopover(true);
    setModalConfirmClock({
      display: true,
      modalData: {
        staffId: response?.data?.staffId || '',
        staffName: response?.data?.staffName || '',
        role: response?.data?.role,
        isCheckIn: response?.data?.isCheckIn,
        isCheckOut: response?.data?.isCheckOut,
        PK: response?.data?.PK || '',
        SK: response?.data?.SK || '',
      },
    });
  };

  const handleCloseModalConfirmClock = () => {
    setModalConfirmClock({
      ...modalConfirmClock,
      display: false,
    });
  };

  const handleSubmitModalConfirmClock = async (data: IModalConfirmClock) => {
    setIsChecking(true);
    if (!data.isCheckIn || data.isCheckOut) {
      try {
        const response = await businessService.staffCheckIn({ staffId: data.staffId });
        if (response?.data?.success) {
          toast.success('Check in successfully.');
          dispatch(updateListStaffWithWorkingHours([...listStaffWithWorkingHours, response?.data?.data].sort((a, b) => a.orderIndex - b.orderIndex)));
        }
      } catch (error) {
        toast.error('Check in failed.');
      }
    } else {
      try {
        const response = await businessService.staffCheckOut({ PK: data.PK, SK: data.SK });
        if (response?.data?.success) {
          toast.success('Check out successfully.');
          dispatch(updateListStaffWithWorkingHours(listStaffWithWorkingHours.filter((staff: { PK: string }) => staff.PK !== response?.data?.data?.PK)));
        }
      } catch (error) {
        toast.error('Check out failed.');
      }
    }
    setIsChecking(false);
    handleCloseModalConfirmClock();
  };

  return (
    <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 }, background: '#F4F6FA' }}>
      <DashboardHeader onOpenSidebar={() => setOpen(true)} collapseClick={collapseClick} onClickCalendar={handleClickCalendar} />
      <NavbarVertical
        isOpenSidebar={open}
        isClosePopover={isClosePopover}
        onCloseSidebar={() => setOpen(false)}
        onClickAttendance={handleClickAttendance}
        onClickCashDrawer={handleClickCashDrawer}
      />
      <RootStyle iscollapse={`${isCollapse}`} isoffset={`${isOffset}`} verticallayout={`${verticalLayout}`}>
        <Box sx={{ position: 'relative', width: '100%', height: '100%', borderRadius: '16px' }}>
          <Outlet />
        </Box>
      </RootStyle>
      <ModalConfirmCashChange
        onClose={() => onConfirmCashChange()}
        open={modalConfirmCashChange?.open}
        modalData={modalConfirmCashChange?.data}
        onConfirm={() => onConfirmCashChange()}
      />
      <ModalPasscode
        ref={dialogRef}
        onSubmit={
          passcodeType === PASSCODE_TYPE.CALENDAR
            ? handleSubmitPasscodeCalendar
            : passcodeType === PASSCODE_TYPE.ATTENDANCE
            ? handleSubmitPasscodeAttendance
            : handleSubmitPasscodeCashDrawer
        }
      />
      {modalConfirmClock.display && (
        <ModalConfirmClock
          modalData={{
            staffName: modalConfirmClock.modalData.staffName,
            staffId: modalConfirmClock.modalData.staffId,
            role: modalConfirmClock.modalData.role,
            isCheckIn: modalConfirmClock.modalData.isCheckIn,
            isCheckOut: modalConfirmClock.modalData.isCheckOut,
            PK: modalConfirmClock.modalData.PK,
            SK: modalConfirmClock.modalData.SK,
          }}
          open={modalConfirmClock.display}
          title={`Clock ${!modalConfirmClock.modalData.isCheckIn || modalConfirmClock.modalData.isCheckOut ? 'in' : 'out'}`}
          onClose={handleCloseModalConfirmClock}
          isChecking={isChecking}
          onSubmit={handleSubmitModalConfirmClock}
        />
      )}
    </Box>
  );
}
